import NectarineInfoIcon from '../NectarineInfoIcon/NectarineInfoIcon';
import './NectarineToggle.scss';

interface NectarineToggleProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
  isDisabled?: boolean;
  label?: string;
  secondaryLabel?: string;
  infoIconTooltipText?: string;
}

const NectarineToggle: React.FC<NectarineToggleProps> = (props: NectarineToggleProps) => {
  return (
    <>
      <div>
        {props.label ? (
          <div className="labelWrapper">
            <label className="bold mb-1">{props.label}</label>

            {props.infoIconTooltipText ? <NectarineInfoIcon tooltipText={props.infoIconTooltipText} /> : <></>}

            {props.secondaryLabel ? <span className="ms-1">{props.secondaryLabel}</span> : <></>}
          </div>
        ) : (
          <></>
        )}

        <div
          // Give it a tabindex so it can be focused by the user (and able to be toggled with the keyboard)
          tabIndex={0}
          className={`toggleContainer ${props.isDisabled ? 'isDisabled' : ''} ${props.value ? 'isOn' : ''}`}
          onKeyDown={(e) => {
            if (e.key === ' ' || e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();

              props.onChange(!props.value);
            }
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            if (props.isDisabled) {
              return;
            }

            props.onChange(!props.value);
          }}
        >
          <div className="toggleButton"></div>
        </div>
      </div>
    </>
  );
};

export default NectarineToggle;
