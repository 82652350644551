import { sortBy, sum } from 'lodash';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { AppSettings } from '../../../../../constants';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import DateHelper from '../../../../Helpers/DateHelper';
import LoadingSpinner from '../../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

const AdvisorAccountReviews = () => {
  const [reviews, setReviews] = useState<Reviews_Review_ViewModel[] | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const reviews = await AdvisorsApiService.getAdvisorReviews();
      setReviews(reviews);
    };

    loadData();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - My Account</title>
      </Helmet>
      <Row md="12">
        <Col>
          <h1 className="mb-4">My Reviews</h1>

          {!reviews ? <LoadingSpinner message="Loading..." /> : <></>}
          {reviews && reviews.length == 0 ? <p>You have not yet received any reviews. When you do they'll appear here!</p> : <></>}
          {reviews && reviews.length > 0 ? (
            <div>
              <p>
                You have received <strong>{reviews.length}</strong> reviews with an average rating of{' '}
                <strong>{(sum(reviews.map((v) => v.rating)) / reviews.length).toFixed(2)}</strong> stars.
              </p>

              {sortBy(reviews, (v) => new Date(v.modifiedAt)).map((review) => (
                <div key={review.id} className="details-review">
                  {Array.from({ length: review.rating }, (v) => (
                    <i key={v as number} className="fa-star fa-solid att-icon"></i>
                  ))}
                  <h3>{review.title}</h3>
                  <p>{review.body}</p>

                  <div className="review-footer">
                    <div className="reviewer-name">{review.displayName}</div>
                    <div className="review-date">{DateHelper.printMonthYearLong(review.modifiedAt)}</div>
                  </div>

                  {review.privateComments ? (
                    <div className="my-2">
                      <strong>Private Notes From {review.displayName}</strong>
                      <div>{review.privateComments}</div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdvisorAccountReviews;
