import HttpHelper from '../../components/Helpers/HttpHelper';
import { ErrorLogService } from '../ErrorLogService';
import ApiServiceBase from './ApiServiceBase';

export default class AdvisorsApiService extends ApiServiceBase {
  private static className = 'AdvisorsApiService';

  public static async getAllAdvisorDetails(queryStringParams?: string): Promise<Advisor_BasicDetails_ViewModel[]> {
    const url = `api/AdvisorResults/get-advisors-details${queryStringParams ?? ''}`;

    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel[]>(() => HttpHelper.get(url));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAllAdvisorDetails()`,
        `An error occurred while loading advisors details: ${url}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorDetailsByIdOrSlug(
    idOrSlug: string,
    showAttributes: boolean,
    showDetails: boolean,
    showReviewDetails: boolean
  ): Promise<Advisor_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel>(() =>
        HttpHelper.get(`api/users/slug/${showAttributes}/${showDetails}/${showReviewDetails}/${idOrSlug}`)
      );
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorDetailsBySlug()`,
        `An error occurred while loading advisor details with slug: ${idOrSlug}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorsSharedAttributes(): Promise<Advisor_BasicDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_BasicDetails_ViewModel>(() => HttpHelper.get(`api/advisor/sharedattributes`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorsSharedAttributes()`,
        `An error occurred while setting an advisor's shared attributes.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async setAdvisorsSharedAttributes(newAttributes: string[]): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/sharedattributes`, newAttributes));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - setAdvisorsSharedAttributes()`,
        `An error occurred while setting an advisor's shared attributes.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisorAttribute(editModel: Advisor_Attribute_EditModel): Promise<string> {
    try {
      const result = (await this.makeApiCall(() => HttpHelper.put<string>(`api/advisor/attribute`, editModel)))!;
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - updateAdvisorAttribute()`,
        `An error occurred while updating an attribute for an advisor.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async deleteAdvisorAttribute(attributeId: string): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.delete(`api/advisor/attribute/${attributeId}`));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - deleteAdvisorAttribute()`,
        `An error occurred while deleting an attribute for an advisor.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async uploadProfilePhoto(formData: FormData): Promise<User_UserPhoto_ViewModel> {
    try {
      const result = (await this.makeApiCall(() =>
        HttpHelper.post<User_UserPhoto_ViewModel>(`api/advisor/profilepic`, formData, 'multipart/form-data')
      ))!;
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - uploadProfilePhoto()`,
        `An error occurred while uploading a profile photo for an advisor.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorsToReview(): Promise<User_BasicDetails_ViewModel[]> {
    try {
      const result = await this.makeApiCall<User_BasicDetails_ViewModel[]>(() => HttpHelper.get(`api/reviews/advisors-to-review`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAdvisorsToReview()`, `An error occurred while loading advisors to review.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorReviews(): Promise<Reviews_Review_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Reviews_Review_ViewModel[]>(() => HttpHelper.get(`api/advisor/reviews`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorReviews()`,
        `An error occurred while loading reviews for the advisor.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorClientInfo(clientId: string): Promise<Advisor_ClientDetails_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisor_ClientDetails_ViewModel>(() => HttpHelper.get(`api/advisor/client/${clientId}`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getAdvisorClientInfo()`,
        `An error occurred while loading advisor client data for client ID: ${clientId}.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getAdvisorClients(): Promise<Advisor_ClientDetails_ViewModel[]> {
    try {
      const result = await this.makeApiCall<Advisor_ClientDetails_ViewModel[]>(() => HttpHelper.get(`api/advisor/clients`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getAdvisorClients()`, `An error occurred while loading advisor clients data.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async checkThePlanCalendlyLink(): Promise<boolean> {
    try {
      const result = await this.makeApiCall<boolean>(() => HttpHelper.get(`api/advisor/check-the-plan-calendly-link`));
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - checkThePlanCalendlyLink()`,
        `An error occurred while checking the Calendly link for The Plan.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisor(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - updateAdvisor()`, `An error occurred while updating advisor information.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisorBio(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor-bio`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - updateAdvisorBio()`, `An error occurred while updating the advisor bio.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisorRates(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor-rates`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - updateAdvisorRates()`, `An error occurred while updating the advisor bio.`, error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async updateAdvisorFinancialPlanningLinks(editModel: Advisor_EditAdvisor_EditModel): Promise<void> {
    try {
      await this.makeApiCall<void>(() => HttpHelper.put(`api/advisor/update-advisor-financial-links`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - updateAdvisorFinancialPlanningLinks()`,
        `An error occurred while updating the advisor financial planning links.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getDashboardData(): Promise<Advisors_DashboardPage_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisors_DashboardPage_ViewModel>(() => HttpHelper.get(`api/advisor/dashboard`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - getDashboardData()`, 'An error occurred while loading dashboard data.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getPaymentsDashboardData(): Promise<Advisors_PaymentsDashboardPage_ViewModel> {
    try {
      const result = await this.makeApiCall<Advisors_PaymentsDashboardPage_ViewModel>(() => HttpHelper.get(`api/advisor/payments`));

      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getPaymentsDashboardData()`,
        'An error occurred while loading payments dashboard data.',
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async saveMeetingNotes(editModel: Advisor_Event_EditModel, shouldSendMeetingNotes: boolean): Promise<void> {
    try {
      await this.makeApiCall(() =>
        HttpHelper.put(`api/advisor/${shouldSendMeetingNotes ? 'sendnotes' : 'savenotes'}/${editModel.id}`, editModel)
      );
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - saveMeetingNotes()`, 'An error occurred while saving the meeting notes.', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async initiateThePlan_Full(editModel: Advisors_InitiateThePlan_EditModel): Promise<void> {
    try {
      await this.makeApiCall(() => HttpHelper.post(`api/advisor/initiate-the-plan-full`, editModel));
    } catch (error: unknown) {
      ErrorLogService.logError(`${this.className} - initiateThePlan_Full()`, 'An error occurred while initiating the Plan (full).', error);

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }

  public static async getMeetingNotesDraft(eventId: string): Promise<Advisor_MeetingNotesDraft_ViewModel> {
    try {
      const result = await this.makeApiCall(() =>
        HttpHelper.get<Advisor_MeetingNotesDraft_ViewModel>(`api/advisor/get-meeting-notes-draft/${eventId}`)
      );
      return result;
    } catch (error: unknown) {
      ErrorLogService.logError(
        `${this.className} - getMeetingNotesDraft()`,
        `An error occurred while getting the meeting notes draft for event ID: '${eventId}'.`,
        error
      );

      // Rethrow the error so the calling code can react and fail gracefully
      throw error;
    }
  }
}
