import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { Subscription } from 'rxjs';
import './ConfirmationOverlay.scss';
import { ConfirmationOverlayPrompt, useConfirmationOverlayContext } from './ConfirmationOverlayContext';

const ConfirmationOverlay: React.FC = () => {
  const allSubscriptions = useRef<Subscription>(new Subscription());

  const { confirmationOverlayPrompt$, hideConfirmationOverlay } = useConfirmationOverlayContext();

  const [displayedPrompt, setDisplayedPrompt] = useState<ConfirmationOverlayPrompt | null>(null);
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);
  const [isHiding, setIsHiding] = useState<boolean>(false);

  useEffect(() => {
    allSubscriptions.current.add(
      confirmationOverlayPrompt$.subscribe((v) => {
        setIsDisplayed(v !== null);
        setIsHiding(false);

        setDisplayedPrompt(v);
      })
    );

    const allSubscriptionsObj = allSubscriptions.current;

    return () => {
      allSubscriptionsObj.unsubscribe();
    };
  }, [confirmationOverlayPrompt$]);

  return (
    <div className={`confirmationOverlay ${isDisplayed ? '' : 'd-none'} ${isHiding ? 'isHiding' : ''}`}>
      {displayedPrompt ? (
        <>
          {displayedPrompt.headerIconClass ? (
            <div className="mb-3">
              <span className={'c-orange promptIcon ' + displayedPrompt.headerIconClass}></span>
            </div>
          ) : (
            <></>
          )}

          {displayedPrompt.title ? (
            <div className="promptTitle">
              <div className="innerText">{displayedPrompt.title}</div>
            </div>
          ) : (
            <></>
          )}

          <div className="promptText">
            <div className="innerText" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayedPrompt.text) }}></div>
          </div>

          {displayedPrompt.text2 ? (
            <div className="promptText">
              <div className="innerText" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(displayedPrompt.text2) }}></div>
            </div>
          ) : (
            <></>
          )}

          <div className="buttonsArea mt-4 d-flex gap-4 justify-content-center">
            {!displayedPrompt.doNotShowNoButton ? (
              <Button
                color="light"
                onClick={() => {
                  setIsHiding(true);
                  hideConfirmationOverlay();

                  displayedPrompt.noAction?.();
                }}
              >
                {displayedPrompt.noButtonText ?? 'No'}
              </Button>
            ) : (
              <></>
            )}

            <Button
              color="primary"
              onClick={() => {
                setIsHiding(true);
                hideConfirmationOverlay();

                displayedPrompt.yesAction();
              }}
            >
              {displayedPrompt.yesButtonText ?? 'Yes'}
            </Button>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ConfirmationOverlay;
