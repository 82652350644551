import { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { ApplicationRoutes, LocalStorageKeys } from '../../constants';
import AuthorizeService from '../api-authorization/AuthorizeService';
import RolesHelper from '../Helpers/RolesHelper';
import LoadingSpinner from '../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

interface RoleRestrictionProps {
  allowedRoles: string[];
  element: React.ReactElement;
}

const RoleRestriction: React.FC<RoleRestrictionProps> = ({ allowedRoles, element }: RoleRestrictionProps) => {
  const [userRoles, setUserRoles] = useState<string[] | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      const isAuthenticated = await AuthorizeService.isAuthenticated();
      setIsAuthenticated(isAuthenticated);

      const roles = await RolesHelper.getRoles();
      setUserRoles(roles);
    };
    loadData();
  }, []);

  if (!allowedRoles || !allowedRoles.length) {
    throw new Error('Must supply the allowedRoles attribute when using RoleRestriction');
  }

  if (!userRoles) {
    return <LoadingSpinner />;
  }

  const hasAccess = userRoles.some((role) => allowedRoles.includes(role));

  // If we're about to redirect the user, we want to make note of the current URL
  // and write it to localstorage, which is much cleaner and easier than passing a URL around,
  // especially when using URL's to transmit authentication info during the login flow.
  if (!hasAccess) {
    localStorage.setItem(LocalStorageKeys.General.ReturnUrl, window.location.href);
  }

  if (hasAccess) {
    return element;
  } else if (!isAuthenticated) {
    return <Navigate to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`} />;
  } else {
    return (
      <div>
        <h1>Uh oh!</h1>
        <p className="p-4">
          It looks like you're not supposed to be here. You may need to{' '}
          <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`}>login</Link> first. Please
          <Link to={`/${ApplicationRoutes.Contact}`}> contact us</Link> if you think that might be a mistake!
        </p>
      </div>
    );
  }
};

export default RoleRestriction;
