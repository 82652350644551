import { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import GeneralHelper from '../../Helpers/GeneralHelper';
import './NectarineInfoIcon.scss';

interface NectarineInfoIconProps {
  tooltipText: string;
}

const NectarineInfoIcon: React.FC<NectarineInfoIconProps> = (props) => {
  // This ID cannot start with a number or the tooltip code gets confused and dies -- it's very dramatic.
  const id = useRef<string>('a' + GeneralHelper.generateGuid());

  return (
    <>
      <span id={id.current} className="infoIcon c-blue fa-duotone fa-regular fa-circle-info"></span>
      <UncontrolledTooltip target={id.current}>{props.tooltipText}</UncontrolledTooltip>
    </>
  );
};

export default NectarineInfoIcon;
