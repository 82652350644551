import { sortBy } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminPayments from '../AdminPayments/AdminPayments';

const AdminAffiliatePayments: React.FC = () => {
  const getPayPeriods = (): Admin_PayPeriod_ViewModel[] => {
    const currentDate = new Date('2023-10-01T00:00:00'); // First day of first ever pay period
    const today = new Date(); // Current date
    let payPeriods: Admin_PayPeriod_ViewModel[] = [];

    while (currentDate < today) {
      const lastDayOfMonth = new Date(currentDate);
      lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1);
      lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);

      const period = {
        start: new Date(currentDate),
        end: new Date(lastDayOfMonth)
      };
      payPeriods.push(period);

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    payPeriods = sortBy(payPeriods, (v) => v.start).reverse();

    return payPeriods;
  };

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Affiliate Payments</title>
      </Helmet>
      <Row>
        <Col>
          <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}>
            <i className="c-black fa-regular fa-arrow-left back-arrow"></i> Back
          </Link>
          <h1>Affiliate Payments</h1>

          <AdminPayments userType="affiliate" payPeriods={getPayPeriods()} />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminAffiliatePayments;
