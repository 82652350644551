import { addHours } from 'date-fns';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings, FeatureFlags } from '../../../../../constants';
import useNavigationService from '../../../../../hooks/UseNavigationService';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import { useGeneralContext } from '../../../../../services/GeneralContext';
import AuthorizeService from '../../../../api-authorization/AuthorizeService';
import DateHelper from '../../../../Helpers/DateHelper';
import LoadingSpinner from '../../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../../ui/NectarineButton/NectarineButton';
import AdvisorInviteClientToThePlan from '../AdvisorInviteClientToThePlan/AdvisorInviteClientToThePlan';
import EnterMeetingNotesModal from '../EnterMeetingNotesModal/EnterMeetingNotesModal';

const AdvisorIndex: React.FC = () => {
  const [advisorDetails, setAdvisorDetails] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<User_BasicDetails_ViewModel | null>(null);
  const [users, setUsers] = useState<Advisor_ClientResult_ViewModel[] | null>(null);
  const [upcomingEvents, setUpcomingEvents] = useState<Advisor_Event_ViewModel[] | null>(null);
  const [eventsNeedingNotes, setEventsNeedingNotes] = useState<Advisor_Event_ViewModel[] | null>(null);
  const [showEnterNotesModal, setShowEnterNotesModal] = useState<boolean>(false);
  const [modalEvent, setModalEvent] = useState<Advisor_Event_ViewModel | null>(null);
  const [showThePlanInviteWizard, setShowThePlanInviteWizard] = useState<boolean>(false);

  const { checkFeatureFlag } = useGeneralContext();
  const showInviteClientToThePlanButton = checkFeatureFlag(FeatureFlags.ThePlanWelcomeEmail);

  const { navigateTo_Error } = useNavigationService();

  const openModal = (event: Advisor_Event_ViewModel) => {
    setModalEvent(event);
    setShowEnterNotesModal(true);
  };

  const toggleEnterNotesModal = () => {
    const newVal = !showEnterNotesModal;
    setShowEnterNotesModal(newVal);

    if (!newVal) {
      // Give the modal time to animate closed before we null out the modal event
      setTimeout(() => {
        setModalEvent(null);
      }, 500);
    }
  };

  function loadPageData() {
    const loadData = async () => {
      try {
        const userObj = await AuthorizeService.populateActiveUser();
        setLoggedInUser(userObj);

        const advisorResult = await AdvisorsApiService.getAdvisorDetailsByIdOrSlug(userObj.id, false, false, false);
        setAdvisorDetails(advisorResult);

        const result = await AdvisorsApiService.getDashboardData();

        setUsers(sortBy(result.clients, (v) => `${v.lastName}${v.firstName}`));
        setUpcomingEvents(result.upcomingEvents);
        setEventsNeedingNotes(result.eventsNeedingNotes);
      } catch {
        navigateTo_Error();
      }
    };

    loadData();

    setShowEnterNotesModal(false);
  }

  useEffect(() => {
    loadPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const twoHoursFromNow = addHours(new Date(), 2);

  function inviteClientToThePlanClick() {
    setShowThePlanInviteWizard(true);
  }

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - My Account</title>
      </Helmet>

      <div className="mt-5"></div>

      {showInviteClientToThePlanButton ? (
        <Row md="12" className="mt-5">
          <Col>
            <Button color="primary" onClick={inviteClientToThePlanClick}>
              Invite client to The Plan
            </Button>

            {showThePlanInviteWizard && advisorDetails && loggedInUser && users ? (
              <AdvisorInviteClientToThePlan
                advisorDetails={advisorDetails}
                loggedInUser={loggedInUser}
                users={users}
                onModalClose={(success) => {
                  setShowThePlanInviteWizard(false);
                }}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row md="12">
        <Col>
          <h1>Upcoming Events</h1>

          {!upcomingEvents ? <LoadingSpinner message="Loading..." /> : <></>}
          {upcomingEvents && upcomingEvents.length == 0 ? <p>You have no upcoming events.</p> : <></>}
          {upcomingEvents && upcomingEvents.length > 0 ? (
            <div>
              <table className="table advisor-events">
                <thead>
                  <tr>
                    <th>
                      <i className="fa-duotone fa-peach light-blue"></i>
                    </th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>Meeting Time</th>
                    <th>Intake Form</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {upcomingEvents.map((event) => (
                    <tr key={event.id}>
                      <td>{event.isNectarine && <i className="fa-duotone fa-peach light-blue"></i>}</td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Client_Full}/${event.consumerId}`}>
                          {event.consumerLastName}, {event.consumerFirstName}
                        </Link>
                        {event.eventCount > 1 && <span> ({event.eventCount})</span>}
                      </td>
                      <td>{event.consumerEmail}</td>
                      <td>{event.consumerState}</td>
                      <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                      <td>
                        {event.intakeForm && <i className="fa-duotone fa-check-to-slot att-icon"></i>}
                        {!event.intakeForm && <i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i>}
                      </td>
                      <td>
                        {new Date(event.startTime) < twoHoursFromNow && (
                          <a className="btn btn-primary" href={event.location}>
                            Join Meeting
                          </a>
                        )}
                        {new Date(event.startTime) >= twoHoursFromNow && (
                          <a className="btn btn-secondary disabled" href={event.location}>
                            Join Meeting
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <p>
                Events will show the <i className="fa-duotone fa-peach light-blue"></i> icon when they are being operated under Nectarine's
                RIA.
              </p>
            </div>
          ) : (
            <></>
          )}

          {!eventsNeedingNotes ? <LoadingSpinner message="Loading..." /> : <></>}

          {eventsNeedingNotes && eventsNeedingNotes.length > 0 ? (
            <div>
              <h1>Notes to enter</h1>
              <p>The meetings below still need your notes recorded.</p>

              <table className="table advisor-events">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>Meeting Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {eventsNeedingNotes.map((event) => (
                    <tr key={event.id}>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Client_Full}/${event.consumerId}`}>
                          {event.consumerLastName}, {event.consumerFirstName}
                        </Link>
                      </td>
                      <td>{event.consumerEmail}</td>
                      <td>{event.consumerState}</td>
                      <td>{DateHelper.mediumDateFormat(event.startTime)}</td>
                      <td>
                        <NectarineButton
                          text="Enter Notes"
                          type="blue"
                          iconClassString="fa-light fa-pen-to-square"
                          onClick={() => openModal(event)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {modalEvent ? (
                <EnterMeetingNotesModal show={showEnterNotesModal} toggle={toggleEnterNotesModal} save={loadPageData} event={modalEvent} />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          <h1>My Clients</h1>

          {!users ? <LoadingSpinner message="Loading..." /> : <></>}
          {users && users.length == 0 ? <p>You have not yet matched with any clients. When you do they'll appear here!</p> : <></>}
          {users && users.length > 0 ? (
            <table className="table advisor-users">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>State</th>
                  <th>Last Meeting</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Client_Full}/${user.id}`}>
                        {user.lastName}, {user.firstName}
                      </Link>
                      {user.eventCount > 1 && <span> ({user.eventCount})</span>}
                    </td>
                    <td>{user.email}</td>
                    <td>{user.state}</td>
                    <td>{user.mostRecentEventStart ? DateHelper.mediumDateFormat(user.mostRecentEventStart) : <></>}</td>
                    <td>
                      <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Compliance_Full}/${user.id}/report`}>Compliance Report</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdvisorIndex;
