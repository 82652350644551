import { format } from 'date-fns';
import { forwardRef } from 'react';
import DateHelper from '../../Helpers/DateHelper';
import NectarineInfoIcon from '../NectarineInfoIcon/NectarineInfoIcon';
import './NectarineDateInput.scss';

interface NectarineDateInputProps {
  value: Date | null;
  onChange: (newValue: Date | null) => void;
  isDisabled?: boolean;
  required?: boolean;
  min?: Date;
  max?: Date;
  placeholder?: string;
  label?: string;
  secondaryLabel?: string;
  infoIconTooltipText?: string;
  readOnly?: boolean;
  readOnlyPlaceholderText?: string;
}

const NectarineDateInput = forwardRef<HTMLInputElement, NectarineDateInputProps>((props: NectarineDateInputProps, ref) => {
  if ((props.min !== undefined || props.max !== undefined) && !props.required) {
    console.warn(
      'The min or max is set on a NectarineDateInput but the required attribute was false, ' +
        'which means HTML form validation will not occur for this input.' +
        (props.label ? `\nThis was for text input with label '${props.label}'.` : '')
    );
  }

  return (
    <>
      <div>
        {props.label ? (
          <div className="labelWrapper">
            <label className="bold mb-1">
              {props.label}
              {props.required ? <span className="ms-1 c-green"> *</span> : ''}
            </label>

            {props.infoIconTooltipText ? <NectarineInfoIcon tooltipText={props.infoIconTooltipText} /> : <></>}

            {props.secondaryLabel ? <span className="ms-1">{props.secondaryLabel}</span> : <></>}
          </div>
        ) : (
          <></>
        )}

        <div className="dateInputWrapper">
          <div className="inputIconContainer">
            <i className="fa-duotone fa-light fa-calendar-days"></i>
          </div>

          {props.readOnly ? (
            props.value ? (
              <div className="readOnlyText d-flex align-items-center">{DateHelper.mediumDateFormat(props.value)}</div>
            ) : (
              <span className="c-gray">{props.readOnlyPlaceholderText || '(No content)'}</span>
            )
          ) : (
            <>
              <input
                className="form-control"
                ref={ref}
                type="date"
                value={props.value ? format(props.value, 'yyyy-MM-dd') : ''}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (props.isDisabled) {
                    return;
                  }

                  if (!e.target.value) {
                    props.onChange(null);
                  } else {
                    const date = new Date(`${e.target.value}T00:00:00`);
                    props.onChange(date);
                  }
                }}
                placeholder={props.placeholder}
                required={props.required}
                disabled={props.isDisabled}
                min={props.min ? format(props.min, 'yyyy-MM-dd') : undefined}
                max={props.max ? format(props.max, 'yyyy-MM-dd') : undefined}
              />

              {!props.required && !props.isDisabled && props.value ? (
                <span
                  className="clearIcon"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    props.onChange(null);
                  }}
                >
                  <span className="fa-solid fa-times"></span>
                </span>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
});

NectarineDateInput.displayName = 'NectarineDateInput';

export default NectarineDateInput;
