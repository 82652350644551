import { intervalToDuration, isAfter } from 'date-fns';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { StripeInvoiceStatuses, ThePlanMeetingStatus } from '../../../../constants';
import useNavigationService from '../../../../hooks/UseNavigationService';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import GeneralHelper from '../../../Helpers/GeneralHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import { useNotificationsPanelContext } from '../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';
import './AdminThePlan.scss';

const AdminThePlan: React.FC = () => {
  const [thePlanInstances, setThePlanInstances] = useState<Admin_ThePlanDetail_ViewModel[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { showErrorNotification } = useNotificationsPanelContext();

  const { navigateTo_Admin_ThePlanEdit } = useNavigationService();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const result = await AdminApiService.getThePlanInstances();
        setThePlanInstances(result);
      } catch {
        showErrorNotification('An error occurred while loading the plan instances.');
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [showErrorNotification]);

  const getStripeInvoiceBadgeInfo = (
    item: Admin_ThePlanDetail_ViewModel
  ): {
    statusText: string;
    cssClass: string;
  } => {
    if (item.stripeInvoiceStatus === StripeInvoiceStatuses.Paid) {
      return {
        statusText: 'Paid',
        cssClass: 'paid'
      };
    } else if (item.stripeInvoiceStatus === StripeInvoiceStatuses.Open) {
      return {
        statusText: 'Open',
        cssClass: 'open'
      };
    }

    const isInvoiceDueWithinOneWeek = intervalToDuration({
      start: new Date(),
      end: item.stripeInvoiceDueDate ?? item.createdAt
    });

    const daysBetweenDates = isInvoiceDueWithinOneWeek.days ?? 0;

    if (daysBetweenDates > 0 && daysBetweenDates <= 7) {
      return {
        statusText: 'Due soon',
        cssClass: 'dueSoon'
      };
    } else if (daysBetweenDates < 0) {
      return {
        statusText: 'Past due',
        cssClass: 'pastDue'
      };
    }

    return {
      cssClass: '',
      statusText: item.stripeInvoiceStatus
    };
  };

  const getMostRecentCompletedMeeting = (item: Admin_ThePlanDetail_ViewModel): string | null => {
    const mostRecentMeeting = sortBy(
      item.thePlanDetailMeetings,
      item.thePlanDetailMeetings.filter((v) => v.status === ThePlanMeetingStatus.Completed)
    ).reverse()[0];

    if (!mostRecentMeeting) {
      return null;
    }

    return DateHelper.mediumDateFormat(mostRecentMeeting.startTime);
  };

  const getNextScheduledMeeting = (item: Admin_ThePlanDetail_ViewModel): string | null => {
    const nextMeeting = sortBy(
      item.thePlanDetailMeetings.filter((v) => v.status === ThePlanMeetingStatus.Scheduled),
      (m) => m.startTime
    ).reverse()[0];

    if (!nextMeeting) {
      return null;
    }

    return DateHelper.mediumDateFormat(nextMeeting.startTime);
  };

  return (
    <>
      <h2 className="mb-3 d-flex">
        <span className="me-4">The Plan - Client Tracker</span>

        <NectarineButton
          text="Add The Plan engagement"
          onClick={() => {
            navigateTo_Admin_ThePlanEdit();
          }}
        />
      </h2>

      {isLoading ? <LoadingSpinner /> : <></>}

      {!isLoading ? (
        <>
          <NectarineTable<Admin_ThePlanDetail_ViewModel>
            maxHeight="60vh"
            data={thePlanInstances ?? []}
            rowDataTemplate={(item) => {
              return (
                <>
                  <NectarineTableDataColumn>
                    {item.clientFirstName} {item.clientLastName}
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn>{item.advisorFullName}</NectarineTableDataColumn>

                  <NectarineTableDataColumn alignment="stretch">
                    <UncontrolledTooltip target={`stripeInvoiceBadge${item.id}`}>
                      {item.stripeInvoiceStatus === StripeInvoiceStatuses.Paid ? (
                        <div>
                          Invoice paid on{' '}
                          {item.stripeInvoicePaidDate
                            ? DateHelper.dayOfTheWeek(item.stripeInvoicePaidDate) +
                              ', ' +
                              DateHelper.shortDateFormat(item.stripeInvoicePaidDate)
                            : 'N/A'}
                        </div>
                      ) : (
                        <div>
                          Invoice {isAfter(new Date(), item.stripeInvoiceDueDate ?? item.createdAt) ? 'was ' : 'is '}
                          due on {DateHelper.dayOfTheWeek(item.stripeInvoiceDueDate ?? item.createdAt)},{' '}
                          {DateHelper.shortDateFormat(item.stripeInvoiceDueDate ?? item.createdAt)}.
                          <br />
                          Click to view the Stripe invoice.
                        </div>
                      )}
                    </UncontrolledTooltip>

                    {item.stripeInvoiceUrl ? (
                      <a href={item.stripeInvoiceUrl} target="_blank" rel="noopener noreferrer">
                        <div
                          id={`stripeInvoiceBadge${item.id}`}
                          className={`stripeInvoiceBadge ${getStripeInvoiceBadgeInfo(item).cssClass}`}
                        >
                          {getStripeInvoiceBadgeInfo(item).statusText}
                        </div>
                      </a>
                    ) : (
                      <div id={`stripeInvoiceBadge${item.id}`} className={`stripeInvoiceBadge ${getStripeInvoiceBadgeInfo(item).cssClass}`}>
                        <span>{getStripeInvoiceBadgeInfo(item).statusText}</span>
                      </div>
                    )}
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn alignment="right">
                    <span
                      className="c-blue underline pointer"
                      onClick={() => {
                        navigateTo_Admin_ThePlanEdit(item.id);
                      }}
                    >
                      <span className="text-nowrap">{DateHelper.mediumDateFormat(item.createdAt)}</span>
                    </span>
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn alignment="center">
                    {item.clientAgreedToTerms ? (
                      <span className="fa-solid fa-check c-green"></span>
                    ) : (
                      <span className="fa-solid fa-times c-red"></span>
                    )}
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn>
                    <span className="text-nowrap">{getMostRecentCompletedMeeting(item) ?? 'None'}</span>
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn>
                    <span className="text-nowrap">{getNextScheduledMeeting(item) ?? 'None'}</span>
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn alignment="center">
                    {item.thePlanDetailMeetings.length ? (
                      <span
                        className="c-blue underline pointer"
                        onClick={() => {
                          navigateTo_Admin_ThePlanEdit(item.id);
                        }}
                      >
                        {item.thePlanDetailMeetings.length}
                      </span>
                    ) : (
                      <>{item.thePlanDetailMeetings.length}</>
                    )}
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn alignment="center">
                    {item.planDeliveryDate !== null ? (
                      <span className="fa-solid fa-check c-green"></span>
                    ) : (
                      <span className="fa-solid fa-times c-red"></span>
                    )}
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn alignment="right">{GeneralHelper.formatNumberAsCurrency(item.price)}</NectarineTableDataColumn>
                </>
              );
            }}
            searchPlaceholderText="Search The Plan clients"
            searchFn={(item, searchText) => {
              const regex = new RegExp(searchText, 'gi');
              const dataToSearch: string[] = [item.clientFirstName, item.clientLastName, item.advisorFullName, item.price.toString()];

              return dataToSearch.some((v) => regex.test(v));
            }}
          >
            <NectarineTableHeaderColumns>
              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel>
                sortFn={(v) => (v.clientFirstName + v.clientLastName).toLowerCase()}
              >
                Customer
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> sortFn={(v) => v.advisorFullName.toLowerCase()}>
                Advisor
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> sortFn={(v) => v.stripeInvoiceStatus}>
                Invoice
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> alignment="right" sortFn={(v) => v.createdAt}>
                Created At
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> sortFn={(v) => v.clientAgreedToTerms}>
                Contract
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> sortFn={(v) => getMostRecentCompletedMeeting(v)}>
                Last Meeting
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> sortFn={(v) => getNextScheduledMeeting(v)}>
                Next Meeting
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> sortFn={(v) => v.thePlanDetailMeetings.length} alignment="center">
                Meetings
              </NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel>>Plan Delivered</NectarineTableHeaderColumn>

              <NectarineTableHeaderColumn<Admin_ThePlanDetail_ViewModel> alignment="right" sortFn={(v) => v.price}>
                Price
              </NectarineTableHeaderColumn>
            </NectarineTableHeaderColumns>
          </NectarineTable>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminThePlan;
