import { addMonths, startOfDay, subYears } from 'date-fns';
import { orderBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import DateHelper from '../../../../Helpers/DateHelper';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import { useConfirmationOverlayContext } from '../../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import NectarineButton from '../../../../ui/NectarineButton/NectarineButton';
import NectarineCheckboxInput from '../../../../ui/NectarineCheckboxInput/NectarineCheckboxInput';
import NectarineDateInput from '../../../../ui/NectarineDateInput/NectarineDateInput';
import NectarineSelectInput from '../../../../ui/NectarineSelectInput/NectarineSelectInput';
import NectarineTextInput from '../../../../ui/NectarineTextInput/NectarineTextInput';
import { useNotificationsPanelContext } from '../../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';

interface EditCustomAttributesProps {
  attributes: Advisor_Attribute_ViewModel[];
  sortByFn?: (inputItem: Advisor_Attribute_ViewModel) => unknown;
  sortByDirection?: 'asc' | 'desc';
  show: boolean;
  onSave: () => void;
  toggle: () => void;
  categoryId: number;
  title: string;
}

interface AdvisorEditModel extends Advisor_Attribute_EditModel {
  current: boolean;
}

const startDate_Min = subYears(new Date(), 50);
const startDate_Max = addMonths(new Date(), 1);

const endDate_Min = subYears(new Date(), 50);
const endDate_Max = addMonths(new Date(), 3);

const AdvisorEditCustomAttributes: React.FC<EditCustomAttributesProps> = (props: EditCustomAttributesProps) => {
  const [attributes, setAttributes] = useState<Advisor_Attribute_ViewModel[]>([]);
  // const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [newAttribute, setNewAttribute] = useState<AdvisorEditModel | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const { showSuccessNotification, showErrorNotification } = useNotificationsPanelContext();

  useEffect(() => {
    let newArray = props.attributes;

    if (props.sortByFn) {
      newArray = orderBy(newArray, [props.sortByFn], [props.sortByDirection ?? 'asc']);
    }

    setAttributes(newArray);

    if (newArray.length == 0) {
      createNewItem();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveItem = async () => {
    try {
      const savedAttributeId = await AdvisorsApiService.updateAdvisorAttribute(newAttribute!);

      const newAttributesArray = attributes.filter((v) => v.id !== savedAttributeId);
      newAttributesArray.push({
        ...newAttribute!,
        id: savedAttributeId
      });

      setAttributes(newAttributesArray);

      setNewAttribute(null);
    } catch (error) {
      showErrorNotification(ErrorsHelper.getErrorMessageFromErrorObject(error));
    }
  };

  const deleteItem = async (attributeToDelete: Advisor_Attribute_ViewModel) => {
    showConfirmationOverlay({
      title: 'Confirm',
      text: 'Are you sure you want to delete this item?',
      yesButtonText: 'Confirm',
      yesAction: async () => {
        try {
          await AdvisorsApiService.deleteAdvisorAttribute(attributeToDelete.id);

          // Remove the deleted attribute from the attributes array
          const newAttributes = attributes.filter((item) => item.id !== attributeToDelete.id);
          setAttributes(newAttributes);

          showSuccessNotification('The item was deleted successfully.');
        } catch (error) {
          showErrorNotification(ErrorsHelper.getErrorMessageFromErrorObject(error));
        }
      }
    });
  };

  function createNewItem() {
    const newEducation: AdvisorEditModel = {
      categoryId: props.categoryId,
      title: '',
      subtitle: '',
      iconTag: 'fa-graduation-cap',
      id: '',
      startDate: null,
      endDate: null,
      slug: '',
      current: false
    };

    setNewAttribute(newEducation);
  }

  function editItem(itemtoEdit: Advisor_Attribute_ViewModel) {
    setNewAttribute({
      ...itemtoEdit,
      current: itemtoEdit.endDate === null || startOfDay(itemtoEdit.endDate) > startOfDay(new Date())
    });
    return false;
  }

  function cancelNewItem() {
    setNewAttribute(null);
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        <div className="bold">Edit {props.title}</div>
      </ModalHeader>
      <ModalBody>
        {newAttribute ? (
          <form ref={formRef} noValidate>
            <Row>
              <Col xs="6">
                <NectarineTextInput
                  label="Title"
                  placeholder="e.g. UCLA or Certified Financial Planner"
                  required
                  maxLength={40}
                  value={newAttribute.title}
                  onChange={(newVal) => {
                    setNewAttribute((v) => ({ ...v!, title: newVal! }));
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs="6">
                <NectarineTextInput
                  label="Subtitle (Optional)"
                  placeholder="e.g. Bachelors of Science or Since 2017"
                  maxLength={40}
                  value={newAttribute.subtitle}
                  onChange={(newVal) => {
                    setNewAttribute((v) => ({ ...v!, subtitle: newVal! }));
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs="6">
                <NectarineDateInput
                  label="Start Date (Optional)"
                  value={newAttribute.startDate}
                  onChange={(newVal) => {
                    setNewAttribute((v) => ({ ...v!, startDate: newVal! }));
                  }}
                  min={startDate_Min}
                  max={startDate_Max}
                />
              </Col>

              <Col xs="6">
                <NectarineDateInput
                  label="End Date (Optional)"
                  value={newAttribute.endDate}
                  onChange={(newVal) => {
                    setNewAttribute((v) => ({ ...v!, endDate: newVal! }));
                  }}
                  min={endDate_Min}
                  max={endDate_Max}
                  isDisabled={newAttribute.current}
                />

                <div className="mt-2"></div>

                <NectarineCheckboxInput
                  label="Current"
                  labelDisplayType="inline"
                  checked={newAttribute.current}
                  onChange={(newVal) => {
                    setNewAttribute((v) => ({ ...v!, current: newVal, endDate: newVal ? null : v!.endDate }));
                  }}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="6">
                <NectarineSelectInput
                  display="block"
                  label="Icon"
                  value={newAttribute.iconTag}
                  items={iconSelectItems}
                  onChange={(newVal) => {
                    setNewAttribute((v) => ({ ...v!, iconTag: newVal as string }));
                  }}
                />
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <i className={'fa-light fa-xl att-icon att-details ' + newAttribute.iconTag}></i>
              </Col>
            </Row>
          </form>
        ) : (
          <></>
        )}
        {!newAttribute ? (
          <Row>
            {attributes.map((item) => (
              <Col key={item.id} md="12" className="mb-4">
                <div className="d-flex">
                  <i className={'fa-light fa-xl att-icon att-details ' + item.iconTag}></i>
                  <div className="att-caption">
                    <div className="title">{item.title}</div>
                    <div className="att-subtitle">{item.subtitle}</div>
                    <div className="att-subtitle">
                      {DateHelper.printYearRange(item.startDate ?? new Date(), item.endDate ?? new Date())}
                    </div>
                  </div>

                  <div className="d-flex flex-grow-1 justify-content-end gap-2">
                    <NectarineButton
                      size="small"
                      type="white"
                      text="Edit"
                      iconClassString="fa fa-pen-to-square"
                      onClick={async () => {
                        await editItem(item);
                      }}
                    />

                    <NectarineButton
                      size="small"
                      type="red"
                      text="Delete"
                      onClick={async () => {
                        await deleteItem(item);
                      }}
                    />
                  </div>
                </div>
              </Col>
            ))}

            {!attributes.length ? <div className="c-gray">No education listings were found.</div> : <></>}
          </Row>
        ) : (
          <></>
        )}
      </ModalBody>

      <ModalFooter>
        {newAttribute ? (
          <div className="d-flex gap-2">
            <NectarineButton type="white" text="Cancel" onClick={cancelNewItem} />

            <NectarineButton
              text="Save"
              onClick={async () => {
                const formIsValid = formRef.current?.checkValidity();
                formRef.current!.reportValidity();

                if (formIsValid) {
                  await saveItem();
                }
              }}
            />
          </div>
        ) : (
          <></>
        )}

        {!newAttribute ? (
          <div className="d-flex justify-content-end gap-2">
            <NectarineButton text="New Item" type="green" iconClassString="fa-solid fa-plus" onClick={createNewItem} />

            <NectarineButton
              text="Done"
              type="blue"
              onClick={() => {
                props.onSave();

                props.toggle();
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </ModalFooter>
    </Modal>
  );
};

const iconSelectItems: NectarineSelectInputItem[] = [
  {
    name: 'Briefcase',
    value: 'fa-briefcase'
  },
  {
    name: 'Building',
    value: 'fa-building'
  },
  {
    name: 'Building with Columns',
    value: 'fa-building-columns'
  },
  {
    name: 'Certificate',
    value: 'fa-file-certificate'
  },
  {
    name: 'Chair',
    value: 'fa-chair-office'
  },
  {
    name: 'Diploma',
    value: 'fa-diploma'
  },
  {
    name: 'Dollar Bill',
    value: 'fa-money-bill'
  },
  {
    name: 'Graduation Cap',
    value: 'fa-graduation-cap'
  },
  {
    name: 'School',
    value: 'fa-school'
  }
];

export default AdvisorEditCustomAttributes;
