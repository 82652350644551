import { forwardRef } from 'react';
import NectarineInfoIcon from '../NectarineInfoIcon/NectarineInfoIcon';
import './NectarineSelectInput.scss';

interface NectarineSelectInputProps {
  value: string | number | null;
  onChange: (newValue: string | number | null) => void;
  isDisabled?: boolean;
  required?: boolean;
  placeholder?: string;
  label?: string;
  secondaryLabel?: string;
  items: NectarineSelectInputItem[];
  style?: 'regular' | 'rounded';
  display?: 'block' | 'inline-block';
  infoIconTooltipText?: string;
}

const NectarineSelectInput = forwardRef<HTMLSelectElement, NectarineSelectInputProps>((props: NectarineSelectInputProps, ref) => {
  return (
    <>
      <div>
        {props.label ? (
          <div className="labelWrapper">
            <label className="bold mb-1">
              {props.label}
              {props.required ? <span className="ms-1 c-green"> *</span> : ''}
            </label>

            {props.infoIconTooltipText ? <NectarineInfoIcon tooltipText={props.infoIconTooltipText} /> : <></>}

            {props.secondaryLabel ? <span className="ms-1">{props.secondaryLabel}</span> : <></>}
          </div>
        ) : (
          <></>
        )}

        <div
          className={`selectInputWrapper ${props.style === 'rounded' ? 'isRounded' : ''} ${(props.display ?? 'block') === 'block' ? 'd-block' : 'd-inline-block'}`}
        >
          <select
            ref={ref}
            name="name"
            className="form-select"
            onChange={(e) => {
              props.onChange(e.target.value);
            }}
            value={props.value ?? undefined}
            disabled={props.isDisabled}
          >
            {props.items.map((currItem) => {
              return (
                <option key={currItem.value} value={currItem.value}>
                  {currItem.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </>
  );
});

NectarineSelectInput.displayName = 'NectarineSelectInput';

export default NectarineSelectInput;
