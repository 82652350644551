export default class ValidationHelper {
  // https://stackoverflow.com/a/46181/27423149
  public static validateEmail(email: string): boolean {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email.toLowerCase()
    );
  }

  public static validateStringLength(input: string, minLength: number, maxLength: number): boolean {
    const cleanedUpInput = (input ?? '').trim();
    return cleanedUpInput.length >= minLength && cleanedUpInput.length <= maxLength;
  }

  public static validateNumericRange(input: number, min: number, max: number): boolean {
    const cleanedUpInput = input ?? 0;
    return cleanedUpInput >= min && cleanedUpInput <= max;
  }
}
