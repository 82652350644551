import { ApplicationRoutes } from './constants';

// Define the routes structure for the application,
// assigning actual components to the routes in the AppRoutes.tsx file.
// We split things like this so we can import this into a node script that generates a sitemap for us on build.
// If we import JSX elements into this file, it creates all kinds of weirdness when importing this into a
// node runtime environment.
const AppRoutesConfiguration: AppRouteListing[] = [
  {
    name: 'Root',
    path: ApplicationRoutes.Root,
    subRoutes: [
      {
        name: 'Home',
        isIndex: true
      },
      {
        name: 'Contact',
        path: ApplicationRoutes.Contact
      },
      {
        name: 'Error',
        path: ApplicationRoutes.Error
      },
      {
        name: 'Advisors',
        path: ApplicationRoutes.Advisors
      },
      {
        name: 'Advisors_StateAndSingleFilter',
        path: '/s/:urlState/:singleFilter?'
      },
      {
        name: 'Advisors_SingleFilter',
        path: `${ApplicationRoutes.Advisors}/:singleFilter`
      },
      {
        name: 'AdvisorDetails',
        path: `${ApplicationRoutes.Advisor}/:slug`
      },
      {
        name: 'About',
        path: ApplicationRoutes.About
      },
      {
        name: 'Booked',
        path: ApplicationRoutes.Booked
      },
      {
        name: 'Terms',
        path: ApplicationRoutes.Terms
      },
      {
        name: 'ThePlan',
        path: ApplicationRoutes.ThePlan
      },
      {
        name: 'Register',
        path: ApplicationRoutes.Register
      },
      {
        name: 'Recruit',
        path: ApplicationRoutes.Recruit
      },
      {
        name: 'Affiliates',
        path: ApplicationRoutes.Affiliates
      },
      {
        name: 'NonUS',
        path: ApplicationRoutes.NonUS
      },
      {
        name: 'Apply',
        path: ApplicationRoutes.Apply
      },
      {
        name: 'Privacy',
        path: ApplicationRoutes.Privacy
      },
      {
        name: 'ThePlan_Terms',
        path: `${ApplicationRoutes.ThePlan_Terms}/:slugOrThePlanDetailId?`
      },
      {
        name: 'LeaveAReview',
        path: `${ApplicationRoutes.LeaveAReview}/:adminID/:consumerID`
      },
      {
        name: 'HourlyFinancialAdvisors',
        path: ApplicationRoutes.HourlyFinancialAdvisors
      },
      {
        name: 'FlatFeeFinancialPlanners',
        path: ApplicationRoutes.FlatFeeFinancialPlanners
      },
      {
        name: 'Services',
        path: `${ApplicationRoutes.Services}/:slug`
      },
      {
        name: 'AffiliatesLandingPage',
        path: '/r/:slug'
      },

      // Admin Routes
      {
        name: 'AdminRoot',
        path: ApplicationRoutes.AdminRoutes.Root,
        subRoutes: [
          {
            name: 'AdminRootIndex',
            isIndex: true
          },
          {
            name: 'AdminSearch',
            path: ApplicationRoutes.AdminRoutes.Search
          },
          {
            name: 'AdminComplianceReport',
            path: `${ApplicationRoutes.AdminRoutes.Users}/:userID/report`
          },
          {
            name: 'AdminUsers',
            path: ApplicationRoutes.AdminRoutes.Users,
            subRoutes: [
              {
                name: 'AdminUsersIndex',
                isIndex: true
              },
              {
                name: 'AdminEditUser',
                path: ':userID/edit'
              }
            ]
          },
          {
            name: 'AdminRolesRoot',
            path: ApplicationRoutes.AdminRoutes.RolesRoutes.Root,
            subRoutes: [
              {
                name: 'AdminRolesIndex',
                isIndex: true
              },
              {
                name: 'AdminEditRole',
                path: ':roleID/edit'
              },
              {
                name: 'AdminCreateRole',
                path: ApplicationRoutes.AdminRoutes.RolesRoutes.Create
              }
            ]
          },

          {
            name: 'AdminUserAgreements',
            path: ApplicationRoutes.AdminRoutes.UserAgreements
          },
          {
            name: 'AdminReviews',
            path: ApplicationRoutes.AdminRoutes.Reviews
          },
          {
            name: 'AdminDocuments',
            path: ApplicationRoutes.AdminRoutes.Documents
          },
          {
            name: 'AdminAdvisorAttributes',
            path: ApplicationRoutes.AdminRoutes.AdvisorAttributes
          },
          {
            name: 'AdminAdvisorPayments',
            path: ApplicationRoutes.AdminRoutes.AdvisorPayments
          },
          {
            name: 'AdminAffiliatePayments',
            path: ApplicationRoutes.AdminRoutes.AffiliatePayments
          },
          {
            name: 'AdminReports',
            path: ApplicationRoutes.AdminRoutes.Reports
          },
          {
            name: 'AdminThePlan',
            path: ApplicationRoutes.AdminRoutes.ThePlan
          },
          {
            name: 'AdminEditThePlan',
            path: `${ApplicationRoutes.AdminRoutes.ThePlan_Edit}/:reloadId/:thePlanInstanceId?`
          },
          {
            name: 'AdminFeatureFlags',
            path: ApplicationRoutes.AdminRoutes.FeatureFlags
          },
          {
            name: 'AdminIntakeForms',
            path: ApplicationRoutes.AdminRoutes.IntakeForms
          },
          {
            name: 'AdminEventsRoot',
            path: ApplicationRoutes.AdminRoutes.Events,
            subRoutes: [
              {
                name: 'AdminEventsIndex',
                isIndex: true
              },
              {
                name: 'AdminEditEvent',
                path: ':eventID/edit'
              }
            ]
          },
          {
            name: 'AdminRecordingsRoot',
            path: ApplicationRoutes.AdminRoutes.Recordings,
            subRoutes: [
              {
                name: 'AdminRecordingsIndex',
                isIndex: true
              },
              {
                name: 'AdminEditRecording',
                path: ':recordingID/edit'
              }
            ]
          }
        ]
      }
    ]
  },

  // Client Routes
  {
    name: 'ClientAccountRoot',
    path: ApplicationRoutes.ClientAccountRoutes.Root,
    subRoutes: [
      {
        name: 'ClientAccountIndex',
        isIndex: true
      },
      {
        name: 'ClientAccountReviews',
        path: ApplicationRoutes.ClientAccountRoutes.Reviews
      },
      {
        name: 'ClientAccountAffiliate',
        path: ApplicationRoutes.ClientAccountRoutes.Share
      }
    ]
  },

  // Advisor Routes
  {
    name: 'AdvisorAccountRoot',
    path: ApplicationRoutes.AdvisorAccountRoutes.Root,
    subRoutes: [
      {
        name: 'AdvisorAccountIndex',
        isIndex: true
      },
      {
        name: 'AdvisorAccountProfile',
        path: ApplicationRoutes.AdvisorAccountRoutes.Profile
      },
      {
        name: 'AdvisorAccountReviews',
        path: ApplicationRoutes.AdvisorAccountRoutes.Reviews
      },
      {
        name: 'AdvisorAccountClient',
        path: `${ApplicationRoutes.AdvisorAccountRoutes.Client}/:clientID`
      },
      {
        name: 'AdvisorAccountPayments',
        path: ApplicationRoutes.AdvisorAccountRoutes.Payments
      },
      {
        name: 'AdvisorAccountComplianceReport',
        path: `${ApplicationRoutes.AdvisorAccountRoutes.Compliance}/:userID/report`
      }
    ]
  },

  // Authentication Routes
  {
    name: 'AuthenticationLogin',
    path: ApplicationRoutes.AuthenticationRoutes.Login
  },
  {
    name: 'AuthenticationLoginFailed',
    path: ApplicationRoutes.AuthenticationRoutes.LoginFailed
  },
  {
    name: 'AuthenticationLoginCallback',
    path: ApplicationRoutes.AuthenticationRoutes.LoginCallback
  },
  {
    name: 'AuthenticationProfile',
    path: ApplicationRoutes.AuthenticationRoutes.Profile
  },
  {
    name: 'AuthenticationLogout',
    path: ApplicationRoutes.AuthenticationRoutes.Logout
  },
  {
    name: 'AuthenticationLogOutCallback',
    path: ApplicationRoutes.AuthenticationRoutes.LogOutCallback
  },
  {
    name: 'AuthenticationLoggedOut',
    path: ApplicationRoutes.AuthenticationRoutes.LoggedOut
  }
];

const getAppRouteComponent = (appRoutesListings: AppRouteListing[], appRouteName: AppRouteNames): AppRouteListing | null => {
  let returnValue: AppRouteListing | null = null;

  const getComponent = (appRoutesListings2: AppRouteListing[]) => {
    if (returnValue) {
      return;
    }

    for (const currItem of appRoutesListings2) {
      if (currItem.name === appRouteName) {
        returnValue = currItem;
        return;
      }

      if (currItem.subRoutes?.length) {
        getComponent(currItem.subRoutes);
      }
    }
  };

  getComponent(appRoutesListings);

  return returnValue;
};

const setAppRouteComponent = (appRoutesListings: AppRouteListing[], appRouteName: AppRouteNames, component: unknown): void => {
  let componentWasSet = false;

  const setComponent = (appRoutesListings2: AppRouteListing[]) => {
    if (componentWasSet) {
      return;
    }

    for (const currItem of appRoutesListings2) {
      if (currItem.name === appRouteName) {
        currItem.component = component;
        componentWasSet = true;
        return;
      }

      if (currItem.subRoutes?.length) {
        setComponent(currItem.subRoutes);
      }
    }
  };

  setComponent(appRoutesListings);
};

const getMissingAppRouteComponents = (
  appRoutesListings: AppRouteListing[],
  missingAppRouteComponents?: AppRouteListing[]
): AppRouteListing[] => {
  missingAppRouteComponents = missingAppRouteComponents ?? [];

  appRoutesListings.forEach((currRoute) => {
    // Routes with children are allowed to not have a component specified (but they also CAN have a component specified)
    if (!currRoute.component && !currRoute.subRoutes?.length) {
      missingAppRouteComponents.push(currRoute);
    }

    if (currRoute.subRoutes?.length) {
      getMissingAppRouteComponents(currRoute.subRoutes, missingAppRouteComponents);
    }
  });

  return missingAppRouteComponents;
};

export { AppRoutesConfiguration, getAppRouteComponent, getMissingAppRouteComponents, setAppRouteComponent };
