import { addDays, addWeeks, isBefore, startOfDay } from 'date-fns';
import { sortBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ThePlanMeetingStatus, ValueLimits } from '../../../../constants';
import useNavigationService from '../../../../hooks/UseNavigationService';
import AdminApiService from '../../../../services/api/AdminApiService';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import DateHelper from '../../../Helpers/DateHelper';
import GeneralHelper from '../../../Helpers/GeneralHelper';
import ValidationHelper from '../../../Helpers/ValidationHelper';
import { useConfirmationOverlayContext } from '../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineAutocompleteInput from '../../../ui/NectarineAutocompleteInput/NectarineAutocompleteInput';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineDateInput from '../../../ui/NectarineDateInput/NectarineDateInput';
import NectarineNumericInput from '../../../ui/NectarineNumericInput/NectarineNumericInput';
import NectarineSelectInput from '../../../ui/NectarineSelectInput/NectarineSelectInput';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import NectarineTextAreaInput from '../../../ui/NectarineTextAreaInput/NectarineTextAreaInput';
import NectarineTextInput from '../../../ui/NectarineTextInput/NectarineTextInput';
import { useNotificationsPanelContext } from '../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';

// Create a wrapper component that will mount / unmount the actual page component
// when the reloadId route parameter changes, so we don't have to manually reset things inside
// the page component when we're reloading the page for the same thePlanInstanceId
const AdminEditThePlan: React.FC = () => {
  const { reloadId } = useParams();

  return <AdminEditThePlanInnerComponent key={reloadId} />;
};

const AdminEditThePlanInnerComponent: React.FC = () => {
  const { thePlanInstanceId, reloadId } = useParams();

  const threeDaysFromToday = useRef<Date>(addDays(startOfDay(new Date()), 3));
  const twoWeeksFromToday = useRef<Date>(addWeeks(startOfDay(new Date()), 2));

  const [thePlanInstance, setThePlanInstance] = useState<Admin_ThePlanDetail_ViewModel | null>(null);

  const [thePlanInstanceEditModel, setThePlanInstanceEditModel] = useState<Admin_ThePlanDetail_EditModel | null>(null);

  const [allAdvisors, setAllAdvisors] = useState<Advisor_BasicDetails_ViewModel[]>([]);

  const [isSavingData, setIsSavingData] = useState<boolean>(false);

  const { showErrorNotification, showSuccessNotification } = useNotificationsPanelContext();

  const { navigateTo_Error, navigateTo_Admin_ThePlanEdit, navigateTo_Admin_ThePlanDetailsView } = useNavigationService();

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const advisorsResults = await AdvisorsApiService.getAllAdvisorDetails();
        setAllAdvisors(sortBy(advisorsResults, (v) => v.fullName));

        if (!thePlanInstanceId) {
          setThePlanInstance({
            advisorFullName: '',
            advisorId: '',
            bccEmail: '',
            clientAgreedToTerms: false,
            clientEmail: '',
            clientFirstName: '',
            clientId: '',
            clientLastName: '',
            contractUrl: '',
            otherContractUrl: '',
            createdAt: new Date(),
            deductionPrice: 0,
            extraInformation: '',
            fromEmail: '',
            id: '',
            notes: '',
            planDeliveryDate: null,
            planWasInitiatedManually: false,
            price: 0,
            startDate: new Date(),
            stripeInvoiceDueDate: null,
            stripeInvoiceId: '',
            stripeInvoicePaidDate: null,
            stripeInvoiceStatus: '',
            stripeInvoiceUrl: '',
            thePlanDetailMeetings: []
          });

          setThePlanInstanceEditModel({
            id: '',
            clientId: '',
            advisorId: '',
            notes: '',
            price: 0,
            stripeInvoiceId: '',
            stripeInvoicePaidDate: null,
            planDeliveryDate: null,
            planStartDate: startOfDay(new Date()),
            otherContractUrl: ''
          });

          return;
        }

        const result = await AdminApiService.getThePlanInstance(thePlanInstanceId);

        setThePlanInstance(result);

        setThePlanInstanceEditModel((v) => ({
          id: result.id,
          clientId: result.clientId,
          advisorId: result.advisorId,
          extraInformation: result.extraInformation,
          notes: result.notes,
          price: result.price,
          stripeInvoiceId: result.stripeInvoiceId,
          planDeliveryDate: result.planDeliveryDate,
          stripeInvoicePaidDate: result.stripeInvoicePaidDate,
          planStartDate: result.startDate,
          otherContractUrl: result.otherContractUrl
        }));
      } catch (error) {
        showErrorNotification('Sorry, but an error occurred while loading the plan details.');

        navigateTo_Error();
      }
    };
    loadData();
  }, [navigateTo_Error, reloadId, showErrorNotification, thePlanInstanceId]);

  const saveChanges = async () => {
    const validateThePlan = (): string[] => {
      const advisorId = (thePlanInstanceEditModel?.advisorId ?? '').trim();
      const clientId = (thePlanInstanceEditModel?.clientId ?? '').trim();
      const stripeInvoicePaidDate = thePlanInstanceEditModel?.stripeInvoicePaidDate ?? null;
      const price = thePlanInstanceEditModel?.price ?? 0;
      // const extraInformation = thePlanInstanceEditModel?.extraInformation ?? '';
      const notes = thePlanInstanceEditModel?.notes ?? '';
      const planDeliveryDate = thePlanInstanceEditModel?.planDeliveryDate ?? null;
      const planStartDate = thePlanInstanceEditModel?.planStartDate ?? null;

      const errors: string[] = [];

      if (!GeneralHelper.isStringAGuid(advisorId)) {
        errors.push('The advisor is required.');
      }

      if (!GeneralHelper.isStringAGuid(clientId)) {
        errors.push('The consumer is required.');
      }

      if (stripeInvoicePaidDate && !isBefore(stripeInvoicePaidDate, addDays(new Date(), 3))) {
        errors.push('The Stripe invoice paid date must be less than 3 days from today.');
      }

      if (planDeliveryDate && !isBefore(planDeliveryDate, addDays(new Date(), 3))) {
        errors.push('The Plan delivery date must be less than 3 days from today.');
      }

      if (planStartDate && !isBefore(planStartDate, addWeeks(new Date(), 2))) {
        errors.push('The Plan start date must be less than 2 weeks from today.');
      }

      if (!ValidationHelper.validateStringLength(notes, 0, ValueLimits.ThePlan_Notes_MaxLength)) {
        errors.push(`The notes must be between 0 & ${ValueLimits.ThePlan_Notes_MaxLength} characters.`);
      }

      if (!ValidationHelper.validateNumericRange(price, ValueLimits.ThePlan_Price_MinValue, ValueLimits.ThePlan_Price_MaxValue)) {
        errors.push(`The price must be between ${ValueLimits.ThePlan_Price_MinValue} & ${ValueLimits.ThePlan_Price_MaxValue} characters.`);
      }

      return errors;
    };

    const validationResults = validateThePlan();
    if (validationResults.length) {
      showErrorNotification(validationResults.join('<br/>'));
      return;
    }

    const isNewPlanEntry = !thePlanInstanceEditModel?.id;

    showConfirmationOverlay({
      title: `Confirm ${isNewPlanEntry ? 'Add' : 'Update'}`,
      text: `Are you sure you want to ${isNewPlanEntry ? 'create' : 'update'} the Plan instance?`,
      noButtonText: 'Not yet',
      yesButtonText: isNewPlanEntry ? 'Create' : 'Update',
      yesAction: async () => {
        try {
          setIsSavingData(true);

          // await new Promise((resolve) => {
          //   setTimeout(() => {
          //     resolve(null);
          //   }, 2000);
          // });

          const newId = await AdminApiService.editThePlan(thePlanInstanceEditModel!);

          if (newId) {
            showSuccessNotification(`The Plan entry was ${isNewPlanEntry ? 'added' : 'updated'} successfully.`);
            navigateTo_Admin_ThePlanEdit(newId);
          }
        } catch (error) {
          showErrorNotification(`Sorry, but an error occurred while ${isNewPlanEntry ? 'adding a new' : 'updating the'} Plan instance.`);
        } finally {
          setIsSavingData(false);
        }
      }
    });
  };

  const getThePlanMeetingStatusBadgeInfo = (
    item: Admin_ThePlanDetailMeeting_ViewModel
  ): {
    statusText: string;
    cssClass: string;
  } => {
    switch (item.status) {
      case ThePlanMeetingStatus.Cancelled:
        return {
          statusText: ThePlanMeetingStatus.Cancelled,
          cssClass: 'cancelled'
        };

      case ThePlanMeetingStatus.Completed:
        return {
          statusText: ThePlanMeetingStatus.Completed,
          cssClass: 'completed'
        };

      case ThePlanMeetingStatus.Scheduled:
        return {
          statusText: ThePlanMeetingStatus.Scheduled,
          cssClass: 'scheduled'
        };
    }

    return {
      cssClass: '',
      statusText: item.status
    };
  };

  return (
    <>
      <span
        className="c-blue2 pointer"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          navigateTo_Admin_ThePlanDetailsView();
        }}
      >
        <i className="c-black fa-regular fa-arrow-left back-arrow"></i> Back
      </span>

      <div className="mb-3"></div>

      <h2 className="mb-3 d-flex">
        {thePlanInstanceEditModel ? <span className="me-4">{thePlanInstanceEditModel.id ? 'Edit' : 'Add'} The Plan</span> : <></>}
      </h2>

      {!thePlanInstanceEditModel ? <LoadingSpinner message="Loading..." /> : <></>}

      {thePlanInstanceEditModel ? (
        <form ref={formRef} noValidate>
          <div className="mt-3">
            <div className="w-50">
              <NectarineSelectInput
                label="Advisor"
                items={[
                  {
                    name: 'Choose an advisor...',
                    value: ''
                  },
                  ...allAdvisors.map((v) => ({
                    name: `${v.firstName} ${v.lastName}`,
                    value: v.id
                  }))
                ]}
                value={thePlanInstanceEditModel.advisorId}
                onChange={(newVal) => {
                  setThePlanInstanceEditModel((v) => ({ ...v!, advisorId: newVal as string }));
                }}
                required
                isDisabled={isSavingData}
              />
            </div>

            <div className="mt-3 w-50">
              <NectarineAutocompleteInput<User_BasicDetails_ViewModel>
                label="Consumer ID"
                initialValue={thePlanInstanceEditModel.clientId}
                placeholder="Search by consumer name or database ID"
                onChange={(newVal) => {
                  setThePlanInstanceEditModel((v) => ({ ...v!, clientId: newVal! }));
                }}
                required
                suggestionsApiSearchFn={(newVal) => AdminApiService.searchUsers(newVal)}
                suggestionDisplayNameProperty="fullName"
                suggestionValueProperty="id"
                selectedEntityIconClass="fa-duotone fa-regular fa-user"
                isDisabled={isSavingData}
              />
            </div>

            <div className="basicContainer w-50 mt-4">
              <span className="containerLabel">
                <i className="fa-brands fa-cc-stripe c-blue2"></i>
              </span>

              <div>
                <NectarineTextInput
                  label="Stripe Invoice ID"
                  placeholder="Enter the Stripe Invoice ID here (ex: in_1QVdBvXXXi6sKVCjI88XpXXX)"
                  value={thePlanInstanceEditModel.stripeInvoiceId}
                  onChange={(newVal) => {
                    setThePlanInstanceEditModel((v) => ({ ...v!, stripeInvoiceId: newVal }));
                  }}
                  isDisabled={isSavingData}
                />
              </div>

              <div className="mt-3 w-50">
                <NectarineDateInput
                  label="Stripe Invoice Paid Date"
                  infoIconTooltipText="Setting this date when it isn't previously set and saving will set the local Stripe invoice status to 'Paid'."
                  value={thePlanInstanceEditModel.stripeInvoicePaidDate}
                  onChange={(newVal) => {
                    setThePlanInstanceEditModel((v) => ({ ...v!, stripeInvoicePaidDate: newVal }));
                  }}
                  // NOTE: We don't set a min value, to preserve compatibility with old data
                  max={threeDaysFromToday.current}
                  isDisabled={isSavingData}
                />
              </div>
            </div>

            <div className="mt-3 w-50">
              <NectarineTextInput
                label="Other Contract Url"
                placeholder="Enter the other contract URL here"
                infoIconTooltipText={`Enter the URL of the other contract URL (ex. a DocuSign URL or a link to the user's Nectarine compliance report)`}
                value={thePlanInstanceEditModel.otherContractUrl}
                onChange={(newVal) => {
                  setThePlanInstanceEditModel((v) => ({ ...v!, otherContractUrl: newVal }));
                }}
                isDisabled={isSavingData}
              />
            </div>

            <div className="mt-3 w-50">
              <NectarineNumericInput
                label="Price"
                value={thePlanInstanceEditModel.price}
                onChange={(newVal) => {
                  setThePlanInstanceEditModel((v) => ({ ...v!, price: newVal! }));
                }}
                required
                min={ValueLimits.ThePlan_Price_MinValue}
                max={ValueLimits.ThePlan_Price_MaxValue}
                isDisabled={isSavingData}
              />
            </div>

            <div className="d-flex w-50">
              <div className="mt-3 w-50">
                <NectarineDateInput
                  label="Plan Start Date"
                  infoIconTooltipText="The date the Plan was started, set to the current date when a welcome email is sent. This date drives automated Stripe invoice due dates, etc."
                  value={thePlanInstanceEditModel.planStartDate}
                  onChange={(newVal) => {
                    setThePlanInstanceEditModel((v) => ({ ...v!, planStartDate: newVal! }));
                  }}
                  required
                  max={twoWeeksFromToday.current}
                  isDisabled={isSavingData}
                />
              </div>

              <div className="mt-3 w-50">
                <NectarineDateInput
                  label="Plan Delivery Date"
                  infoIconTooltipText="Setting this date when it isn't previously set and saving will generate a payable event for the advisor (coming soon)."
                  value={thePlanInstanceEditModel.planDeliveryDate}
                  onChange={(newVal) => {
                    setThePlanInstanceEditModel((v) => ({ ...v!, planDeliveryDate: newVal }));
                  }}
                  max={threeDaysFromToday.current}
                  isDisabled={isSavingData}
                />
              </div>
            </div>

            <div className="mt-3 w-50">
              <NectarineTextAreaInput
                label="Notes"
                rows={3}
                placeholder="Enter notes"
                value={thePlanInstanceEditModel.notes}
                onChange={(newVal) => {
                  setThePlanInstanceEditModel((v) => ({ ...v!, notes: newVal }));
                }}
                maxLength={ValueLimits.ThePlan_Notes_MaxLength}
                isDisabled={isSavingData}
              />
            </div>

            <div className="mt-3 w-50">
              <NectarineTextAreaInput
                label="Extra Information"
                secondaryLabel="(Welcome Email Notes)"
                rows={3}
                placeholder="Enter extra information"
                value={thePlanInstance?.extraInformation ?? ''}
                onChange={() => {}}
                maxLength={ValueLimits.ThePlan_ExtraInformation_MaxLength}
                readOnly={true}
                readOnlyPlaceholderText="(No extra information)"
              />
            </div>

            <div className="mt-3">
              <NectarineButton
                text="Save"
                showLoadingDots={isSavingData}
                onClick={async () => {
                  const formIsValid = formRef.current?.checkValidity();
                  formRef.current!.reportValidity();

                  if (formIsValid) {
                    await saveChanges();
                  }
                }}
                isDisabled={isSavingData}
              />
            </div>
          </div>
        </form>
      ) : (
        <></>
      )}
      {thePlanInstance?.id ? (
        <>
          <div className="mt-5"></div>

          <hr />

          <h2 className="mt-3 d-flex">
            <span className="me-4">Meeting Logs</span>
          </h2>

          {thePlanInstance.thePlanDetailMeetings.length ? (
            <NectarineTable<Admin_ThePlanDetailMeeting_ViewModel>
              maxHeight="50vh"
              data={thePlanInstance.thePlanDetailMeetings ?? []}
              rowDataTemplate={(item, index) => {
                return (
                  <>
                    {/* TODO - this needs to be driven off some kind of data if we can, not just an index */}
                    <NectarineTableDataColumn>{index + 1}</NectarineTableDataColumn>

                    <NectarineTableDataColumn>
                      {/* TODO - this should link to the event ID if we can  */}
                      <span className="text-nowrap">{DateHelper.mediumDateFormat(item.startTime)}</span>
                    </NectarineTableDataColumn>

                    <NectarineTableDataColumn>
                      <div
                        id={`thePlanMeetingBadge${item.id}`}
                        className={`thePlanMeetingBadge ${getThePlanMeetingStatusBadgeInfo(item).cssClass}`}
                      >
                        {getThePlanMeetingStatusBadgeInfo(item).statusText}
                      </div>
                    </NectarineTableDataColumn>

                    <NectarineTableDataColumn alignment="center">
                      <div className="d-flex gap-3">
                        {/* TODO - wire these up */}

                        <a href={item.zoomJoinUrl} target="_blank" rel="noopener noreferrer">
                          Zoom
                        </a>
                        {/* <div>Reschedule</div>
                        <div>Cancel</div> */}
                      </div>
                    </NectarineTableDataColumn>
                  </>
                );
              }}
            >
              <NectarineTableHeaderColumns>
                <NectarineTableHeaderColumn<Admin_ThePlanDetailMeeting_ViewModel>>Meeting Number</NectarineTableHeaderColumn>

                <NectarineTableHeaderColumn<Admin_ThePlanDetailMeeting_ViewModel> sortFn={(v) => v.startTime}>
                  Start Time
                </NectarineTableHeaderColumn>

                <NectarineTableHeaderColumn<Admin_ThePlanDetailMeeting_ViewModel>
                  sortFn={(v) => getThePlanMeetingStatusBadgeInfo(v).statusText}
                >
                  Status
                </NectarineTableHeaderColumn>

                <NectarineTableHeaderColumn<Admin_ThePlanDetailMeeting_ViewModel> alignment="center">Links</NectarineTableHeaderColumn>
              </NectarineTableHeaderColumns>
            </NectarineTable>
          ) : (
            <span className="c-gray">(No meetings were found)</span>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AdminEditThePlan;
