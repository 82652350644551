import DOMPurify from 'dompurify';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import NectarineInfoIcon from '../NectarineInfoIcon/NectarineInfoIcon';
import './NectarineTextInput.scss';

const NectarineTextInput = forwardRef<HTMLInputElement, NectarineTextInputProps>((props: NectarineTextInputProps, ref) => {
  const textChangeTimeout = useRef<NodeJS.Timeout | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);

  // We have our own internal ref for the input, so forward that along to the forwardRef ref object
  // that comes into this component as a parameter
  useImperativeHandle(ref, () => inputRef.current!);

  if (props.minLength !== undefined && !props.required) {
    throw new Error(
      'The min length is set on a NectarineTextInput but the required attribute was false, ' +
        'which means text length validation will not occur for this input.' +
        (props.label ? `\nThis was for text input with label '${props.label}'.` : '')
    );
  }

  if (props.value !== undefined && props.debounceTimeout) {
    throw new Error(`The debounce timeout cannot be used in conjunction with a controlled component (ie. a provided 'value' property.)`);
  }

  const isUncontrolledComponent = props.debounceTimeout !== undefined;

  return (
    <>
      <div>
        {props.label ? (
          <div className="labelWrapper">
            <label className="bold mb-1">
              {props.label} {props.required ? <span className="ms-1 c-green"> *</span> : ''}
            </label>

            {props.infoIconTooltipText ? <NectarineInfoIcon tooltipText={props.infoIconTooltipText} /> : <></>}

            {props.secondaryLabel ? (
              <>
                {(props.secondaryLabelAlignment ?? 'inline') === 'inline' ? (
                  <div>
                    {props.secondaryLabelIconCssClass ? <span className={props.secondaryLabelIconCssClass}></span> : <></>}

                    <span className="ms-1">{props.secondaryLabelAlignment}</span>
                  </div>
                ) : (
                  <div className="ms-1 flex-grow-1 text-end">
                    {props.secondaryLabelIconCssClass ? <span className={`${props.secondaryLabelIconCssClass} me-2`}></span> : <></>}

                    <span className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.secondaryLabel) }}></span>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className={`textInputWrapper ${props.iconClassString ? 'hasIcon' : ''} ${props.readOnly ? 'isReadOnly' : ''}`}>
          {props.readOnly ? (
            props.value ? (
              <div className="d-flex align-items-center">{props.value}</div>
            ) : (
              <span className="c-gray">{props.readOnlyPlaceholderText || '(No content)'}</span>
            )
          ) : (
            <>
              <input
                ref={inputRef}
                className="form-control"
                type={props.type ?? 'text'}
                value={!isUncontrolledComponent ? (props.value ?? '') : undefined}
                defaultValue={props.initialValue ?? undefined}
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (props.isDisabled) {
                    return;
                  }

                  let newValue = e.target.value;

                  if (props.maxLength !== undefined && (newValue ?? '').length > props.maxLength) {
                    newValue = newValue.substring(0, props.maxLength);
                  }

                  // If we're debouncing things, the input is uncontrolled and we want to fire the
                  // onChange event after the user stops typing for the specified duration
                  if (isUncontrolledComponent) {
                    if (textChangeTimeout.current) {
                      clearTimeout(textChangeTimeout.current);
                    }

                    textChangeTimeout.current = setTimeout(() => {
                      props.onChange(newValue);
                    }, props.debounceTimeout);
                  } else {
                    props.onChange(newValue);
                  }
                }}
                onFocus={() => {
                  props.onFocus?.();
                }}
                onBlur={() => {
                  props.onBlur?.();
                }}
                onKeyDown={(e) => {
                  props.onKeyDown?.(e);
                }}
                placeholder={props.placeholder}
                required={props.required}
                disabled={props.isDisabled}
                minLength={props.minLength}
                maxLength={props.maxLength}
              />

              {props.iconClassString ? (
                <span className="inputIcon">
                  <span className={props.iconClassString}></span>
                </span>
              ) : (
                <></>
              )}

              {!props.isDisabled &&
              (isUncontrolledComponent
                ? ((inputRef as any)?.current?.value ?? '').trim().length > 0
                : (props.value ?? '').trim().length > 0) ? (
                <span
                  className="clearIcon"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    props.onChange('');

                    if (isUncontrolledComponent && (inputRef as any)?.current) {
                      (inputRef as any).current.value = '';
                    }
                  }}
                >
                  <span className="fa-solid fa-times"></span>
                </span>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
});

NectarineTextInput.displayName = 'NectarineTextInput';

export default NectarineTextInput;
