import { forwardRef } from 'react';
import './NectarineTextAreaInput.scss';

interface NectarineTextAreaInputProps {
  value: string | null;
  onChange: (newValue: string | null) => void;
  isDisabled?: boolean;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  label?: string;
  rows?: number;
  secondaryLabel?: string;
  readOnly?: boolean;
  readOnlyPlaceholderText?: string;
}

const NectarineTextAreaInput = forwardRef<HTMLTextAreaElement, NectarineTextAreaInputProps>((props: NectarineTextAreaInputProps, ref) => {
  if (props.minLength !== undefined && !props.required) {
    console.warn(
      'The min length is set on a NectarineTextAreaInput but the required attribute was false, ' +
        'which means text length validation will not occur for this input.' +
        (props.label ? `\nThis was for text input with label '${props.label}'.` : '')
    );
  }

  return (
    <>
      <div>
        {props.label ? (
          <div className="labelWrapper">
            <label className="bold mb-1">{props.label}</label>
            {props.secondaryLabel ? <span className="ms-1">{props.secondaryLabel}</span> : <></>}

            {props.required ? <span className="ms-1 c-green"> *</span> : ''}
          </div>
        ) : (
          <></>
        )}

        <div className={`textAreaInputWrapper ${props.readOnly ? 'isReadOnly' : ''}`}>
          {props.readOnly ? (
            props.value ? (
              <div>{props.value}</div>
            ) : (
              <span className="c-gray">{props.readOnlyPlaceholderText || '(No content)'}</span>
            )
          ) : (
            <textarea
              ref={ref}
              className="form-control"
              required={props.required}
              disabled={props.isDisabled}
              minLength={props.minLength}
              maxLength={props.maxLength}
              placeholder={props.placeholder}
              value={props.value ?? ''}
              rows={!props.readOnly ? (props.rows ?? 2) : 2}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (props.isDisabled) {
                  return;
                }

                let newValue = e.target.value;

                if (props.maxLength !== undefined && (newValue ?? '').length > props.maxLength) {
                  newValue = newValue.substring(0, props.maxLength);
                }

                props.onChange(e.target.value);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
});

NectarineTextAreaInput.displayName = 'NectarineTextAreaInput';

export default NectarineTextAreaInput;
