import { BadRequestError } from './Utilities';

const getStringFromErrorObject = (errorObj: string | Error | unknown): string => {
  let messageToLog = '';

  if (typeof errorObj === 'string') {
    messageToLog = errorObj;
  } else if (errorObj instanceof Error) {
    messageToLog = `${errorObj.name}: ${errorObj.message}`;
  } else {
    messageToLog = JSON.stringify(errorObj);
  }

  messageToLog = messageToLog ?? 'Unknown Error';

  return messageToLog;
};

export class ErrorLogService {
  public static logError(source: string, message: string | Error, errorObj?: unknown, otherInformation: unknown = null): void {
    let messageToLog = getStringFromErrorObject(message);
    const errorInfo = errorObj ? getStringFromErrorObject(errorObj) : null;

    if (errorInfo) {
      messageToLog += '\n' + errorInfo;
    }

    // We do not log BadRequestError error objects (which are thrown in the
    // HTTP helper code when we get a 400 BadRequest response back)
    if (errorObj instanceof BadRequestError) {
      return;
    }

    const regexes = [/load failed/i, /failed to fetch/i, /networkerror/i];
    if (regexes.some((v) => v.test(messageToLog))) {
      return;
    }

    try {
      const editModel: System_LogError_EditModel = {
        source,
        message: messageToLog,
        referringUrl: document.referrer,
        otherInformation
      };

      // NOTE: We do not await the result of this call since we don't do anything with the result
      fetch('./api/system/log-error', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(editModel)
      });
    } catch {
      // If things fail here, there isn't much we can do
    }
  }
}
