import { CommonApiBadRequestErrors, CommonErrorMessages } from '../../constants';

export default class ErrorsHelper {
  public static getErrorMessageFromErrorObject(errorObject: string | Error | unknown): string {
    let errorObjectAsString = '';

    if (typeof errorObject === 'string') {
      errorObjectAsString = (errorObject || '').trim();
    } else if (errorObject instanceof Error) {
      errorObjectAsString = errorObject.message;
    }

    if (!errorObjectAsString) {
      return CommonErrorMessages.General;
    }

    switch (errorObjectAsString) {
      case 'Failed to fetch':
        return CommonErrorMessages.General;

      case CommonApiBadRequestErrors.NotLoggedIn:
        return CommonErrorMessages.NotLoggedIn;

      default:
        return errorObjectAsString;
    }
  }
}
