import { useEffect, useRef, useState } from 'react';
import NectarineTextInput from '../NectarineTextInput/NectarineTextInput';
import { useNotificationsPanelContext } from '../Notifications/NotificationsPanel/NotificationsPanelContext';
import './NectarineAutocompleteInput.scss';

interface NectarineAutocompleteInputProps<T> extends NectarineTextInputProps {
  suggestions?: T[] | string[];
  suggestionsApiSearchFn?: (inputId: string) => Promise<T[]>;
  suggestionDisplayNameProperty?: keyof T;
  suggestionValueProperty?: keyof T;
  // When the user selects a suggestion, the callback includes the selected 'value' for the item, as well
  // as the underlying object itself
  onSelect?: (value: string, selectedObject: T) => void;
  selectedEntityIconClass?: string;
}

const NectarineAutocompleteInput = <T,>(props: NectarineAutocompleteInputProps<T>) => {
  const [suggestions, setSuggestions] = useState<T[] | string[]>(props.suggestions ?? []);

  const { showErrorNotification } = useNotificationsPanelContext();

  if (
    suggestions &&
    suggestions.length &&
    typeof suggestions[0] !== 'string' &&
    (props.suggestionDisplayNameProperty === undefined || props.suggestionValueProperty === undefined)
  ) {
    throw new Error(
      `The suggestionDisplayNameProperty and suggestionValueProperty properties must be provided when the suggestions are not strings.`
    );
  }

  if (props.suggestionsApiSearchFn && (props.suggestionDisplayNameProperty === undefined || props.suggestionValueProperty === undefined)) {
    throw new Error(
      `The suggestionDisplayNameProperty and suggestionValueProperty properties must be provided when the suggestionsApiSearchFn is provided.`
    );
  }

  if (!props.suggestionsApiSearchFn && suggestions === undefined) {
    throw new Error(`The suggestions property is required when the suggestionsApiSearchFn is not provided.`);
  }

  const [filteredSuggestions, setFilteredSuggestions] = useState<T[] | string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const suggestionRefs = useRef<HTMLLIElement[]>([]);
  const focusedSuggestionRef = useRef<HTMLLIElement | null>(null);

  const suggestionsAreaCloseTimeout = useRef<NodeJS.Timeout | null>(null);

  // If we have an initial value, run an initial search to get the matching suggestions
  useEffect(() => {
    const loadData = async () => {
      if ((props.initialValue?.length ?? 0) > 0 && props.suggestionsApiSearchFn) {
        try {
          const result = await props.suggestionsApiSearchFn(props.initialValue!);
          setSuggestions(result);
          setFilteredSuggestions(result);

          const selectedItem = result.find((suggestion) => {
            if (typeof suggestion === 'string') {
              return suggestion.toLowerCase().includes(props.initialValue!.toLowerCase());
            } else {
              return (suggestion[props.suggestionValueProperty!] as string)
                .toString()
                .toLowerCase()
                .includes(props.initialValue!.toLowerCase());
            }
          });

          if (selectedItem) {
            if (typeof selectedItem === 'string') {
              props.onSelect?.(selectedItem, selectedItem);
            } else {
              props.onSelect?.(selectedItem[props.suggestionValueProperty!] as string, selectedItem);
            }
          }
        } catch (error) {
          showErrorNotification('An error occurred while loading initial suggestions data.');
        }
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = async (newVal: string) => {
    let suggestionsArray = suggestions;

    // Reset the refs array before we set the filtered suggestions
    suggestionRefs.current = [];

    if (!newVal.length) {
      if (props.suggestionsApiSearchFn) {
        setFilteredSuggestions([]);
      } else {
        setFilteredSuggestions(suggestionsArray);
      }

      return;
    }

    // If we don't have a list of suggestions but instead rely on an API call,
    // populate the suggestions now, before filtering them below
    if (props.suggestionsApiSearchFn) {
      try {
        const results = await props.suggestionsApiSearchFn!(newVal);

        setSuggestions(results);
        setFilteredSuggestions(results);
        suggestionsArray = results;
      } catch (error) {
        showErrorNotification('An error occurred while loading suggestions data.');
      }
    }

    if (newVal.length > 0) {
      if (suggestionsArray.length) {
        const filtered = suggestionsArray.filter((suggestion) => {
          if (typeof suggestion === 'string') {
            return suggestion.toLowerCase().includes(newVal.toLowerCase());
          } else {
            return (
              (suggestion[props.suggestionDisplayNameProperty!] as string).toLowerCase().includes(newVal.toLowerCase()) ||
              (suggestion[props.suggestionValueProperty!] as string).toString().toLowerCase().includes(newVal.toLowerCase())
            );
          }
        });

        setFilteredSuggestions(filtered as T[] | string[]);
      }

      setShowSuggestions(true);
    } else {
      if (suggestionsArray.length) {
        setFilteredSuggestions(suggestionsArray);
      } else {
        setFilteredSuggestions([]);
      }
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion: T | string) => {
    if (typeof suggestion === 'string') {
      inputRef.current!.value = suggestion;

      props.onChange?.(suggestion);
      props.onSelect?.(suggestion, suggestion as T);
    } else {
      inputRef.current!.value = suggestion[props.suggestionValueProperty!] as string;

      props.onChange?.(suggestion[props.suggestionValueProperty!] as string);
      props.onSelect?.(suggestion[props.suggestionValueProperty!] as string, suggestion);
    }

    setShowSuggestions(false);
  };

  const getSelectedSuggestion = (): string | undefined => {
    const currVal = (inputRef.current?.value ?? '').trim().toLowerCase();

    if (!currVal) {
      return undefined;
    }

    if ((suggestions?.length ?? 0) === 0) {
      return undefined;
    }

    let matchingItem: string | null = '';

    if (suggestions && typeof suggestions?.[0] === 'string') {
      matchingItem = (suggestions as string[]).find((suggestion) => suggestion.toLowerCase() === currVal.toLowerCase()) ?? null;
    } else {
      matchingItem =
        ((suggestions as T[]).find(
          (suggestion) => (suggestion[props.suggestionValueProperty!] as string).toString().toLowerCase() === currVal
        )?.[props.suggestionDisplayNameProperty!] as string) ?? null;
    }

    return matchingItem ? `Matching: <i>${matchingItem}</i>` : undefined;
  };

  return (
    <div className="nectarineAutocomplete">
      <div className="autocomplete-wrapper">
        <NectarineTextInput
          ref={inputRef}
          {...props}
          secondaryLabel={getSelectedSuggestion() ?? '(No matching suggestion)'}
          secondaryLabelAlignment="end"
          secondaryLabelIconCssClass={(getSelectedSuggestion() ? 'c-blue ' : 'c-gray ') + props.selectedEntityIconClass}
          initialValue={props.initialValue}
          debounceTimeout={250}
          onChange={(newVal) => {
            newVal = (newVal ?? '').trim();

            handleInputChange(newVal!);

            if (!(newVal || '').trim().length) {
              setShowSuggestions(true);
            }
          }}
          onKeyDown={(e) => {
            switch (e.key) {
              case 'Tab':
              case 'ArrowDown':
              case 'ArrowUp':
                if (suggestionRefs.current && suggestionRefs.current.length) {
                  if (e.key === 'ArrowDown' || e.key === 'Tab') {
                    (suggestionRefs.current[0] as any as HTMLInputElement).focus();
                  } else if (e.key === 'ArrowUp') {
                    (suggestionRefs.current[suggestionRefs.current.length - 1] as any as HTMLInputElement).focus();
                  }
                }

                // Wait for the blur event to queue up the timeout, then dispose of it
                setTimeout(() => {
                  if (suggestionsAreaCloseTimeout.current) {
                    clearTimeout(suggestionsAreaCloseTimeout.current);
                  }
                }, 50);

                e.preventDefault();
                e.stopPropagation();
                break;
            }
          }}
          onFocus={() => {
            setShowSuggestions(true);
          }}
          onBlur={() => {
            if (suggestionsAreaCloseTimeout.current) {
              clearTimeout(suggestionsAreaCloseTimeout.current);
            }

            // Delay to avoid closing immediately after selection
            suggestionsAreaCloseTimeout.current = setTimeout(() => setShowSuggestions(false), 200);
          }}
        />

        {showSuggestions && filteredSuggestions.length > 0 ? (
          <ul className="autocomplete-suggestions">
            {filteredSuggestions.map((suggestion, index) => (
              <li
                ref={(e) => {
                  if (e) {
                    suggestionRefs.current.push(e!);
                  }
                }}
                key={index}
                tabIndex={0}
                onClick={() => handleSuggestionClick(suggestion)}
                title={typeof suggestion === 'string' ? undefined : (suggestion[props.suggestionValueProperty!] as string)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    e.stopPropagation();

                    handleSuggestionClick(suggestion);
                    return;
                  } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                    e.preventDefault();
                    e.stopPropagation();

                    const indexOfElement = suggestionRefs.current.indexOf(e.target as any);
                    if (indexOfElement > -1) {
                      if (e.key === 'ArrowDown') {
                        if (suggestionRefs.current[indexOfElement + 1]) {
                          suggestionRefs.current[indexOfElement + 1].focus();
                        } else {
                          suggestionRefs.current[0].focus();
                        }
                      } else if (e.key === 'ArrowUp') {
                        if (suggestionRefs.current[indexOfElement - 1]) {
                          suggestionRefs.current[indexOfElement - 1].focus();
                        } else {
                          suggestionRefs.current[suggestionRefs.current.length - 1].focus();
                        }
                      }
                    }

                    return;
                  }
                }}
                onFocus={(e) => {
                  focusedSuggestionRef.current = e.target;
                }}
                onBlur={(e) => {
                  focusedSuggestionRef.current = null;
                }}
              >
                {typeof suggestion === 'string' ? suggestion : (suggestion[props.suggestionDisplayNameProperty!] as string)}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default NectarineAutocompleteInput;
