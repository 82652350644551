import { createContext, ReactNode, useCallback, useContext, useRef } from 'react';
import { Observable } from 'rxjs';
import { useObservableWithBehaviorSubject } from '../../../hooks/UseObservable';

interface HeaderCellData {
  id: string;
}

interface HeaderCellClickData extends HeaderCellData {
  sortFn?: (dataObj: any) => unknown;
}

interface TableContextType {
  onHeaderCellClick: (rowData: HeaderCellClickData) => void;
  sortInformationChanged$: Observable<SortInformation>;
  onSortInformationChanged: () => void;
}

// A context for use with an instance of a NectarineTable
const NectarineTableContext = createContext<TableContextType | undefined>(undefined);

const NectarineTableContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sortInformationChanged$, setSortInformationChanged, getSortInformation] = useObservableWithBehaviorSubject<SortInformation>({
    columnId: null,
    sortDirection: 'asc'
  });

  const sortInformation = useRef<SortInformation>({
    sortDirection: 'asc',
    columnId: null
  });

  const onHeaderCellClick = useCallback(
    (rowData: HeaderCellClickData): void => {
      const currentSortInfo = getSortInformation();

      let newSortDirection: TableCellSortDirections = 'asc';

      if (currentSortInfo?.columnId === rowData.id) {
        newSortDirection = currentSortInfo.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        newSortDirection = 'asc';
      }

      sortInformation.current = {
        columnId: rowData.id,
        sortDirection: newSortDirection,
        sortFn: rowData.sortFn
      };

      setSortInformationChanged(sortInformation.current);
    },
    [getSortInformation, setSortInformationChanged]
  );

  const onSortInformationChanged = useCallback(() => {
    setSortInformationChanged(sortInformation.current);
  }, [setSortInformationChanged]);

  return (
    <NectarineTableContext.Provider
      value={{
        sortInformationChanged$,
        onSortInformationChanged,
        onHeaderCellClick
      }}
    >
      {children}
    </NectarineTableContext.Provider>
  );
};

const useNectarineTableContext = () => {
  const context = useContext(NectarineTableContext);

  if (!context) {
    throw new Error('useNectarineTableContext() must be used within a NectarineTableContext. Please verify your DOM structure.');
  }
  return context;
};

export { NectarineTableContextProvider, useNectarineTableContext };
