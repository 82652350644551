import { useRef, useState } from 'react';
import { Alert, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import NectarineButton from '../../../../ui/NectarineButton/NectarineButton';
import { useNotificationsPanelContext } from '../../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';

interface AdvisorEditProfilePhotoProps {
  show: boolean;
  toggle: () => void;
  save: () => void;
  advisor: Advisor_BasicDetails_ViewModel;
}

const AdvisorEditProfilePhoto: React.FC<AdvisorEditProfilePhotoProps> = (props: AdvisorEditProfilePhotoProps) => {
  const [savePhotoLoading, setSavePhotoLoading] = useState(false);
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { showSuccessNotification, showInfoNotification } = useNotificationsPanelContext();

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files![0]);
    hideError();
  };

  //Post the selected file to the API at /api/advisor/photo
  const onSave = async () => {
    if (selectedFile) {
      hideError();

      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        setSavePhotoLoading(true);

        await AdvisorsApiService.uploadProfilePhoto(formData);

        showSuccessNotification('Your profile photo was uploaded successfully.');

        props.save();
      } catch (error) {
        showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
      } finally {
        setSavePhotoLoading(false);
      }
    }
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <form noValidate ref={formRef}>
        <ModalHeader toggle={props.toggle}>Upload New Profile Pic</ModalHeader>

        <ModalBody>
          {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}
          <p>
            Please make sure you are looking directly at the camera, and your face and part of your shoulders are visible.
            <br />
            There is a maximum photo size of <strong>1MB</strong>.
          </p>

          <Input type="file" onChange={handleFileSelect} />
        </ModalBody>
        <ModalFooter>
          <NectarineButton
            type="blue"
            text="Save Profile Pic"
            isDisabled={savePhotoLoading || !selectedFile}
            onDisabledClickFn={() => {
              showInfoNotification('Please select a photo to upload.');
            }}
            showLoadingDots={savePhotoLoading}
            onClick={async () => {
              await onSave();
            }}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AdvisorEditProfilePhoto;
