import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import { AdminEventsTable } from '../AdminEventsTable/AdminEventsTable';

const adminLinkColumnWidth_Xs = 6;
const adminLinkColumnWidth_Lg = 3;

// NOTE: These admin link routes must have a forward slash in front of them
const adminLinks = [
  {
    name: 'Users',
    iconClasses: 'fa-duotone fa-user admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.Users_Full}`
  },
  {
    name: 'Roles',
    iconClasses: 'fa-duotone fa-hat-cowboy admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.RolesRoutes.Root_Full}`
  },
  {
    name: 'Advisor Attributes',
    iconClasses: 'fa-duotone fa-sliders admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.AdvisorAttributes_Full}`
  },
  {
    name: 'Nectarine Compliance Docs',
    iconClasses: 'fa-sharp fa-regular fa-file-invoice admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.Documents_Full}`
  },
  {
    name: 'User Agreements',
    iconClasses: 'fa-duotone fa-handshake admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.UserAgreements_Full}`
  },
  {
    name: 'Reviews',
    iconClasses: 'fa-solid fa-star admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.Reviews_Full}`
  },
  {
    name: 'Events',
    iconClasses: 'fa-duotone fa-calendar-days admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.Events_Full}`
  },
  {
    name: 'Recordings',
    iconClasses: 'fa-duotone fa-film admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.Recordings_Full}`
  },
  {
    name: 'Orphan Intake Forms',
    iconClasses: 'fa-duotone fa-solid fa-person-to-door admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.IntakeForms_Full}`
  },
  {
    name: 'Advisor Payments',
    iconClasses: 'fa-duotone fa-money-bill-transfer admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.AdvisorPayments_Full}`
  },
  {
    name: 'Affiliate Payments',
    iconClasses: 'fa-regular fa-money-bill-transfer admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.AffiliatePayments_Full}`
  },
  {
    name: 'Admin Reports',
    iconClasses: 'fa-duotone fa-table admin-menu-icon',
    route: `/${ApplicationRoutes.AdminRoutes.Reports_Full}`
  },
  {
    name: 'The Plan Engagements',
    iconClasses: 'fa-duotone fa-clipboard-list-check',
    route: `/${ApplicationRoutes.AdminRoutes.ThePlan_Full}`
  },
  {
    name: 'Feature Flags',
    iconClasses: 'fa-duotone fa-solid fa-flag-checkered',
    route: `/${ApplicationRoutes.AdminRoutes.FeatureFlags_Full}`
  }
];

export const AdminIndex: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<Admin_Dashboard_ViewModel | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getDashboardData();
      setDashboardData(result);
    };
    loadData();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Admin - {AppSettings.ApplicationName}</title>
      </Helmet>

      {dashboardData && dashboardData.unlinkedEvents && dashboardData.unlinkedEvents.length > 0 ? (
        <Row>
          <Col className="warningMessage mb-2">
            <div>
              <h1>Action Needed!</h1>
              <div>
                There are {dashboardData.unlinkedEvents.length} events that need to be linked to a user. Please review and link them to the
                appropriate user.
              </div>
            </div>

            <AdminEventsTable events={dashboardData.unlinkedEvents} onDelete={() => {}} />
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {dashboardData && (dashboardData?.unlinkedThePlanClientAccounts?.length ?? 0) > 0 ? (
        <Row>
          <Col className="warningMessage">
            <h3>No Action Needed</h3>
            <div>
              There&nbsp;
              {dashboardData.unlinkedThePlanClientAccounts.length === 1
                ? 'is 1'
                : 'are ' + dashboardData.unlinkedThePlanClientAccounts.length}
              &nbsp;"The Plan" implementation(s) without a linked client.
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <h1>Admin Links</h1>
      <Row>
        {adminLinks.map((currLink) => {
          return (
            <Col lg={adminLinkColumnWidth_Lg} xs={adminLinkColumnWidth_Xs} key={currLink.name} className="mb-3">
              <Link to={currLink.route}>
                <div className="admin-link-button">
                  <i className={currLink.iconClasses}></i>

                  <div className="mt-1">{currLink.name}</div>
                </div>
              </Link>
            </Col>
          );
        })}
      </Row>

      {/* <h1>State Coverage</h1>

      {!dashboardData && <LoadingSpinner message="Loading..." />}
      {dashboardData && dashboardData.stateCoverage && (
        <table className="table">
          <thead>
            <tr>
              <th>State</th>
              <th>Coverage</th>
            </tr>
          </thead>
          <tbody>
            {dashboardData.stateCoverage.map((state) => (
              <tr key={state.stateAbbreviation}>
                <td>{state.stateAbbreviation}</td>
                <td>{state.userCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )} */}
    </Container>
  );
};
