import { orderBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import NectarineCheckboxInput from '../NectarineCheckboxInput/NectarineCheckboxInput';
import NectarineTextInput from '../NectarineTextInput/NectarineTextInput';
import { NectarineTableContextProvider, useNectarineTableContext } from './NectarineTable.Context';
import './NectarineTable.scss';
import NectarineTableDataColumn from './NectarineTableDataColumn';
import { NectarineTableHeaderColumn } from './NectarineTableHeaderColumn';

interface NectarineTableProps<T> {
  data: T[];
  rowDataTemplate: (inputData: T, index: number) => JSX.Element;
  children: JSX.Element | JSX.Element[];
  searchFn?: (item: T, searchText: string) => unknown;
  rowSelectionMode?: 'single' | 'multi';
  onRowSelection?: (selectedItems: T[]) => void;
  searchPlaceholderText?: string;
  showBorders?: boolean;
  maxHeight?: string;
}

// NOTE: We have to use a wrapper component so we can use the context for all inner components,
// including the NectarineInnerTable
const NectarineTable = <T,>(props: NectarineTableProps<T>) => {
  if (props.rowSelectionMode && !props.onRowSelection) {
    throw new Error('The onRowSelection callback is required when the row selection mode is set for the NectarineTable.');
  }

  return (
    <NectarineTableContextProvider>
      <NectarineInnerTable {...props}></NectarineInnerTable>
    </NectarineTableContextProvider>
  );
};

const sortTableData = <T,>(
  inputData: T[],
  sortInfo: SortInformation | null,
  searchText: string,
  searchFn?: (item: T, searchText: string) => unknown
): T[] => {
  let returnValue = [...inputData];
  searchText = (searchText ?? '').trim();

  if (searchFn && searchText) {
    returnValue = returnValue.filter((v) => searchFn(v, searchText));
  }

  if (sortInfo?.sortFn) {
    returnValue = orderBy(returnValue, [sortInfo.sortFn], [sortInfo.sortDirection]) as T[];
  }

  return returnValue;
};

// NOTE: We have to use a comma after the generic parameter
// https://www.freecodecamp.org/news/typescript-generics-with-functional-react-components/
const NectarineInnerTable = <T,>(props: NectarineTableProps<T>) => {
  // const [headerRowCellsElements, setHeaderRowCellsElements] = useState<JSX.Element | JSX.Element[] | null>(null);

  const { sortInformationChanged$, onSortInformationChanged } = useNectarineTableContext();

  const [dataToDisplay, setDataToDisplay] = useState<T[]>([]);

  const [sortInformation, setSortInformation] = useState<SortInformation | null>(null);

  // NOTE: We use a ref here, since any changes to the search text will trigger the displayed items
  // to recalculate and cause a re-render.
  // If this is a state variable, it will cause extra unnecessary re-renders.
  const searchText = useRef<string>('');

  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  useEffect(() => {
    // When the data changes, trigger the observable, which will trigger code
    // below to sort and filter as appropriate.
    // We only want this code to fire when props.data changes, so using an observable
    // prevents having to list a ton of things in this useEffect dependency array and
    // needing extra code to know when the one we care about actually changed.
    onSortInformationChanged();
  }, [onSortInformationChanged, props.data]);

  useEffect(() => {
    const allSubscriptions = new Subscription();

    allSubscriptions.add(
      sortInformationChanged$.subscribe((sortInfo) => {
        setSortInformation(sortInfo);

        setDataToDisplay(sortTableData(props.data, sortInfo, searchText.current, props.searchFn));
      })
    );

    return () => {
      allSubscriptions.unsubscribe();
    };
  }, [props.data, props.searchFn, searchText, sortInformationChanged$]);

  const onRowSelect = useCallback(
    (item: T): void => {
      if (props.rowSelectionMode === 'single') {
        setSelectedItems((currValue) => {
          if (currValue.includes(item)) {
            return [];
          }

          return [item];
        });
      } else {
        setSelectedItems((currValue) => {
          if (currValue.includes(item)) {
            return currValue.filter((v) => v !== item);
          }

          return [...currValue, item];
        });
      }
    },
    [props.rowSelectionMode]
  );

  return (
    // NOTE: Each instance of this component has its own context, to silo table instances
    <>
      {props.searchFn && props.data.length ? (
        <div className="my-2 d-flex gap-3 align-items-end">
          <div className="w-50">
            {/* NOTE: We treat this as an uncontrolled component */}
            <NectarineTextInput
              placeholder={props.searchPlaceholderText ?? 'Search'}
              debounceTimeout={250}
              onChange={(newVal) => {
                searchText.current = newVal!;

                setDataToDisplay(sortTableData(props.data, sortInformation, newVal ?? '', props.searchFn));
              }}
              iconClassString="fa-solid fa-search"
            />
          </div>

          {dataToDisplay.length !== props.data.length ? (
            <div className="w-25">
              Showing {dataToDisplay.length} filtered row{dataToDisplay.length === 1 ? '' : 's'}
            </div>
          ) : (
            <></>
          )}

          <div className={`w-25 ${!selectedItems.length ? 'opacity-0' : ''}`}>
            {selectedItems.length} Selected Item{selectedItems.length === 1 ? '' : 's'}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="nectarineInnerTable" style={{ maxHeight: props.maxHeight ?? '60vh', overflow: 'auto' }}>
        {dataToDisplay.length ? (
          <table className={`nectarineTable table table-striped table-hover ${props.showBorders ? 'table-bordered' : ''}`}>
            <thead className="headerRow">
              <tr>
                {/* Checkbox column */}
                {props.rowSelectionMode ? <NectarineTableHeaderColumn<T>>&nbsp;</NectarineTableHeaderColumn> : <></>}

                {props.children}
              </tr>
            </thead>
            <tbody>
              {dataToDisplay.map((item, index) => (
                <tr key={index}>
                  {props.rowSelectionMode ? (
                    <NectarineTableDataColumn>
                      <NectarineCheckboxInput
                        labelDisplayType="inline"
                        checked={selectedItems.includes(item)}
                        onChange={() => {
                          onRowSelect(item);
                        }}
                      />
                    </NectarineTableDataColumn>
                  ) : (
                    <></>
                  )}

                  <>{props.rowDataTemplate(item, index)}</>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="mt-4 text-center c-gray">
            {(searchText.current ?? '').trim().length ? (
              <>
                <div>No search results were found.</div>

                <div>
                  Try{' '}
                  <span
                    className="underline pointer"
                    onClick={() => {
                      searchText.current = '';

                      setDataToDisplay(sortTableData(props.data, sortInformation, '', props.searchFn));
                    }}
                  >
                    clearing your search text
                  </span>
                  .
                </div>
              </>
            ) : props.data.length ? (
              <div>No data was found.</div>
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export { NectarineTable };
