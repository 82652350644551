import { useEffect, useRef, useState } from 'react';
import { Subscription } from 'rxjs';
import GeneralHelper from '../../Helpers/GeneralHelper';
import { useNectarineTableContext } from './NectarineTable.Context';
import './NectarineTable.scss';

// A wrapper component
const NectarineTableHeaderColumns = ({ children }: { children: JSX.Element | JSX.Element[] }) => <>{children}</>;

const NectarineTableHeaderColumn = <T,>(props: NectarineTableHeaderColumnProps<T>) => {
  const { sortInformationChanged$, onHeaderCellClick } = useNectarineTableContext();

  const [sortInformation, setSortInformation] = useState<SortInformation | null>(null);

  const id = useRef<string>(GeneralHelper.generateGuid());

  useEffect(() => {
    // Register this header column as the currently selected sort column
    if (props.isDefaultSortOption && props.sortFn) {
      onHeaderCellClick({
        id: id.current,
        sortFn: props.sortFn
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allSubscriptions = new Subscription();

    // When sort information changes, update things on this component so we can display
    // things like sorting arrows appropriately
    allSubscriptions.add(
      sortInformationChanged$.subscribe((sortInfo) => {
        setSortInformation(sortInfo);
      })
    );
  }, [sortInformationChanged$]);

  const getCssClasses = (): string => {
    const returnValue: string[] = [];

    switch (props.alignment) {
      case 'center':
        returnValue.push('justify-content-center');
        break;

      case 'right':
        returnValue.push('justify-content-end');
        break;

      default:
        break;
    }

    if (props.sortFn) {
      returnValue.push('pointer');
    }

    return returnValue.join(' ');
  };

  const isCurrentlySorted = id.current === sortInformation?.columnId;

  return (
    <th>
      <div
        className={`headerColumn no-user-select d-flex ${getCssClasses()}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          if (!props.sortFn) {
            return;
          }

          onHeaderCellClick({
            id: id.current,
            sortFn: props.sortFn
          });
        }}
      >
        {props.sortFn ? (
          <span className={`headerText d-flex ${isCurrentlySorted ? 'isCurrentlySorted fullWidth' : ''}`}>
            {props.children}
            {/* Add a space so the line height is consistent, in case the column header is an icon or some non-text value */}
            &nbsp;
            <span className={`ms-3 sortIconContainer ${isCurrentlySorted ? '' : 'opacity-0'}`}>
              <span className={`sortIcon rotate ${sortInformation?.sortDirection === 'desc' ? 'rotate-180' : ''}`}>
                <span className="font-10 fa-solid fa-chevron-up c-green"></span>
              </span>
            </span>
          </span>
        ) : (
          <>
            {props.children}
            {/* Add a space so the line height is consistent, in case the column header is an icon or some non-text value */}
            &nbsp;
          </>
        )}
      </div>
    </th>
  );
};

export { NectarineTableHeaderColumn, NectarineTableHeaderColumns };
