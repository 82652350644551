import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import AdminApiService from '../../../../services/api/AdminApiService';
import { useConfirmationOverlayContext } from '../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import { useLoadingOverlayContext } from '../../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineToggle from '../../../ui/NectarineToggle/NectarineToggle';
import { useNotificationsPanelContext } from '../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';
import './AdminFeatureFlags.scss';

const AdminFeatureFlags: React.FC = () => {
  const [featureFlags, setFeatureFlags] = useState<Admin_FeatureFlag_ViewModel[] | null>(null);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  const { showErrorNotification, showSuccessNotification } = useNotificationsPanelContext();

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getFeatureFlags();
      setFeatureFlags(result);
    };

    loadData();
  }, []);

  const toggleFeatureFlag = (featureFlag: Admin_FeatureFlag_ViewModel, newVal: boolean) => {
    showConfirmationOverlay({
      text: `Are you sure you want to turn the feature "${featureFlag.name}" ${newVal ? 'on' : 'off'} for everyone?`,
      yesAction: async () => {
        try {
          showLoadingOverlay();

          await AdminApiService.setFeatureFlags({
            id: featureFlag.id,
            isEnabled: !featureFlag.isEnabled
          });

          setFeatureFlags((v) => {
            const newArray = [...v!];
            const indexToUpdate = v!.indexOf(featureFlag);
            newArray[indexToUpdate].isEnabled = newVal;

            return newArray;
          });

          if (newVal) {
            showSuccessNotification(`The feature "${featureFlag.name}" has been turned on and is available to users.<br/>Godspeed.`);
          } else {
            showSuccessNotification(`The feature "${featureFlag.name}" has been turned off and is hidden from users.`);
          }
        } catch (error) {
          showErrorNotification('An error occurred while updating the feature flag.');
        } finally {
          hideLoadingOverlay();
        }
      }
    });
  };

  return (
    <Container>
      <Helmet>
        <title>Admin / Feature Flags</title>
      </Helmet>

      {!featureFlags ? <LoadingSpinner message="Loading..." /> : <></>}

      <div className="adminFeatureFlagsPage">
        {featureFlags ? (
          featureFlags.length ? (
            <>
              <h1>Feature Flags</h1>

              {featureFlags?.map((currItem) => {
                return (
                  <Row className="mb-3 featureFlagListing" key={currItem.id}>
                    <Col sm="3" className="d-flex align-items-center">
                      <div>{currItem.name}</div>
                    </Col>

                    <Col>
                      <div className="d-flex align-items-center">
                        <div className="ms-5">
                          <NectarineToggle
                            value={currItem.isEnabled}
                            onChange={(newVal) => {
                              toggleFeatureFlag(currItem, newVal);
                            }}
                          />
                        </div>
                        {currItem.isEnabled ? (
                          <div className="isVisibleText ms-4">Visible</div>
                        ) : (
                          <div className="notVisibleText ms-4">Not Visible</div>
                        )}{' '}
                      </div>
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
};

export default AdminFeatureFlags;
