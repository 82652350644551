import { addDays, format } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { Col, Label, Modal, ModalBody, Row, UncontrolledTooltip } from 'reactstrap';
import { AppSettings, ValueLimits } from '../../../../../constants';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import GeneralHelper from '../../../../Helpers/GeneralHelper';
import ValidationHelper from '../../../../Helpers/ValidationHelper';
import { useConfirmationOverlayContext } from '../../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import { useLoadingOverlayContext } from '../../../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import ModalWizard from '../../../../ui/ModalWizard/ModalWizard';
import NectarineNumericInput from '../../../../ui/NectarineNumericInput/NectarineNumericInput';
import NectarineSelectInput from '../../../../ui/NectarineSelectInput/NectarineSelectInput';
import NectarineTextAreaInput from '../../../../ui/NectarineTextAreaInput/NectarineTextAreaInput';
import NectarineTextInput from '../../../../ui/NectarineTextInput/NectarineTextInput';
import { useNotificationsPanelContext } from '../../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';
import './AdvisorInviteClientToThePlan.scss';

interface AdvisorInviteClientToThePlanProps {
  advisorDetails: Advisor_BasicDetails_ViewModel;
  users: Advisor_ClientResult_ViewModel[];
  loggedInUser: User_BasicDetails_ViewModel;
  onModalClose: (success: boolean) => void;
}

const value_ClientWithoutNectarineAccount = '-1';

const thirtyDaysFromNow = addDays(new Date(), 30);

const AdvisorInviteClientToThePlan: React.FC<AdvisorInviteClientToThePlanProps> = (props: AdvisorInviteClientToThePlanProps) => {
  const invitationFormRef = useRef<HTMLFormElement | null>(null);

  const [calendlyLink, setCalendlyLink] = useState<string>('');
  const [calendlyLinkIsValid, setCalendlyLinkIsValid] = useState<boolean | null>(null);

  const [inviteClientToThePlanData, setInviteClientToThePlanData] = useState<Advisors_InitiateThePlan_EditModel>({
    clientId: '',
    firstName: '',
    lastName: '',
    email: '',
    fromEmail: '',
    bccEmail: '',
    extraInformation: '',
    price: 0,
    deduction: 0
  });

  const [showThePlanWelcomePDF, setShowThePlanWelcomePDF] = useState<boolean>(false);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();
  const { showSuccessNotification, showErrorNotification } = useNotificationsPanelContext();

  useEffect(() => {
    setInviteClientToThePlanData((v) => ({
      ...v,
      fromEmail: props.loggedInUser.email,
      bccEmail: props.loggedInUser.email,
      price: props.advisorDetails.thePlanRate ?? 0
    }));

    const checkCalendlyLink = async () => {
      const newCalendlyLink = props.advisorDetails.calendarLink.replace('/meet', '/the-plan');
      setCalendlyLink(newCalendlyLink);

      try {
        // NOTE: We have to let the server check if the calendly link is good, since we will
        // run into CORS issues if we run an HTTP request to Calendly here
        const result = await AdvisorsApiService.checkThePlanCalendlyLink();
        setCalendlyLinkIsValid(result);
      } catch (error) {
        setCalendlyLinkIsValid(false);
      }
    };

    checkCalendlyLink();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isThePlanInvitationFormValid(): boolean {
    let returnValue = true;

    if (!inviteClientToThePlanData.clientId) {
      returnValue = false;
    }

    if ([inviteClientToThePlanData.firstName, inviteClientToThePlanData.lastName].some((v) => !((v || '').trim().length >= 2))) {
      returnValue = false;
    }

    if (
      [inviteClientToThePlanData.email, inviteClientToThePlanData.fromEmail, inviteClientToThePlanData.bccEmail].some(
        (v) => !ValidationHelper.validateEmail(v)
      )
    ) {
      returnValue = false;
    }

    if (!((inviteClientToThePlanData.price ?? 0) > 0)) {
      returnValue = false;
    }

    if ((inviteClientToThePlanData.deduction ?? 0) < 0) {
      returnValue = false;
    }

    if (!props.advisorDetails.rightCapitalLink || !props.advisorDetails.rightCapitalLogin) {
      returnValue = false;
    }

    if (!calendlyLinkIsValid) {
      returnValue = false;
    }

    return returnValue;
  }

  function getSelectedClientName(type: 'first-name' | 'full-name'): string {
    return type === 'first-name'
      ? (inviteClientToThePlanData.firstName ?? '')
      : `${inviteClientToThePlanData.firstName} ${inviteClientToThePlanData.lastName}`;
  }

  function onInviteClientToThePlanFormChange(newVal: string | number | null, propertyName: keyof Advisors_InitiateThePlan_EditModel) {
    if (typeof newVal === 'string') {
      newVal = (newVal || '').trimStart();
    }

    setInviteClientToThePlanData((v) => {
      const newObj = {
        ...v,
        [propertyName]: newVal
      };

      if (propertyName === 'clientId') {
        const matchingClient = props.users?.find((v) => v.id === newVal);
        if (matchingClient) {
          newObj.email = matchingClient.email;
          newObj.firstName = matchingClient.firstName;
          newObj.lastName = matchingClient.lastName;
        } else {
          newObj.email = '';
          newObj.firstName = '';
          newObj.lastName = '';
        }
      }

      return newObj;
    });
  }

  return (
    <ModalWizard
      onModalClose={() => {
        props.onModalClose(false);
      }}
      pages={[
        {
          id: 'page1',
          title: <span className="bold">Invite a client to start The Plan</span>,
          canContinue: isThePlanInvitationFormValid,
          nextPageButtonText: 'Preview welcome email',
          showCancelButton: true,
          onNextButtonClick: () => {
            invitationFormRef.current!.checkValidity();
            invitationFormRef.current!.reportValidity();
          },
          content: (
            <form id="invitationForm" ref={invitationFormRef} noValidate>
              <Row>
                <Col sm="12" md="6" className="mb-3">
                  <NectarineSelectInput
                    display="inline-block"
                    label="Choose a Client"
                    onChange={(e) => {
                      onInviteClientToThePlanFormChange(e, 'clientId');
                    }}
                    value={inviteClientToThePlanData.clientId}
                    items={[
                      {
                        value: '',
                        name: 'Choose a client...'
                      },
                      {
                        value: value_ClientWithoutNectarineAccount,
                        name: '(Client without a Nectarine account)'
                      },
                      ...props.users.map((v) => ({
                        name: `${v.firstName} ${v.lastName}`,
                        value: v.id
                      }))
                    ]}
                  />
                </Col>

                <Col sm="12" md="6" className="mb-3">
                  <NectarineTextInput
                    type="email"
                    label="Email"
                    required
                    placeholder="The client's email"
                    value={inviteClientToThePlanData.email}
                    maxLength={ValueLimits.Email_MaxLength}
                    isDisabled={inviteClientToThePlanData.clientId !== value_ClientWithoutNectarineAccount}
                    onChange={(e) => {
                      onInviteClientToThePlanFormChange(e, 'email');
                    }}
                  />
                </Col>

                {inviteClientToThePlanData.clientId === value_ClientWithoutNectarineAccount ? (
                  <>
                    <Col sm="12" md="6" className="mb-3">
                      <NectarineTextInput
                        label="First Name"
                        required
                        placeholder="The client's first name"
                        value={inviteClientToThePlanData.firstName}
                        maxLength={ValueLimits.Name_MaxLength}
                        isDisabled={inviteClientToThePlanData.clientId !== value_ClientWithoutNectarineAccount}
                        onChange={(e) => {
                          onInviteClientToThePlanFormChange(e, 'firstName');
                        }}
                      />
                    </Col>

                    <Col sm="12" md="6" className="mb-3">
                      <NectarineTextInput
                        label="Last Name"
                        required
                        placeholder="The client's last name"
                        value={inviteClientToThePlanData.lastName}
                        maxLength={ValueLimits.Name_MaxLength}
                        isDisabled={inviteClientToThePlanData.clientId !== value_ClientWithoutNectarineAccount}
                        onChange={(e) => {
                          onInviteClientToThePlanFormChange(e, 'lastName');
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}

                <Col sm="12" className="mb-3">
                  <NectarineTextAreaInput
                    label="Extra Information"
                    placeholder={
                      'e.g. It was great talking with you today, ' +
                      (getSelectedClientName('first-name') || 'Alexandra') +
                      `! I'd love to get started on a plan with you...`
                    }
                    value={inviteClientToThePlanData.extraInformation}
                    maxLength={ValueLimits.ThePlan_ExtraInformation_MaxLength}
                    onChange={(e) => {
                      onInviteClientToThePlanFormChange(e, 'extraInformation');
                    }}
                  />
                </Col>

                <Col sm="12" className="mb-3">
                  <NectarineNumericInput
                    label="Total Price"
                    secondaryLabel=" (before any deductions)"
                    value={inviteClientToThePlanData.price}
                    onChange={(e) => {
                      onInviteClientToThePlanFormChange(e, 'price');
                    }}
                    required
                    min={ValueLimits.ThePlan_Price_MinValue}
                    max={ValueLimits.ThePlan_Price_MaxValue}
                  />
                </Col>

                <Col sm="12" className="mb-3">
                  <NectarineNumericInput
                    label="Deduction Price"
                    secondaryLabel=" (If they are getting refunded for The Hour)"
                    value={inviteClientToThePlanData.deduction}
                    onChange={(e) => {
                      onInviteClientToThePlanFormChange(e, 'deduction');
                    }}
                    required
                    min={ValueLimits.ThePlan_DeductionPrice_MinValue}
                    max={ValueLimits.ThePlan_DeductionPrice_MaxValue}
                  />
                </Col>
              </Row>

              {!props.advisorDetails.rightCapitalLink || !props.advisorDetails.rightCapitalLogin ? (
                <div className="c-red semibold mt-2">
                  Your RightCapital invitation link or login link is missing.
                  <br />
                  Please set them on your Profile page before continuing.
                </div>
              ) : (
                <></>
              )}

              {calendlyLinkIsValid === false ? (
                <div className="c-red semibold mt-3">
                  Your Calendly link for The Plan is not configured.
                  <br />
                  Please update that on Calendly before continuing.
                </div>
              ) : (
                <></>
              )}

              <div className="mt-3"></div>
            </form>
          )
        },
        {
          id: 'page2',
          title: <span className="bold">Invite a client to start The Plan</span>,
          content: (
            <div className="emailPreview">
              <div>
                <Label className="bold">To:</Label>
                <span className="ms-2 bold">{getSelectedClientName('full-name')} </span>
                <span className="ms-1">{`<${inviteClientToThePlanData.email}>`}</span>
              </div>

              <div>
                <Label className="bold">From:</Label>
                <span className="ms-2 bold">{props.loggedInUser?.fullName} </span>
                <span>{`<${inviteClientToThePlanData.fromEmail}>`}</span>
              </div>

              {inviteClientToThePlanData.bccEmail ? (
                <div>
                  <Label className="bold">BCC:</Label>
                  <span className="ms-2 bold">{props.loggedInUser?.fullName} </span>
                  <span>{`<${inviteClientToThePlanData.bccEmail}>`}</span>
                </div>
              ) : (
                <></>
              )}

              <div className="mt-3">
                <Label className="bold">Subject:</Label>
                <span className="ms-2">Nectarine Financial Plan - Welcome!</span>
              </div>

              <div className="mt-3">
                <Label className="bold">Body:</Label>

                <div className="emailPreviewBodyArea mt-2">
                  Hi {getSelectedClientName('first-name')},
                  {(inviteClientToThePlanData.extraInformation || '').trim().length ? (
                    <div className="mt-3">{(inviteClientToThePlanData.extraInformation || '').trim()}</div>
                  ) : (
                    <></>
                  )}
                  <div className="mt-3">
                    We are very excited to get you started on building your financial plan! Here are the next steps to move forward!
                  </div>
                  <div className="mt-5">
                    <div className="bold">Client portal</div>
                    <div>
                      When you have time, please log in and complete the initial six steps to enter your information. If you run into any
                      problems, please let us know. For the "Net Worth" section, please link your accounts where possible so we can have
                      visibility into the holdings.
                    </div>

                    <div className="mt-3 d-flex gap-3">
                      <a
                        id="rightCapitalRegisterLink"
                        className="btn btn-primary"
                        href={props.advisorDetails?.rightCapitalLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Create a RightCapital account"
                      >
                        Create RightCapital account
                      </a>

                      <UncontrolledTooltip target={'rightCapitalRegisterLink'}>
                        This button will take your client to the RightCapital invitation link you specified on your "My Profile" page.
                        <br />
                        Click here to go to it now.
                      </UncontrolledTooltip>

                      <a
                        id="rightCapitalLoginButton"
                        className="btn btn-secondary"
                        href={props.advisorDetails?.rightCapitalLogin}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Log in to an existing account"
                      >
                        Log in to existing account
                      </a>

                      <UncontrolledTooltip target={'rightCapitalLoginButton'}>
                        This button will take your client to the RightCapital login link you specified on your "My Profile" page.
                        <br />
                        Click here to go to it now.
                      </UncontrolledTooltip>
                    </div>

                    <div className="mt-5">
                      <div className="bold">Schedule your first meeting</div>
                      <div>
                        Your first meeting should take place about one week after your information is uploaded to the client portal, to give
                        me adequate time to review everything. The first meeting will be focused on ensuring I understand your full
                        financial situation, based on what you entered in the client portal.
                      </div>

                      <div className="mt-3 d-flex gap-3">
                        <a
                          id="calendlyLink"
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Book your first meeting"
                          href={calendlyLink}
                        >
                          Book first meeting
                        </a>

                        <UncontrolledTooltip target={'calendlyLink'}>
                          This button will take your client to your Calendly URL for The Plan, where they can schedule their first meeting.
                        </UncontrolledTooltip>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="bold">Payment</div>
                      <div>
                        Your payment of{' '}
                        {GeneralHelper.formatNumberAsCurrency(
                          (inviteClientToThePlanData.price ?? 0) - (inviteClientToThePlanData.deduction ?? 0)
                        )}
                        &nbsp;is due by&nbsp;
                        {format(thirtyDaysFromNow, 'MMMM d, yyyy')}. Click below to make the payment.
                      </div>

                      <div className="mt-3 d-flex gap-3">
                        <a
                          id="makePaymentButton"
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Make a payment"
                        >
                          Make payment
                        </a>
                        <UncontrolledTooltip target={'makePaymentButton'}>
                          This button will take your client to a Stripe invoice for the amount you set on this wizard.
                          <br />
                          Note that the invoice will be created when the email is sent.
                        </UncontrolledTooltip>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="bold">Contract</div>
                      <div>Please view and agree to the contract prior to your first meeting.</div>

                      <div className="mt-3 d-flex gap-3">
                        <a
                          id="viewContractButton"
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="View the contract"
                        >
                          View contract
                        </a>

                        <UncontrolledTooltip target={'viewContractButton'}>
                          This button will take the user to a page on the Nectarine website where they can agree to the terms of The Plan.
                          <br />
                          The contract they agree to will have the amount you set on this wizard pre-filled, and is not generated until the
                          email is sent.
                        </UncontrolledTooltip>
                      </div>
                    </div>

                    <div className="mt-5">
                      <div className="bold">Plan overview</div>
                      <div>
                        Below is a full overview of the financial planning process and what you can expect during your financial planning
                        engagement.
                      </div>

                      <div className="mt-3 d-flex gap-3">
                        <a
                          id="viewPlanOverviewButton"
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="View an overview of The Plan"
                          onClick={() => setShowThePlanWelcomePDF(true)}
                        >
                          View plan overview
                        </a>

                        <UncontrolledTooltip target={'viewPlanOverviewButton'}>
                          This button will pull up a PDF of the planning overview. Click here to view the PDF.
                        </UncontrolledTooltip>
                      </div>
                    </div>

                    <Modal
                      isOpen={showThePlanWelcomePDF}
                      toggle={() => {
                        setShowThePlanWelcomePDF(false);
                      }}
                      className="full-screen-modal"
                    >
                      <ModalBody>
                        <iframe src={AppSettings.ThePlanWelcomePDF} width="1000%" height={window.innerHeight * 0.9}></iframe>
                      </ModalBody>
                    </Modal>

                    <div className="mt-5">
                      If you have any questions, feel free to email me at {inviteClientToThePlanData.fromEmail}. I look forward to working
                      with you!
                    </div>

                    <div className="mt-5">
                      <img
                        src={props.advisorDetails?.profilePicUrl}
                        className="circle-image headshot"
                        alt={`${props.advisorDetails?.firstName} ${props.advisorDetails?.lastName}, ${props.advisorDetails?.certifications}`}
                      />
                    </div>

                    <div className="mt-3">{props.advisorDetails?.fullName}</div>
                    <div>{inviteClientToThePlanData.fromEmail}</div>
                  </div>
                </div>
              </div>
            </div>
          ),
          customButtons: [
            {
              text: 'Send welcome email',
              onClick: () => {
                showConfirmationOverlay({
                  title: 'Confirm',
                  text: 'Are you ready to send the email to the client?',
                  noButtonText: 'Not yet',
                  yesButtonText: 'Send',
                  yesAction: async () => {
                    try {
                      showLoadingOverlay();

                      await AdvisorsApiService.initiateThePlan_Full({
                        ...inviteClientToThePlanData,
                        price: inviteClientToThePlanData.price || 0,
                        deduction: inviteClientToThePlanData.deduction || 0
                      });

                      showSuccessNotification(
                        `${inviteClientToThePlanData.firstName} ${inviteClientToThePlanData.lastName} was successfully invited to start The Plan.`
                      );

                      props.onModalClose(true);
                    } catch (error) {
                      showErrorNotification(
                        `An error occurred while getting things set up to invite the client to The Plan. ` +
                          `Please contact ${AppSettings.ApplicationName} support.`
                      );
                    } finally {
                      hideLoadingOverlay();
                    }
                  }
                });
              }
            }
          ]
        }
      ]}
    />
  );
};

export default AdvisorInviteClientToThePlan;
