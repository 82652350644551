import { forwardRef, ReactElement } from 'react';
import NectarineInfoIcon from '../NectarineInfoIcon/NectarineInfoIcon';
import './NectarineCheckboxInput.scss';

interface NectarineCheckboxInputProps {
  label?: string;
  isDisabled?: boolean;
  secondaryLabel?: string;
  labelDisplayType?: 'regular' | 'inline';
  checked: boolean;
  onChange: (newVal: boolean) => void;
  infoIconTooltipText?: string;
}

const NectarineCheckboxInput = forwardRef<HTMLInputElement, NectarineCheckboxInputProps>((props: NectarineCheckboxInputProps, ref) => {
  const labelDisplayType = props.labelDisplayType ?? 'regular';

  const getLabelJsx = (): ReactElement => {
    return props.label ? (
      <div
        className={`labelWrapper ${labelDisplayType === 'inline' ? 'ms-2' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (!props.isDisabled) {
            props.onChange(!props.checked);
          }
        }}
      >
        <label className="bold mb-1">{props.label}</label>

        {props.infoIconTooltipText ? <NectarineInfoIcon tooltipText={props.infoIconTooltipText} /> : <></>}

        {props.secondaryLabel ? <span className="ms-1">{props.secondaryLabel}</span> : <></>}
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className="nectarineCheckboxInput">
      {labelDisplayType === 'regular' ? <div>{getLabelJsx()}</div> : <></>}

      <input
        ref={ref}
        className="form-check-input"
        type="checkbox"
        checked={props.checked}
        onChange={(e) => {
          // NOTE: We just want to stop propagation, not prevent the default behavior,
          // or else the checkbox "checked" state doesn't work correctly
          e.stopPropagation();

          if (!props.isDisabled) {
            props.onChange(e.target.checked);
          }
        }}
      />

      {labelDisplayType === 'inline' ? <div>{getLabelJsx()}</div> : <></>}
    </div>
  );
});

NectarineCheckboxInput.displayName = 'NectarineCheckboxInput';

export default NectarineCheckboxInput;
