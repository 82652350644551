import { HTTPStatuses } from '../../constants';
import { BadRequestError } from '../../services/Utilities';
import AuthorizeService from '../api-authorization/AuthorizeService';

type HttpVerbs = 'GET' | 'DELETE' | 'PUT' | 'POST';

type ContentTypes = 'application/json' | 'multipart/form-data';

interface ResponseStatusCallbacks {
  [index: number]: (responseData?: unknown) => void;
}

interface ResponseStatusCallbacksWithPromises {
  [index: number]: (responseData?: unknown) => Promise<void>;
}

export default class HttpHelper {
  public static async get<T>(
    url: string,
    bodyContentType?: ContentTypes,
    responseStatusCallbacks?: ResponseStatusCallbacks,
    responseStatusCallbacksWithPromises?: ResponseStatusCallbacksWithPromises
  ): Promise<T> {
    return (await this.makeHttpCall<T>(
      url,
      'GET',
      undefined,
      bodyContentType,
      responseStatusCallbacks,
      responseStatusCallbacksWithPromises
    )) as T;
  }

  public static async put<T = void>(
    url: string,
    body?: unknown,
    bodyContentType?: ContentTypes,
    responseStatusCallbacks?: ResponseStatusCallbacks,
    responseStatusCallbacksWithPromises?: ResponseStatusCallbacksWithPromises
  ): Promise<T | void> {
    return this.makeHttpCall<T>(url, 'PUT', body, bodyContentType, responseStatusCallbacks, responseStatusCallbacksWithPromises);
  }

  public static async post<T = void>(
    url: string,
    body?: unknown,
    bodyContentType?: ContentTypes,
    responseStatusCallbacks?: ResponseStatusCallbacks,
    responseStatusCallbacksWithPromises?: ResponseStatusCallbacksWithPromises
  ): Promise<T | void> {
    return this.makeHttpCall<T>(url, 'POST', body, bodyContentType, responseStatusCallbacks, responseStatusCallbacksWithPromises);
  }

  public static async delete<T = void>(
    url: string,
    body?: unknown,
    bodyContentType?: ContentTypes,
    responseStatusCallbacks?: ResponseStatusCallbacks,
    responseStatusCallbacksWithPromises?: ResponseStatusCallbacksWithPromises
  ): Promise<T | void> {
    return this.makeHttpCall<T>(url, 'DELETE', body, bodyContentType, responseStatusCallbacks, responseStatusCallbacksWithPromises);
  }

  private static async getAuthenticationHeaderInformation(): Promise<HeadersInit> {
    const token = await AuthorizeService.getAccessToken();
    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  // NOTE: This function does NOT return the HTTP status of the AJAX call, but it does accept an optional parameter
  // that we can use to provide callback functions for specific HTTP statuses.
  // Note that these callback functions will execute before this function returns.
  private static async makeHttpCall<T = void>(
    url: string,
    verb: HttpVerbs,
    body?: unknown,
    bodyContentType?: ContentTypes,
    responseStatusCallbacks?: ResponseStatusCallbacks,
    responseStatusCallbacksWithPromises?: ResponseStatusCallbacksWithPromises
  ): Promise<T | void> {
    const bodyContent = bodyContentType === 'multipart/form-data' ? body : JSON.stringify(body);

    const requestInitObj: RequestInit = {
      method: verb,
      headers: {
        Accept: 'application/json',
        Referer: window.location.href,
        // Always attach an auth token if it's present
        ...(await this.getAuthenticationHeaderInformation())
      },
      body: bodyContent as any
    };

    // We don't set the content-type header if it's multipart form data (ie. a file upload)
    if (bodyContentType !== 'multipart/form-data') {
      (requestInitObj.headers as any)['Content-Type'] = bodyContentType ?? 'application/json';
    }

    const result = await fetch(url, requestInitObj);

    // If we hit an error status, try to get error information from the response and throw a new Error with
    // that info as the message.
    // NOTE: We have an error message helper class that we use elsewhere to format error messages nicely for display.
    if (result.status >= 400 && result.status < 600) {
      let errorContent: unknown | null = null;
      try {
        errorContent = await result.json();
      } catch {}

      if (errorContent) {
        if (typeof errorContent === 'string') {
          throw result.status === 400 ? new BadRequestError(errorContent) : new Error(errorContent);
        } else if (typeof errorContent === 'object') {
          // Try to get the first property value for objects.
          // For example, a response may contain something like "{ notLoggedIn: true }"
          if (Object.keys(errorContent as object).length > 0) {
            const objData = Object.keys(errorContent)[0];
            throw result.status === 400 ? new BadRequestError(objData) : new Error(objData);
          }
        }
      }

      throw result.status === 400 ? new BadRequestError() : new Error();
    }

    let responseData: T | null = null;

    if (result && result.status !== HTTPStatuses.NoContent && !result.redirected) {
      try {
        responseData = (await result.json()) as T;
      } catch {}
    }

    // Run any HTTP response status callbacks that we need do not need to await
    if (responseStatusCallbacks && responseStatusCallbacks[result.status]) {
      responseStatusCallbacks[result.status](responseData);
    }

    // Run any HTTP response status callbacks that we need to await
    if (responseStatusCallbacksWithPromises && responseStatusCallbacksWithPromises[result.status]) {
      await responseStatusCallbacksWithPromises[result.status](responseData);
    }

    if (responseData !== null) {
      return responseData;
    }

    return;
  }
}
