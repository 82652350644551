import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, UserRoles } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

export const AdminUsers: React.FC = () => {
  const [users, setUsers] = useState<User_BasicDetails_ViewModel[] | null>(null);
  const [filterRole, setFilterRole] = useState<string | null>(null);

  React.useEffect(() => {
    filterUsersByRole(UserRoles.Advisor);
  }, []);

  const filterUsersByRole = async (roleName: string) => {
    setUsers(null);
    setFilterRole(roleName);

    const usersInRole = await AdminApiService.getUsersInRole(roleName);
    setUsers(usersInRole);
  };

  const populateAllUsers = async () => {
    const users = await AdminApiService.getAllUsers();
    setUsers(users);
    setFilterRole('All');
  };

  return (
    <Container>
      <Helmet>
        <title>Admin / All Users</title>
      </Helmet>
      <Row>
        <Col>
          <h1>Users</h1>
          <p>
            Show only:{' '}
            <Button color={filterRole === UserRoles.Advisor ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Advisor)}>
              Advisors
            </Button>{' '}
            <Button color={filterRole === UserRoles.Affiliate ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Affiliate)}>
              Affiliates
            </Button>{' '}
            <Button color={filterRole === UserRoles.Consumer ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Consumer)}>
              Consumers
            </Button>{' '}
            <Button color={filterRole === UserRoles.Admin ? 'info' : 'light'} onClick={(e) => filterUsersByRole(UserRoles.Admin)}>
              Admins
            </Button>{' '}
            <Button color={filterRole == 'All' ? 'info' : 'light'} onClick={populateAllUsers}>
              All
            </Button>{' '}
          </p>

          {users ? (
            <p>
              Found <strong>{users.length}</strong> results
            </p>
          ) : (
            <></>
          )}

          <NectarineTable<User_BasicDetails_ViewModel>
            data={users ?? []}
            searchFn={(item, searchText) => {
              const regex = new RegExp(searchText, 'gi');
              const dataToSearch: string[] = [
                item.firstName,
                item.lastName,
                item.email,
                item.nextAvailability ? DateHelper.casualDateFormat(item.nextAvailability) : ''
              ].filter((v) => v);

              return dataToSearch.some((v) => regex.test(v));
            }}
            rowDataTemplate={(item) => {
              return (
                <>
                  <NectarineTableDataColumn>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.id}/edit`}>{item.lastName}</Link>
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.id}/edit`}>{item.firstName}</Link>
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.id}/edit`}>{item.email}</Link>
                  </NectarineTableDataColumn>

                  <NectarineTableDataColumn>{item.state}</NectarineTableDataColumn>

                  {filterRole === UserRoles.Advisor ? (
                    <>
                      <NectarineTableDataColumn>
                        {item.isNectarine ? <i className="fa-duotone fa-peach light-blue"></i> : ''}
                      </NectarineTableDataColumn>

                      <NectarineTableDataColumn>
                        {item.nextAvailability && new Date(item.nextAvailability) > new Date() ? (
                          <span>{DateHelper.casualDateFormat(item.nextAvailability)}</span>
                        ) : (
                          <></>
                        )}
                      </NectarineTableDataColumn>
                    </>
                  ) : (
                    <></>
                  )}

                  {[UserRoles.Consumer, UserRoles.Affiliate].includes(filterRole!) ? (
                    <NectarineTableDataColumn>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.id}/report`}>Compliance Report</Link>
                    </NectarineTableDataColumn>
                  ) : (
                    <></>
                  )}

                  <NectarineTableDataColumn alignment="center">
                    <AdminImpersonateLink userId={item.id} />
                  </NectarineTableDataColumn>
                </>
              );
            }}
          >
            <NectarineTableHeaderColumns>
              <NectarineTableHeaderColumn<User_BasicDetails_ViewModel> sortFn={(item) => item.lastName}>Last</NectarineTableHeaderColumn>
              <NectarineTableHeaderColumn<User_BasicDetails_ViewModel> sortFn={(item) => item.firstName}>First</NectarineTableHeaderColumn>
              <NectarineTableHeaderColumn<User_BasicDetails_ViewModel> sortFn={(item) => item.email}>Email</NectarineTableHeaderColumn>
              <NectarineTableHeaderColumn<User_BasicDetails_ViewModel> sortFn={(item) => item.state}>State</NectarineTableHeaderColumn>

              {filterRole === UserRoles.Advisor ? (
                <>
                  <NectarineTableHeaderColumn<User_BasicDetails_ViewModel> sortFn={(item) => item.isNectarine}>
                    <i className="fa-duotone fa-peach light-blue"></i>
                  </NectarineTableHeaderColumn>

                  <NectarineTableHeaderColumn<User_BasicDetails_ViewModel>
                    sortFn={(item) => (item.nextAvailability ? new Date(item.nextAvailability) : new Date())}
                  >
                    Next Available
                  </NectarineTableHeaderColumn>
                </>
              ) : (
                <></>
              )}

              {[UserRoles.Consumer, UserRoles.Affiliate].includes(filterRole!) ? (
                <NectarineTableHeaderColumn<User_BasicDetails_ViewModel>>Report</NectarineTableHeaderColumn>
              ) : (
                <></>
              )}

              <NectarineTableHeaderColumn<User_BasicDetails_ViewModel> alignment="center">Impersonate</NectarineTableHeaderColumn>
            </NectarineTableHeaderColumns>
          </NectarineTable>

          {!users ? <LoadingSpinner message="Loading..." /> : <></>}
        </Col>
      </Row>
    </Container>
  );
};
