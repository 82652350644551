import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { AppSettings, ValueLimits } from '../../../constants';
import UsersApiService from '../../../services/api/UsersApiService';
import { useLoadingOverlayContext } from '../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import NectarineButton from '../../ui/NectarineButton/NectarineButton';
import NectarineTextAreaInput from '../../ui/NectarineTextAreaInput/NectarineTextAreaInput';
import NectarineTextInput from '../../ui/NectarineTextInput/NectarineTextInput';
import heroImage from './../../../img/investing-experts.png';

const Contact: React.FC = () => {
  const [messageSentSection, setMessageSentSection] = useState<{ show: boolean; text: string | null; isError: boolean }>({
    show: false,
    text: null,
    isError: false
  });

  const [editModel, setEditModel] = useState<Users_ContactUs_EditModel>({
    name: '',
    email: '',
    message: ''
  });

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  const formRef = useRef<HTMLFormElement | null>(null);

  function showMessage(isError: boolean, text: string) {
    setMessageSentSection({
      show: true,
      text,
      isError
    });
  }

  const onSave = async () => {
    try {
      showLoadingOverlay();

      await UsersApiService.contactUs(editModel);

      showMessage(false, "Your message was sent! We'll get back to you shortly.");
    } catch (error) {
      showMessage(true, 'There was a problem sending your message. Please email us at team@hellonectarine.com');
    } finally {
      hideLoadingOverlay();
    }
  };

  return (
    <>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Contact Us</title>
      </Helmet>

      <form noValidate ref={formRef} className="contact-us" onSubmit={onSave}>
        <Container>
          <Row>
            {messageSentSection.show ? (
              <Col xs={12} md={6}>
                {!messageSentSection.isError ? (
                  <>
                    <h1>Thanks for saying hi!</h1>
                    <p>{messageSentSection.text}</p>
                  </>
                ) : (
                  <>
                    <h1>Something went wrong</h1>
                    <p className="c-red">{messageSentSection.text}</p>
                  </>
                )}
              </Col>
            ) : (
              <></>
            )}

            {!messageSentSection.show ? (
              <Col xs={12} lg={6}>
                <h1>Say Hello to {AppSettings.ApplicationName}</h1>

                <p>Send us a message below and we'll get back to you ASAP!</p>

                <Row>
                  <Col xs={6} className="mb-3">
                    <NectarineTextInput
                      label="Name"
                      placeholder="Enter your name"
                      required
                      minLength={ValueLimits.Name_MinLength}
                      maxLength={ValueLimits.Name_MaxLength * 2}
                      value={editModel.name}
                      onChange={(newVal) => {
                        setEditModel((v) => ({ ...v, name: newVal ?? '' }));
                      }}
                    />
                  </Col>

                  <Col xs={6} className="mb-3">
                    <NectarineTextInput
                      label="Email"
                      type="email"
                      placeholder="your.name@example.com"
                      required
                      maxLength={ValueLimits.Email_MaxLength}
                      value={editModel.email}
                      onChange={(newVal) => {
                        setEditModel((v) => ({ ...v, email: newVal ?? '' }));
                      }}
                    />
                  </Col>

                  <Col xs={12} className="mb-3">
                    <NectarineTextAreaInput
                      label="Message"
                      secondaryLabel=" (2,000 characters max)"
                      placeholder="Enter your message here"
                      required
                      rows={6}
                      maxLength={2000}
                      value={editModel.message}
                      onChange={(newVal) => {
                        setEditModel((v) => ({ ...v, message: newVal ?? '' }));
                      }}
                    />
                  </Col>
                </Row>

                <NectarineButton
                  type="blue"
                  text="Contact Us!"
                  onClick={async () => {
                    const formIsValid = formRef.current?.checkValidity();
                    formRef.current!.reportValidity();

                    if (formIsValid) {
                      await onSave();
                    }
                  }}
                />
              </Col>
            ) : (
              <></>
            )}

            <Col xs={12} lg={6}>
              <img src={heroImage} className="hero center" alt="Nectarine Investing Experts Illustration" />
            </Col>
          </Row>
        </Container>
      </form>
    </>
  );
};

export default Contact;
