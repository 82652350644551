import { useEffect, useRef, useState } from 'react';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Subscription } from 'rxjs';
import NectarineButton from '../NectarineButton/NectarineButton';
import NectarineDateInput from '../NectarineDateInput/NectarineDateInput';
import NectarineNumericInput from '../NectarineNumericInput/NectarineNumericInput';
import NectarineTextAreaInput from '../NectarineTextAreaInput/NectarineTextAreaInput';
import NectarineTextInput from '../NectarineTextInput/NectarineTextInput';
import NectarineToggle from '../NectarineToggle/NectarineToggle';
import { useNotificationsPanelContext } from '../Notifications/NotificationsPanel/NotificationsPanelContext';
import { FormModalData, FormModalDataValueObject, FormModalInput, useFormModalContextContext } from './FormModalContext';

const FormModal: React.FC = () => {
  const [displayedFormModalData, setDisplayedFormModalData] = useState<FormModalData | null>(null);
  const [modalIsDisplayed, setModalIsDisplayed] = useState<boolean>(false);
  const [editData, setEditData] = useState<FormModalDataValueObject>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { showErrorNotification } = useNotificationsPanelContext();
  const { formModalData$, turnOffSaveButtonLoadState$, hideFormModal } = useFormModalContextContext();

  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    const allSubscriptions = new Subscription();

    allSubscriptions.add(
      formModalData$.subscribe((newData) => {
        if (newData) {
          const newEditData: FormModalDataValueObject = {};

          newData.inputs.forEach((v) => {
            newEditData[v.id] = v.initialValue;
          });

          setEditData(newEditData);

          setModalIsDisplayed(true);
          setDisplayedFormModalData(newData);
        } else {
          setModalIsDisplayed(false);

          setTimeout(() => {
            setDisplayedFormModalData(null);
          }, 500);
        }
      })
    );

    allSubscriptions.add(
      turnOffSaveButtonLoadState$.subscribe(() => {
        setIsSaving(false);
      })
    );

    return () => {
      allSubscriptions.unsubscribe();
    };
  }, [formModalData$, turnOffSaveButtonLoadState$]);

  const isFormValid = () => {
    if (!displayedFormModalData) {
      return false;
    }

    let returnValue = true;

    for (const currInput of displayedFormModalData.inputs) {
      const currValue = editData[currInput.id];

      switch (currInput.type) {
        case 'text':
        case 'textarea': {
          if (currInput.textInputSettings?.minLength !== undefined) {
            const parsedValue = ((currValue as string) ?? '').trim();
            if (parsedValue.length < currInput.textInputSettings.minLength) {
              returnValue = false;
            }
          }
          if (currInput.textInputSettings?.maxLength !== undefined) {
            const parsedValue = ((currValue as string) ?? '').trim();
            if (parsedValue.length > currInput.textInputSettings.maxLength) {
              returnValue = false;
            }
          }

          break;
        }

        case 'number': {
          if (currInput.numericInputSettings?.min !== undefined) {
            const parsedValue = (currValue as number) ?? 0;

            if (parsedValue < currInput.numericInputSettings.min) {
              returnValue = false;
            }
          }
          if (currInput.numericInputSettings?.max !== undefined) {
            const parsedValue = (currValue as number) ?? 0;

            if (parsedValue > currInput.numericInputSettings.max) {
              returnValue = false;
            }
          }

          break;
        }

        case 'date': {
          if (currInput.dateInputSettings?.min !== undefined) {
            const parsedValue = (currValue as Date) ?? undefined;

            if (parsedValue === undefined || parsedValue < currInput.dateInputSettings.min) {
              returnValue = false;
            }
          }
          if (currInput.dateInputSettings?.max !== undefined) {
            const parsedValue = (currValue as Date) ?? undefined;

            if (parsedValue === undefined || parsedValue > currInput.dateInputSettings.max) {
              returnValue = false;
            }
          }

          break;
        }
      }
    }

    return returnValue;
  };

  const getInputJsx = (currInput: FormModalInput) => {
    switch (currInput.type) {
      case 'boolean':
        return (
          <NectarineToggle
            label={currInput.label}
            secondaryLabel={currInput.secondaryLabel}
            value={(editData[currInput.id] as boolean) ?? undefined}
            onChange={(e) => {
              setEditData((v) => {
                return {
                  ...v,
                  [currInput.id]: e
                };
              });
            }}
          />
        );

      case 'number':
        return (
          <NectarineNumericInput
            label={currInput.label}
            secondaryLabel={currInput.secondaryLabel}
            value={editData[currInput.id] as number}
            onChange={(e) => {
              setEditData((v) => {
                return {
                  ...v,
                  [currInput.id]: e
                };
              });
            }}
            placeholder={currInput.numericInputSettings?.placeholderText}
            required={currInput.isRequired ?? true}
            min={currInput.numericInputSettings?.min}
            max={currInput.numericInputSettings?.max}
          />
        );

      case 'text':
        return (
          <NectarineTextInput
            label={currInput.label}
            secondaryLabel={currInput.secondaryLabel}
            value={(editData[currInput.id] as string) ?? undefined}
            onChange={(e) => {
              setEditData((v) => {
                return {
                  ...v,
                  [currInput.id]: e
                };
              });
            }}
            placeholder={currInput.textInputSettings?.placeholderText}
            required={currInput.isRequired ?? true}
            minLength={currInput.textInputSettings?.minLength}
            maxLength={currInput.textInputSettings?.maxLength}
          />
        );

      case 'textarea':
        return (
          <NectarineTextAreaInput
            label={currInput.label}
            secondaryLabel={currInput.secondaryLabel}
            value={(editData[currInput.id] as string) ?? undefined}
            onChange={(e) => {
              setEditData((v) => {
                return {
                  ...v,
                  [currInput.id]: e
                };
              });
            }}
            placeholder={currInput.textInputSettings?.placeholderText}
            required={currInput.isRequired ?? true}
            minLength={currInput.textInputSettings?.minLength}
            maxLength={currInput.textInputSettings?.maxLength}
          />
        );

      case 'date':
        return (
          <NectarineDateInput
            label={currInput.label}
            secondaryLabel={currInput.secondaryLabel}
            value={(editData[currInput.id] as Date) ?? undefined}
            onChange={(e) => {
              setEditData((v) => {
                return {
                  ...v,
                  [currInput.id]: e
                };
              });
            }}
            placeholder={currInput.dateInputSettings?.placeholderText}
            required={currInput.isRequired ?? true}
            min={currInput.dateInputSettings?.min}
            max={currInput.dateInputSettings?.max}
          />
        );
    }
  };

  const getModalClasses = () => {
    const returnValue: string[] = [];

    if (displayedFormModalData?.inputs.length === 1 && ['number', 'date', 'boolean'].includes(displayedFormModalData.inputs[0].type)) {
      returnValue.push('thinModal');
    }

    return returnValue.join(' ');
  };

  return (
    <>
      {displayedFormModalData ? (
        <Modal isOpen={modalIsDisplayed} toggle={() => {}} className={getModalClasses()}>
          <ModalHeader toggle={hideFormModal}>
            <span className="bold">{displayedFormModalData.modalTitle ?? 'Edit'}</span>
          </ModalHeader>

          <ModalBody>
            <form ref={formRef} noValidate>
              <Row className="mb-4">
                {displayedFormModalData.inputs.map((currInput) => {
                  return (
                    <Col sm="12" lg={(currInput.columnSpan ?? 2) === 2 ? '12' : '6'} key={currInput.id}>
                      {getInputJsx(currInput)}
                    </Col>
                  );
                })}
              </Row>
            </form>
          </ModalBody>

          <ModalFooter>
            <div className="d-flex gap-3">
              <NectarineButton
                text="Cancel"
                type="white"
                onClick={() => {
                  hideFormModal();
                }}
              />

              <NectarineButton
                text="Save"
                type="blue"
                showLoadingDots={displayedFormModalData.shouldShowLoadingDotsOnSave && isSaving}
                isDisabled={!isFormValid() || isSaving}
                onClick={() => {
                  formRef.current!.checkValidity();
                  formRef.current!.reportValidity();

                  if (!isFormValid()) {
                    showErrorNotification('One or more inputs are not valid.');
                    return;
                  }

                  if (displayedFormModalData.shouldShowLoadingDotsOnSave) {
                    setIsSaving(true);
                  }

                  displayedFormModalData.onSaveFn(editData);
                }}
                onClickValidationFn={() => {
                  formRef.current!.checkValidity();
                  formRef.current!.reportValidity();
                }}
              />
            </div>
          </ModalFooter>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormModal;
