import { sortBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import useNavigationService from '../../../../hooks/UseNavigationService';
import AdminApiService from '../../../../services/api/AdminApiService';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineAutocompleteInput from '../../../ui/NectarineAutocompleteInput/NectarineAutocompleteInput';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineDateInput from '../../../ui/NectarineDateInput/NectarineDateInput';
import NectarineNumericInput from '../../../ui/NectarineNumericInput/NectarineNumericInput';
import NectarineSelectInput from '../../../ui/NectarineSelectInput/NectarineSelectInput';
import NectarineTextInput from '../../../ui/NectarineTextInput/NectarineTextInput';
import NectarineToggle from '../../../ui/NectarineToggle/NectarineToggle';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

const statusOptions: NectarineSelectInputItem[] = [
  { value: '', name: 'Choose a status...' },
  { value: 'Active', name: 'Active' },
  { value: 'Scheduled', name: 'Scheduled' },
  { value: 'Canceled', name: 'Canceled' },
  { value: 'Completed', name: 'Completed' },
  { value: 'Refunded', name: 'Refunded' }
];

export const AdminEditEvent = () => {
  const { eventID } = useParams();
  const [event, setEvent] = useState<Admin_Event_ViewModel | null>(null);
  const [eventToEdit, setEventToEdit] = useState<Admin_Event_EditModel | null>(null);
  const [redirectToAdminList, setRedirect] = useState<boolean>(false);

  const [allAdvisors, setAllAdvisors] = useState<Advisor_BasicDetails_ViewModel[]>([]);

  const [selectedConsumerObj, setSelectedConsumerObj] = useState<User_BasicDetails_ViewModel | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { navigateTo_Admin_EventsView } = useNavigationService();

  useEffect(() => {
    const loadData = async () => {
      const advisorsResults = await AdvisorsApiService.getAllAdvisorDetails();
      setAllAdvisors(sortBy(advisorsResults, (v) => v.fullName));

      const eventData = await AdminApiService.getEventById(eventID!);
      setEvent(eventData);

      setEventToEdit({
        id: eventData.id,
        advisorId: eventData.advisorId,
        consumerId: eventData.consumerId,
        isNectarine: eventData.isNectarine,
        location: eventData.location,
        paymentAmount: eventData.paymentAmount,
        paymentSuccessful: eventData.paymentSuccessful,
        status: eventData.status
      });
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = async () => {
    try {
      await AdminApiService.updateEvent(eventToEdit!);
      setRedirect(true);
    } catch (error) {
      alert(error);
      console.error('Unable to add item.', error);
    }

    return false;
  };

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.Root}`} />;
  }

  const getMatchingAdvisor = (): Advisor_BasicDetails_ViewModel | null => {
    return allAdvisors.find((v) => v.id === eventToEdit?.advisorId) ?? null;
  };

  return (
    <Container>
      <Helmet>
        <title>Edit Event</title>
      </Helmet>
      <Row>
        <Col>
          <span
            className="c-blue2 pointer"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              navigateTo_Admin_EventsView();
            }}
          >
            <i className="c-black fa-regular fa-arrow-left back-arrow"></i> Back
          </span>

          <div className="mb-3"></div>

          <h2>Edit Event</h2>

          {!eventToEdit ? <LoadingSpinner message="Loading..." /> : <></>}

          {event && eventToEdit ? (
            <form ref={formRef} noValidate>
              <div className="d-flex align-items-end gap-3 mt-3">
                <div className="w-50">
                  <NectarineSelectInput
                    label="Advisor ID"
                    items={[
                      {
                        name: 'Choose an advisor...',
                        value: ''
                      },
                      ...allAdvisors.map((v) => ({
                        name: `${v.firstName} ${v.lastName}`,
                        value: v.id
                      }))
                    ]}
                    value={eventToEdit.advisorId}
                    onChange={(newVal) => {
                      console.log(newVal);

                      setEventToEdit((v) => ({ ...v!, advisorId: newVal as string }));
                    }}
                    required
                  />
                </div>

                {eventToEdit.advisorId ? (
                  <td>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${eventToEdit.advisorId}/edit`}>
                      {getMatchingAdvisor()?.fullName}
                    </Link>
                    <AdminImpersonateLink userId={eventToEdit.advisorId!} />
                  </td>
                ) : (
                  <></>
                )}
              </div>

              <div className="d-flex align-items-end gap-3 mt-3">
                <div className="w-50">
                  <NectarineAutocompleteInput<User_BasicDetails_ViewModel>
                    label="Consumer ID"
                    initialValue={eventToEdit.consumerId}
                    placeholder="Search by consumer name or database ID"
                    onChange={(newVal) => {
                      setEventToEdit((v) => ({ ...v!, consumerId: newVal! }));
                    }}
                    onSelect={(newVal: string, changedObj: User_BasicDetails_ViewModel) => {
                      setSelectedConsumerObj(changedObj ? changedObj : null);
                    }}
                    required
                    suggestionsApiSearchFn={(newVal) => AdminApiService.searchUsers(newVal)}
                    suggestionDisplayNameProperty="fullName"
                    suggestionValueProperty="id"
                    selectedEntityIconClass="fa-duotone fa-regular fa-user"
                  />
                </div>

                {selectedConsumerObj ? (
                  <td>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${selectedConsumerObj?.id}/edit`}>
                      {selectedConsumerObj?.fullName}
                    </Link>
                    <AdminImpersonateLink userId={selectedConsumerObj.id} />
                  </td>
                ) : (
                  <></>
                )}
              </div>

              <div className="d-flex align-items-end gap-3 mt-3">
                <NectarineDateInput label="Created At" value={event.createdAt} onChange={() => {}} readOnly />
              </div>

              <div className="d-flex align-items-end gap-3 mt-3">
                <NectarineDateInput label="Start Time" value={event.startTime} onChange={() => {}} readOnly />
              </div>

              <div className="w-50 mt-3">
                <NectarineSelectInput
                  label="Status"
                  items={statusOptions}
                  value={eventToEdit.status}
                  onChange={(newVal) => setEventToEdit((v) => ({ ...v!, status: newVal as string }))}
                  display="inline-block"
                />
              </div>

              <div className="w-50 mt-3">
                <NectarineNumericInput
                  label="Client Paid Amount"
                  value={eventToEdit.paymentAmount}
                  onChange={(newVal) => setEventToEdit((v) => ({ ...v!, paymentAmount: newVal! }))}
                />
              </div>

              <div className="w-50 mt-3">
                <NectarineToggle
                  label="Payment Successful"
                  value={eventToEdit.paymentSuccessful}
                  onChange={(newVal) => setEventToEdit((v) => ({ ...v!, paymentSuccessful: newVal }))}
                />

                {!eventToEdit.paymentSuccessful && eventToEdit.paymentAmount > 0 ? (
                  <span className="text-danger">
                    <strong>FAILED!</strong>
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-50 mt-3">
                <NectarineToggle
                  label="Is Nectarine"
                  secondaryLabel="(When turned on, this event is operating under the Nectarine RIA)"
                  value={eventToEdit.isNectarine}
                  onChange={(newVal) => setEventToEdit((v) => ({ ...v!, isNectarine: newVal }))}
                />
              </div>

              <div className="basicContainer w-50 mt-4">
                <span className="containerLabel">Zoom</span>

                <div>
                  <NectarineTextInput
                    label="Zoom Link"
                    value={eventToEdit.location}
                    onChange={(newVal) => setEventToEdit((v) => ({ ...v!, location: newVal! }))}
                  />
                </div>

                <div className="mt-3">
                  <strong>Zoom Links</strong>

                  <div className="d-flex gap-4">
                    <a href={event.location}>Zoom</a>
                    <a href={event.rescheduleUrl}>Reschedule</a>
                    <a href={event.cancelUrl}>Cancel</a>{' '}
                    {event.consumerFullName && event.advisorFullName ? (
                      <a href={'/leaveareview/' + event.advisorId + '/' + event.consumerId}>Nectarine Review Page</a>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-3"></div>

              <NectarineButton
                text="Save"
                onClick={async () => {
                  const formIsValid = formRef.current?.checkValidity();
                  formRef.current!.reportValidity();

                  if (formIsValid) {
                    await onSave();
                  }
                }}
              />
            </form>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};
