import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationRoutes } from '../../../../constants';
import HttpHelper from '../../../Helpers/HttpHelper';

interface AdminImpersonateLinkProps {
  userId: string;
}

const adminRootUrl = `/${ApplicationRoutes.AdminRoutes.Root}`;

const AdminImpersonateLink: React.FC<AdminImpersonateLinkProps> = ({ userId }: AdminImpersonateLinkProps) => {
  const onImpersonate = async (event: React.MouseEvent) => {
    event.preventDefault();

    try {
      await HttpHelper.put('/api/users/' + userId + '/impersonate');
    } catch (error) {
      console.error('Unable to impersonate user.', error);
    }

    window.location.href = adminRootUrl;

    return true;
  };

  return (
    <Link to={adminRootUrl} onClick={(e) => onImpersonate(e)} className="impersonate ms-1">
      🥸
    </Link>
  );
};

export default AdminImpersonateLink;
