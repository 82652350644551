import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AccordionBody, AccordionHeader, AccordionItem, Col, Container, Row, UncontrolledAccordion } from 'reactstrap';
import { ApplicationRoutes } from '../../../constants';
import { HomeApiService } from '../../../services/api/HomeApiService';
import StateHelper from '../../Helpers/StateHelper';
import { useLoadingOverlayContext } from '../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LandingPageTemplate from '../LandingPages/LandingPageTemplate';
import './../../../styles/common.scss';
import './Home.scss';

const Home = () => {
  const [advisorAttributes, setAdvisorAttributes] = useState<Advisor_Attribute_ViewModel[] | null>(null);

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  useEffect(() => {
    const loadData = async () => {
      try {
        const results = await HomeApiService.getAllAdvisorAttributes();
        setAdvisorAttributes(results);
      } catch (error) {
        // NOTE: Any errors are logged by the API service
      }
    };

    loadData();
  }, [hideLoadingOverlay, showLoadingOverlay]);

  return (
    <Container>
      <LandingPageTemplate plural="financial advisors" singular="advisor" />

      <Row className="mt-5">
        <Col lg="4" md="12" sm="12" xs="12">
          <UncontrolledAccordion defaultOpen={[]} toggle={() => {}}>
            <AccordionItem>
              <AccordionHeader targetId="1">Browse Financial Advisors by State</AccordionHeader>
              <AccordionBody accordionId="1">
                <ul>
                  {StateHelper.usStatesAndTerritories.map((state) => (
                    <li key={state.abbreviation}>
                      <Link to={'/s/' + StateHelper.getStateNameForUrl(state.abbreviation)}>
                        {state.name} Fiduciary Flat-Fee Financial Advisors
                      </Link>
                    </li>
                  ))}
                </ul>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        </Col>

        {advisorAttributes ? (
          <Col lg="4" md="12" sm="12" xs="12">
            <UncontrolledAccordion defaultOpen={[]} toggle={() => {}}>
              <AccordionItem>
                <AccordionHeader targetId="1">Browse Financial Advisors by Speciality</AccordionHeader>
                <AccordionBody accordionId="1">
                  <ul>
                    {advisorAttributes.map((att) => (
                      <li key={att.slug}>
                        <Link to={`/${ApplicationRoutes.Advisors}/${att.slug}`}>{att.title}</Link>
                      </li>
                    ))}
                  </ul>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </Col>
        ) : (
          <></>
        )}

        <Col lg="4" md="12" sm="12" xs="12">
          <UncontrolledAccordion defaultOpen={[]} toggle={() => {}}>
            <AccordionItem>
              <AccordionHeader targetId="1">Browse by Business Model</AccordionHeader>
              <AccordionBody accordionId="1">
                <ul>
                  <li>
                    <Link to={`/${ApplicationRoutes.HourlyFinancialAdvisors}`}>Hourly Financial Advisors</Link>
                  </li>
                  <li>
                    <Link to={`/${ApplicationRoutes.FlatFeeFinancialPlanners}`}>Flat-Fee Financial Planners</Link>
                  </li>
                  <li>
                    <Link to={`/${ApplicationRoutes.SeoLandingPagesRoutes.FeeOnlyFiduciaries_Full}`}>Fee-Only Fiduciaries</Link>
                  </li>
                  <li>
                    <Link to={`/${ApplicationRoutes.SeoLandingPagesRoutes.FiduciaryCFPS_Full}`}>Fiduciary CFPs</Link>
                  </li>
                  <li>
                    <Link to={`/${ApplicationRoutes.SeoLandingPagesRoutes.IndependentFinancialAdvisors_Full}`}>
                      Independent Financial Advisors
                    </Link>
                  </li>
                  <li>
                    <Link to={`/${ApplicationRoutes.SeoLandingPagesRoutes.FlatRateFinancialPlanners_Full}`}>
                      Flat-Rate Financial Planners
                    </Link>
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
