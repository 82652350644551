import { addDays, addWeeks } from 'date-fns';
import { sortBy } from 'lodash';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminPayments from '../AdminPayments/AdminPayments';

const payPeriodStartDate = new Date('2023-07-16T00:00:00'); // First day of first ever pay period

const AdminAdvisorPayments = () => {
  const getPayPeriods = (): Admin_PayPeriod_ViewModel[] => {
    const today = new Date(); // Current date
    let payPeriods: Admin_PayPeriod_ViewModel[] = [];
    let currentDate = new Date(payPeriodStartDate); // Start from a copy of the start date

    while (currentDate <= today) {
      payPeriods.push({
        start: new Date(currentDate),
        end: addDays(currentDate, 13)
      });

      // Move to the next period
      currentDate = addWeeks(currentDate, 2);
    }

    // Sort weeks in descending order
    payPeriods = sortBy(payPeriods, (v) => v.start).reverse();

    return payPeriods;
  };

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Advisor Payments</title>
      </Helmet>
      <Row>
        <Col>
          <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}>
            <i className="c-black fa-regular fa-arrow-left back-arrow"></i> Back
          </Link>
          <h1>Advisor Payments</h1>

          <AdminPayments userType="advisor" payPeriods={getPayPeriods()} />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminAdvisorPayments;
