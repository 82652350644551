import React, { useEffect, useState } from 'react';
import { ApplicationRoutes, CommonQueryParameterNames, LocalStorageKeys, UserLoginActions } from '../../constants';
import { LoadingBlank } from '../LoadingBlank/LoadingBlank';
import AuthorizeService, { AuthenticationResultStatus } from './AuthorizeService';

const Login: React.FC<{ action: string }> = ({ action }) => {
  const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    switch (action) {
      case UserLoginActions.Login:
        login(getReturnUrl());
        break;
      case UserLoginActions.LoginCallback:
        processLoginCallback();
        break;
      case UserLoginActions.LoginFailed:
        const params = new URLSearchParams(window.location.search);
        const error = params.get(CommonQueryParameterNames.Message);
        setMessage(error ?? '');
        break;
      case UserLoginActions.Profile:
        redirectToProfile();
        break;
      case UserLoginActions.Register:
        redirectToRegister();
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  const renderContent = () => {
    if (message) {
      return <div>{message}</div>;
    } else {
      switch (action) {
        case UserLoginActions.Login:
          return <LoadingBlank />;
        case UserLoginActions.LoginCallback:
          return <LoadingBlank />;
        case UserLoginActions.Profile:
        case UserLoginActions.Register:
          return <div></div>;
        default:
          throw new Error(`Invalid action '${action}'`);
      }
    }
  };

  const login = async (returnUrl: string) => {
    const state = { returnUrl };
    const result = await AuthorizeService.signIn(state);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;
      case AuthenticationResultStatus.Success:
        await navigateToReturnUrl(returnUrl);
        break;
      case AuthenticationResultStatus.Fail:
        setMessage((result as any).message ?? '');
        break;
      default:
        throw new Error(`Invalid status result ${result.status}.`);
    }
  };

  const processLoginCallback = async () => {
    const url = window.location.href;
    const result = await AuthorizeService.completeSignIn(url);

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        throw new Error('Should not redirect.');

      case AuthenticationResultStatus.Success:
        const state = (result as any).state;
        if (!state) {
          await navigateToReturnUrl(getReturnUrl(state));
        } else {
          const returnUrl = localStorage.getItem(LocalStorageKeys.General.ReturnUrl) ?? null;

          if (returnUrl) {
            localStorage.removeItem(LocalStorageKeys.General.ReturnUrl);
            await navigateToReturnUrl(returnUrl);
          } else {
            await navigateToReturnUrl(getReturnUrl(state));
          }
        }
        break;

      case AuthenticationResultStatus.Fail:
        setMessage((result as any).message ?? '');
        break;

      default:
        throw new Error(`Invalid authentication result status '${result.status}'.`);
    }
  };

  const getReturnUrl = (state?: any) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(CommonQueryParameterNames.ReturnUrl);

    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
  };

  const redirectToRegister = () => {
    redirectToApiAuthorizationPath(
      `${ApplicationRoutes.AuthenticationRoutes.IdentityRegisterPath}?${CommonQueryParameterNames.ReturnUrl}=${encodeURI(ApplicationRoutes.AuthenticationRoutes.Login)}`
    );
  };

  const redirectToProfile = () => {
    redirectToApiAuthorizationPath(ApplicationRoutes.AuthenticationRoutes.IdentityManagePath);
  };

  const redirectToApiAuthorizationPath = (apiAuthorizationPath: string) => {
    const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
    window.location.replace(redirectUrl);
  };

  const navigateToReturnUrl = (returnUrl: string) => {
    window.location.replace(returnUrl);
  };

  return renderContent();
};

export default Login;
