import { forwardRef, useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import GeneralHelper from '../../Helpers/GeneralHelper';
import LoadingDots from '../LoadingAnimations/LoadingDots/LoadingDots';
import './NectarineButton.scss';

interface NectarineButtonProps {
  text: string;
  iconClassString?: string;
  onClick: (() => void) | (() => Promise<void>);
  // An optional click event that always fires, even if the button is disabled
  onClickValidationFn?: () => void;
  // An optional click event that only fires when the button is disabled
  onDisabledClickFn?: () => void;
  type?: 'blue' | 'red' | 'white' | 'green';
  size?: 'regular' | 'medium' | 'small';
  isDisabled?: boolean;
  showLoadingDots?: boolean;
  disabledTooltipText?: string;
}

const NectarineButton = forwardRef<HTMLButtonElement, NectarineButtonProps>((props: NectarineButtonProps, ref) => {
  // NOTE: We have to put a letter in front of the GUID, or else the UncontrolledTooltip breaks
  const id = useRef<string>('M' + GeneralHelper.generateGuid());

  const getColorClass = () => {
    switch (props.type) {
      case 'red':
        return 'btn-danger';

      case 'white':
        return 'btn-light';

      case 'green':
        return 'btn-success';

      case 'blue':
      default:
        return 'btn-primary';
    }
  };

  const getButtonSizeCSSClass = () => {
    switch (props.size) {
      case 'regular':
        return '';

      case 'small':
        return 'isSmallButton';

      default:
        return 'isMediumButton';
    }
  };

  return (
    <div id={id.current} className={`buttonWrapper d-inline-block ${getButtonSizeCSSClass()}`}>
      {/* Include an overlay so we can still receive a click event and run a validation function if we need */}
      {props.isDisabled ? (
        <div
          className="disabledButtonOverlay"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            props.onClickValidationFn?.();

            props.onDisabledClickFn?.();
          }}
        ></div>
      ) : (
        <></>
      )}

      {props.disabledTooltipText ? <UncontrolledTooltip target={id.current}>{props.disabledTooltipText}</UncontrolledTooltip> : <></>}

      <button
        ref={ref}
        type="button"
        className={`btn ${getColorClass()} nectarineButton ${props.isDisabled ? 'isDisabled' : ''}`}
        disabled={props.isDisabled ?? false}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          props.onClickValidationFn?.();

          if (!props.isDisabled) {
            props.onClick();
          }
        }}
        onKeyDown={(e) => {
          if ((!props.isDisabled && e.key === ' ') || e.key === 'Enter') {
            props.onClick();
          }
        }}
      >
        <div className={`nectarineButtonContent`}>
          {props.iconClassString ? (
            <span className="me-2">
              <span className={props.iconClassString}></span>
            </span>
          ) : (
            <></>
          )}

          <span className="buttonText">{props.text}</span>

          <div className={`loadingDotsWrapper ${props.showLoadingDots ? 'isDisplayed' : ''}`}>
            <LoadingDots />
          </div>
        </div>
      </button>
    </div>
  );
});

NectarineButton.displayName = 'NectarineButton';

export default NectarineButton;
