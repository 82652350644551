export default class GeneralHelper {
  public static isStringAGuid(input: string): boolean {
    const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return guidRegex.test(input);
  }

  public static formatNumberAsCurrency(input: number): string {
    let returnValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2
    }).format(input);

    if (returnValue.endsWith('.00')) {
      returnValue = returnValue.substring(0, returnValue.length - 3);
    }

    return returnValue;
  }

  public static formatNumberWithCommas(input: number): string {
    return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  public static formatPriceRange(input1: number, input2: number): string {
    if (input1 === input2) {
      return GeneralHelper.formatNumberAsCurrency(input1);
    }

    return `${GeneralHelper.formatNumberAsCurrency(input1)}-${GeneralHelper.formatNumberAsCurrency(input2)}`;
  }

  public static toTitleCase(str: string): string {
    return str
      .split(' ') // Split the string into an array of words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back into a single string
  }

  public static capitalizeFirstLetterOfWord(input: string): string {
    input = (input ?? '').trim();

    if (!input.length) {
      return '';
    }

    if (input.length >= 2) {
      return input[0].toUpperCase() + input.slice(1, input.length);
    } else {
      return input.toUpperCase();
    }
  }

  public static generateGuid(): string {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
      (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
    );
  }

  public static convertBytesToMB(bytes: number): number {
    return bytes / 1024 / 1024;
  }
}
