import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import RecordingsApiService from '../../../../services/api/RecordingsApiService';
import DateHelper from '../../../Helpers/DateHelper';
import GeneralHelper from '../../../Helpers/GeneralHelper';
import { useConfirmationOverlayContext } from '../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';

const noLinkedEventText = 'No Linked Event';

const AdminRecordings: React.FC = () => {
  const [recordings, setRecordings] = useState<Admin_MeetingRecording_ViewModel[] | null>(null);
  const [presignedLinksLoadingForRecodingIds, setPresignedLinksLoadingForRecodingIds] = useState<string[]>([]);
  const [recordingsAndPresignedS3Urls, setRecordingsAndPresignedS3Urls] = useState<{ [index: string]: string }>({});

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getAllRecordings();
      setRecordings(result);
    };

    loadData();
  }, []);

  const onDelete = async (rec: Admin_MeetingRecording_ViewModel) => {
    showConfirmationOverlay({
      title: 'Confirm Deletion',
      text: `Are you sure you want to delete this recording?`,
      text2: `This is permanent and can't be undone.`,
      noButtonText: 'Not yet',
      yesButtonText: 'Delete',
      yesAction: async () => {
        const updatedRecordings = [...recordings!];
        const index = updatedRecordings.indexOf(rec);
        if (index !== -1) {
          updatedRecordings.splice(index, 1);
        }

        try {
          await AdminApiService.deleteRecording(rec.id);

          setRecordings(updatedRecordings);
          alert('Recording deleted successfully.');
        } catch (error) {
          alert(error);
          console.error('Unable to delete item.', error);
        }
      }
    });
  };

  async function setS3LinksForRecording(recordingId: string): Promise<void> {
    try {
      setPresignedLinksLoadingForRecodingIds((v) => [...v, recordingId]);

      const presignedS3Url = await RecordingsApiService.getPresignedS3UrlForRecording(recordingId);

      setRecordingsAndPresignedS3Urls((v) => {
        return {
          ...v,
          [recordingId]: presignedS3Url
        };
      });
    } catch (error) {
      alert(error);
      console.error('Unable to get the S3 links for the recording.', error);
    } finally {
      setPresignedLinksLoadingForRecodingIds((v) => v.filter((m) => m !== recordingId));
    }
  }

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Recordings</title>
      </Helmet>

      <Link to={`/${ApplicationRoutes.AdminRoutes.Root}`}>
        <i className="c-black fa-regular fa-arrow-left back-arrow"></i> Back
      </Link>
      <h1>View All Recordings</h1>

      {!recordings ? <LoadingSpinner message="Loading..." /> : <></>}

      {recordings ? (
        <div>
          Showing <strong>{GeneralHelper.formatNumberWithCommas(recordings.length)}</strong> results.
        </div>
      ) : (
        <></>
      )}

      {recordings ? (
        <NectarineTable<Admin_MeetingRecording_ViewModel>
          maxHeight="50vh"
          data={recordings ?? []}
          searchPlaceholderText="Search Recordings"
          searchFn={(item, searchText) => {
            const regex = new RegExp(searchText, 'gi');
            const dataToSearch: string[] = [
              DateHelper.mediumDateFormat(item.startTime),
              item.eventId ? item.advisorEmail + item.consumerEmail : noLinkedEventText,
              item.durationInMinutes.toString(),
              item.hostEmail,
              item.fileSize.toString(),
              DateHelper.mediumDateFormat(item.createdAt),
              item.status
            ];

            return dataToSearch.some((v) => regex.test(v));
          }}
          rowDataTemplate={(item) => {
            return (
              <>
                <NectarineTableDataColumn>
                  <div>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Recordings_Full}/${item.id}/edit`}>
                      {DateHelper.mediumDateFormat(item.startTime)}
                    </Link>
                  </div>
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.durationInMinutes.toString()} minutes</NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  {item.eventId ? (
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${item.eventId}/edit`}>
                      {item.advisorEmail} + {item.consumerEmail}
                    </Link>
                  ) : (
                    <strong className="red">{noLinkedEventText}</strong>
                  )}
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.hostEmail}</NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  <div>{GeneralHelper.convertBytesToMB(item.fileSize).toFixed(1)}MB</div>
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>{DateHelper.mediumDateFormat(item.createdAt)}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.status}</NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  <div className="d-flex flex-nowrap align-items-center justify-content-center gap-2">
                    {recordingsAndPresignedS3Urls[item.id] ? (
                      <div>
                        <a href={item.downloadUrl} title="Download">
                          <i className="fa-regular fa-download"></i>
                        </a>
                        &nbsp;
                        <a className="mx-2" href={item.playUrl} title="Play">
                          <i className="fa-regular fa-play"></i>
                        </a>
                        &nbsp;
                        <a href={recordingsAndPresignedS3Urls[item.id]} title="S3">
                          <i className="fa-regular fa-database"></i>
                        </a>
                      </div>
                    ) : (
                      <NectarineButton
                        size="small"
                        text="Get Links"
                        showLoadingDots={presignedLinksLoadingForRecodingIds.includes(item.id)}
                        onClick={async () => {
                          setS3LinksForRecording(item.id);
                        }}
                      />
                    )}
                  </div>
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  <NectarineButton
                    type="red"
                    size="small"
                    text="Delete"
                    onClick={async () => {
                      await onDelete(item);
                    }}
                  />
                </NectarineTableDataColumn>
              </>
            );
          }}
        >
          <NectarineTableHeaderColumns>
            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel> sortFn={(v) => v.startTime}>
              Start Time
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel> sortFn={(v) => v.durationInMinutes}>
              Duration
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel> sortFn={(v) => v.advisorEmail + v.consumerEmail}>
              Event
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel> sortFn={(v) => v.hostEmail.toLowerCase()}>
              Host Email
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel> sortFn={(v) => v.fileSize}>Size</NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel> sortFn={(v) => v.createdAt}>
              Created At
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel> sortFn={(v) => v.status.toLowerCase()}>
              Status
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel>>Links</NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Admin_MeetingRecording_ViewModel>>Delete</NectarineTableHeaderColumn>
          </NectarineTableHeaderColumns>
        </NectarineTable>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default AdminRecordings;
