import { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { ValueLimits } from '../../../../../constants';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import { useFormModalContextContext } from '../../../../ui/FormModal/FormModalContext';
import NectarineButton from '../../../../ui/NectarineButton/NectarineButton';
import { useNotificationsPanelContext } from '../../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';

interface AdvisorEditRateProps {
  rateType: 'hourly' | 'the-plan';
  advisor: Advisor_BasicDetails_ViewModel;
  onSave: (updatedAdvisorVM: Advisor_BasicDetails_ViewModel) => void;
}

const AdvisorEditRate: React.FC<AdvisorEditRateProps> = (props: AdvisorEditRateProps) => {
  const [advisorToEdit, setAdvisorToEdit] = useState<Advisor_EditAdvisor_EditModel | null>(null);
  const [rateChangedAlert, setRateChangedAlert] = useState<boolean>(false);

  const { showFormModal, hideFormModal, turnOffSaveButtonLoadState } = useFormModalContextContext();
  const { showErrorNotification } = useNotificationsPanelContext();

  useEffect(() => {
    setAdvisorToEdit({
      bio: '',
      calendarLink: '',
      certifications: '',
      experienceStart: props.advisor.experienceStart,
      firstName: '',
      hourlyRate: props.advisor.hourlyRate,
      thePlanRate: props.advisor.thePlanRate,
      id: props.advisor.id,
      lastName: '',
      longBio: props.advisor.longBio,
      personalBio: props.advisor.personalBio,
      rightCapitalLink: '',
      rightCapitalLogin: '',
      showReviews: false,
      showInResults: false,
      title: ''
    });
  }, [props.advisor]);

  function getRateDisplayName(): string {
    return props.rateType === 'hourly' ? 'Hourly' : 'The Plan';
  }

  const editRateClick = () => {
    const inputId = 'rate';

    showFormModal({
      modalTitle: `Edit ${getRateDisplayName()} Rate`,
      shouldShowLoadingDotsOnSave: true,
      inputs: [
        {
          id: inputId,
          label: `${getRateDisplayName()} Rate`,
          type: 'number',
          columnSpan: 2,
          initialValue: props.rateType === 'hourly' ? advisorToEdit?.hourlyRate : advisorToEdit?.thePlanRate,
          isRequired: true,
          numericInputSettings:
            props.rateType === 'hourly'
              ? {
                  min: ValueLimits.TheHour_Price_MinValue,
                  max: ValueLimits.TheHour_Price_MaxValue,
                  placeholderText: 'Enter The Hour rate'
                }
              : {
                  min: ValueLimits.ThePlan_Price_MinValue,
                  max: ValueLimits.ThePlan_Price_MaxValue,
                  placeholderText: 'Enter The Plan rate'
                }
        }
      ],
      onSaveFn: async (data) => {
        const newRate = data[inputId] as number;

        const isTheHour = props.rateType === 'hourly';

        const advisorToSave = { ...advisorToEdit! };

        if (isTheHour) {
          advisorToSave.hourlyRate = newRate;
        } else {
          advisorToSave.thePlanRate = newRate;
        }

        try {
          await AdvisorsApiService.updateAdvisorRates(advisorToSave);

          const updatedAdvisorVM: Advisor_BasicDetails_ViewModel = {
            ...props.advisor,
            hourlyRate: advisorToSave.hourlyRate,
            thePlanRate: advisorToSave.thePlanRate
          };

          props.onSave(updatedAdvisorVM);

          if (isTheHour) {
            setRateChangedAlert(true);
          }

          hideFormModal();
        } catch (error: any) {
          showErrorNotification(error.message);
        } finally {
          turnOffSaveButtonLoadState();
        }
      }
    });
  };

  return (
    <div>
      {props.rateType === 'hourly' ? (
        <>
          {props.advisor.hourlyRate > 0 ? (
            <div>
              <span className="rateLabel">${Math.round(props.advisor.hourlyRate).toLocaleString('en-US')}</span>
              <span className="results-product"> / one hour</span>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          {props.advisor.thePlanRate > 0 ? (
            <div>
              <span className="rateLabel">${Math.round(props.advisor.thePlanRate).toLocaleString('en-US')}</span>
              <span className="results-product"> / 8 weeks</span>
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      {props.rateType === 'hourly' && !props.advisor.hourlyRate ? <p>Not Set - Click Edit Below</p> : <></>}

      {props.rateType === 'the-plan' && !props.advisor.thePlanRate ? <p>Not Set - Click Edit Below</p> : <></>}

      <Alert color="danger" isOpen={rateChangedAlert} toggle={() => setRateChangedAlert(false)}>
        <p>
          <strong>Important! Action Required!</strong> Changes here <strong>do not automatically</strong> update the amount charged by
          Calendly. To complete this change, please visit your{' '}
          <a href="https://calendly.com/event_types/user/me" target="_blank" rel="noopener noreferrer">
            Calendly Event types page
          </a>{' '}
          then:
        </p>
        <ol>
          <li>Click on the Nectarine Advisor Meeting event</li>
          <li>Click "Booking page options" on the left</li>
          <li>Edit the "Amount to be collected" to match the number shown above</li>
          <li>Click "Save and close"</li>
        </ol>
        <p>
          Failing to do this will result in clients paying a different amount than what they agreed to in their contract and what is
          advertised on the site.
        </p>
      </Alert>
      <p>
        <NectarineButton type="blue" text={`Edit ${getRateDisplayName()} Rate`} onClick={editRateClick} />
      </p>
    </div>
  );
};

export default AdvisorEditRate;
