import { format, parse, startOfDay } from 'date-fns';
import { sum } from 'lodash';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { AdminEventStatuses, ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import GeneralHelper from '../../../Helpers/GeneralHelper';
import { useConfirmationOverlayContext } from '../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineCheckboxInput from '../../../ui/NectarineCheckboxInput/NectarineCheckboxInput';
import NectarineDateInput from '../../../ui/NectarineDateInput/NectarineDateInput';
import NectarineSelectInput from '../../../ui/NectarineSelectInput/NectarineSelectInput';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import { useNotificationsPanelContext } from '../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

interface AdminPaymentsProps {
  userType: 'advisor' | 'affiliate';
  payPeriods: Admin_PayPeriod_ViewModel[];
}

const dateFormatString = 'yyyy-MM-dd';

const AdminPayments: React.FC<AdminPaymentsProps> = (props: AdminPaymentsProps) => {
  const [data, setData] = useState<Admin_UserPaymentDashboard_ViewModel | null>(null);
  const [filteredEvents, setFilteredEvents] = useState<Admin_Event_ViewModel[] | null>(null);
  const [user, setUser] = useState<Admin_UserPayPeriodSummary_ViewModel | null>(null);
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<string | null>(null);
  const [newPayment, setNewPayment] = useState<Admin_PostNewUserPayment_EditModel | null>(null);

  const [isMakingPaymentOnServer, setIsMakingPaymentOnServer] = useState<boolean>(false);

  const userTypeDisplayName = GeneralHelper.capitalizeFirstLetterOfWord(props.userType);

  const { showSuccessNotification, showErrorNotification, showInfoNotification } = useNotificationsPanelContext();

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const searchPayments = async (payPeriod: string) => {
    setData(null);

    let payPeriodParam: Date | string = payPeriod;
    if (/^[\d-]+$/.test(payPeriod)) {
      payPeriodParam = parse(payPeriod, dateFormatString, new Date());
    }

    const data = await AdminApiService.getUserPayments(props.userType, payPeriodParam);

    setData(data);
    setFilteredEvents(data.events);
  };

  const filter = (userId: string) => {
    const newFilteredEvents = data!.events.filter((event) => {
      if (props.userType === 'advisor') {
        return event.advisorId === userId;
      } else {
        return event.affiliateId === userId;
      }
    });

    setFilteredEvents(newFilteredEvents);
    return false;
  };

  const logPaymentMode = (userPayPeriodSummary: Admin_UserPayPeriodSummary_ViewModel) => {
    filter(userPayPeriodSummary.userId);
    setUser(userPayPeriodSummary);

    setSelectedEvents([]);

    setNewPayment({
      paidAt: startOfDay(new Date()),
      eventIds: [],
      isManuallyLoggingPayment: false,
      isSendingFunds: false
    });
  };

  const makePayment = (isManual: boolean) => {
    if (!selectedEvents || selectedEvents.length == 0) {
      showInfoNotification('Please select at least one event to log a payment.');
      return false;
    }

    const newPaymentObj: Admin_PostNewUserPayment_EditModel = {
      ...newPayment!,
      eventIds: selectedEvents,
      isManuallyLoggingPayment: false,
      isSendingFunds: false
    };

    if (isManual) {
      newPaymentObj.isManuallyLoggingPayment = true;
    } else {
      newPaymentObj.isSendingFunds = true;
    }

    showConfirmationOverlay({
      title: isManual ? 'Confirm Log Payment' : 'Confirm Making Payment',
      text: isManual
        ? `Are you sure you want to log a manual payment to ${user!.userFullName} for ${selectedEvents.length} event${selectedEvents.length === 1 ? '' : 's'} totaling $${getPaymentAmount()}?`
        : `Are you sure you want to make a payment through Stripe to ${user!.userFullName} for ${selectedEvents.length} event${selectedEvents.length === 1 ? '' : 's'} totaling $${getPaymentAmount()}?`,
      yesButtonText: isManual ? 'Log payment manually' : 'Make payment now',
      noButtonText: 'Not yet',
      yesAction: async () => {
        try {
          setIsMakingPaymentOnServer(true);

          if (props.userType === 'advisor') {
            await AdminApiService.postNewAdvisorPayment(user!.userId, newPaymentObj);
          } else {
            await AdminApiService.postNewAffiliatePayment(user!.userId, newPaymentObj);
          }

          setUser(null);
          searchPayments(selectedPayPeriod!);

          if (isManual) {
            showSuccessNotification(`The payment to '${user!.userFullName}' was logged successfully.`);
          } else {
            showSuccessNotification(`The payment of $${getPaymentAmount()} to '${user!.userFullName}' was transferred successfully.`);
          }
        } catch (error) {
          if (isManual) {
            showErrorNotification(`An error occurred while logging the payment to '${user!.userFullName}'.`);
          } else {
            showErrorNotification(`An error occurred while making the payment to '${user!.userFullName}'.`);
          }
        } finally {
          setIsMakingPaymentOnServer(false);
        }
      }
    });
  };

  const getPaymentAmount = (): string | null => {
    return filteredEvents
      ? sum(
          filteredEvents
            .filter((event) => selectedEvents.includes(event.id))
            .map((event) => {
              if (props.userType === 'advisor') {
                return event.advisorOwedAmount;
              } else {
                return event.affiliateOwedAmount;
              }
            })
        ).toLocaleString()
      : null;
  };

  return (
    <Container>
      <Helmet>
        <title>
          {AppSettings.ApplicationName} - {userTypeDisplayName} Payments
        </title>
      </Helmet>
      <Row>
        <Col>
          {!user ? (
            <NectarineSelectInput
              display="inline-block"
              items={[
                {
                  name: 'Choose a Pay Period',
                  value: ''
                },
                {
                  name: 'Unsubmitted Notes',
                  value: 'unsubmitted-notes'
                },
                ...props.payPeriods.map((v) => ({
                  name: `${DateHelper.shortDateFormat(v.start)} - ${DateHelper.shortDateFormat(v.end)}`,
                  value: format(v.start, dateFormatString)
                }))
              ]}
              style="rounded"
              value={selectedPayPeriod}
              onChange={(newVal) => {
                setSelectedPayPeriod(newVal as string);
                searchPayments(newVal as string);
              }}
            />
          ) : (
            <></>
          )}
          {user ? (
            <div>
              <h2>Log a Payment For {user.userFullName}</h2>
              <p>
                Select the events below to log a payment for {user.userFullName}. The total number of events and amount owed will appear
                below. Once that is correct, click "Log Payment" to mark all of those events as paid and create a record of the payment. For
                now, the actual payment will still need to be sent outside of Nectarine.
              </p>
              <p>
                <strong>Events Selected:</strong> {selectedEvents.length}
              </p>
              <p>
                <strong>Payment Amount:</strong> ${getPaymentAmount()}
              </p>
              <p>
                <NectarineDateInput
                  label="Payment Date:"
                  value={newPayment?.paidAt ?? null}
                  onChange={(newVal) => {
                    setNewPayment((v) => ({
                      ...v!,
                      paidAt: newVal!
                    }));
                  }}
                />
              </p>

              <div className="d-flex gap-3">
                <NectarineButton
                  type="white"
                  text="Cancel"
                  onClick={() => {
                    setUser(null);
                    setFilteredEvents(data!.events);
                  }}
                />

                <NectarineButton
                  type="blue"
                  text="Log payment manually"
                  showLoadingDots={isMakingPaymentOnServer}
                  isDisabled={!(selectedEvents?.length ?? 0) || isMakingPaymentOnServer}
                  onClick={() => {
                    makePayment(true);
                  }}
                  onDisabledClickFn={() => {
                    showInfoNotification('Please select at least one event to manually log a payment.');
                  }}
                  disabledTooltipText="Please select at least one event to manually log a payment."
                />

                {props.userType === 'advisor' ? (
                  <NectarineButton
                    type="blue"
                    text="Make payment now"
                    showLoadingDots={isMakingPaymentOnServer}
                    isDisabled={!(selectedEvents?.length ?? 0) || isMakingPaymentOnServer}
                    onClick={() => {
                      makePayment(false);
                    }}
                    onDisabledClickFn={() => {
                      showInfoNotification('Please select at least one event to make a payment.');
                    }}
                    disabledTooltipText="Please select at least one event to make a payment."
                  />
                ) : (
                  <></>
                )}
              </div>
              <hr />
            </div>
          ) : (
            <></>
          )}

          {selectedPayPeriod && !data && <LoadingSpinner message="Loading..." />}

          {data && !data.userPaymentDetails ? <div>No data.</div> : <></>}

          {data && data.userPaymentDetails && !user ? (
            <div className="mt-3">
              <h2>Payment by {userTypeDisplayName} Summary</h2>
              <p>
                Found <strong>{data.userPaymentDetails.length}</strong> {props.userType}s. Total Owed:{' '}
                <strong>${data.userPaymentDetails.reduce((sum, user) => sum + user.totalOwedAmount, 0).toLocaleString()}</strong>
              </p>

              <NectarineTable<Admin_UserPayPeriodSummary_ViewModel>
                data={data.userPaymentDetails ?? []}
                searchFn={(item, searchText) => {
                  const regex = new RegExp(searchText, 'gi');
                  const dataToSearch: string[] = [item.userFullName].filter((v) => v);

                  return dataToSearch.some((v) => regex.test(v));
                }}
                rowDataTemplate={(item) => {
                  return (
                    <>
                      <NectarineTableDataColumn>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.userId}/edit`}>{item.userFullName}</Link>
                        {!item.userFullName ? <span>{item.userEmail}</span> : <></>}
                        {item.userId ? <AdminImpersonateLink userId={item.userId} /> : <></>}
                      </NectarineTableDataColumn>

                      <NectarineTableDataColumn alignment="right">
                        {GeneralHelper.formatNumberAsCurrency(item.totalPaidAmount)}
                      </NectarineTableDataColumn>

                      <NectarineTableDataColumn alignment="right">{item.eventsPaid}</NectarineTableDataColumn>

                      <NectarineTableDataColumn alignment="right">
                        {GeneralHelper.formatNumberAsCurrency(item.totalOwedAmount)}
                      </NectarineTableDataColumn>

                      <NectarineTableDataColumn alignment="right">{item.eventsOwed}</NectarineTableDataColumn>

                      <NectarineTableDataColumn alignment="center">
                        {item.totalOwedAmount > 0 ? (
                          <NectarineButton
                            type="blue"
                            size="small"
                            text="Log Payment..."
                            onClick={() => {
                              logPaymentMode(item);
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </NectarineTableDataColumn>
                    </>
                  );
                }}
              >
                <NectarineTableHeaderColumns>
                  <NectarineTableHeaderColumn<Admin_UserPayPeriodSummary_ViewModel> sortFn={(item) => item.userFullName}>
                    Advisor
                  </NectarineTableHeaderColumn>

                  <NectarineTableHeaderColumn<Admin_UserPayPeriodSummary_ViewModel>
                    alignment="right"
                    sortFn={(item) => item.totalPaidAmount}
                  >
                    Total Paid
                  </NectarineTableHeaderColumn>

                  <NectarineTableHeaderColumn<Admin_UserPayPeriodSummary_ViewModel> alignment="right" sortFn={(item) => item.eventsPaid}>
                    Events Paid
                  </NectarineTableHeaderColumn>

                  <NectarineTableHeaderColumn<Admin_UserPayPeriodSummary_ViewModel>
                    alignment="right"
                    sortFn={(item) => item.totalOwedAmount}
                  >
                    Total Owed
                  </NectarineTableHeaderColumn>

                  <NectarineTableHeaderColumn<Admin_UserPayPeriodSummary_ViewModel> alignment="right" sortFn={(item) => item.eventsOwed}>
                    Events Owed
                  </NectarineTableHeaderColumn>

                  <NectarineTableHeaderColumn<Admin_UserPayPeriodSummary_ViewModel> alignment="center">Actions</NectarineTableHeaderColumn>
                </NectarineTableHeaderColumns>
              </NectarineTable>
            </div>
          ) : (
            <></>
          )}

          {filteredEvents ? (
            <div>
              <h2>Events Details</h2>
              <p>
                Showing <strong>{GeneralHelper.formatNumberWithCommas(filteredEvents.length)}</strong> events. Total Owed:{' '}
                <strong>
                  $
                  {sum(
                    filteredEvents.map((currEvent) => {
                      if (currEvent.paymentSuccessful && currEvent.status === AdminEventStatuses.Active) {
                        return props.userType === 'advisor' ? currEvent.advisorOwedAmount : currEvent.affiliateOwedAmount;
                      }

                      return 0;
                    })
                  ).toLocaleString()}
                </strong>
              </p>

              {/* Select all checkboxes link */}
              {user && (
                <p>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedEvents(
                        filteredEvents
                          .filter((currEvent) => {
                            if (props.userType === 'advisor') {
                              return currEvent.advisorPaid === false;
                            } else {
                              return currEvent.affiliatePaid === false;
                            }
                          })
                          .map((v) => v.id)
                      );
                    }}
                  >
                    Select All
                  </a>
                  {' - '}
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedEvents([]);
                    }}
                  >
                    Select None
                  </a>
                </p>
              )}

              <table className="table">
                <thead>
                  <tr>
                    {user && <th></th>}
                    <th>{userTypeDisplayName}</th>
                    <th>Consumer</th>
                    <th>Start Time</th>
                    <th>Notes Sent</th>
                    <th>Status</th>
                    <th>Client Paid</th>
                    <th>{userTypeDisplayName} Paid</th>
                    <th>{userTypeDisplayName} Owed</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEvents.map((event) => (
                    <tr key={event.id}>
                      {user ? (
                        <td>
                          <NectarineCheckboxInput
                            labelDisplayType="inline"
                            checked={selectedEvents.includes(event.id)}
                            onChange={(v) => {
                              if (v) {
                                setSelectedEvents((prevSelected) => [...prevSelected, event.id]);
                              } else {
                                setSelectedEvents((prevSelected) => prevSelected.filter((state) => state !== event.id));
                              }
                            }}
                          />
                        </td>
                      ) : (
                        <></>
                      )}
                      <td>
                        <Link
                          to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${props.userType === 'advisor' ? event.advisorId : event.affiliateId}/edit`}
                        >
                          {props.userType === 'advisor' ? event.advisorFullName : event.affiliateFullName}
                        </Link>

                        {!(props.userType === 'advisor' ? event.advisorFullName : event.affiliateFullName) ? (
                          <span>{props.userType === 'advisor' ? event.advisorEmail : event.affiliateEmail}</span>
                        ) : (
                          <></>
                        )}

                        {props.userType === 'advisor' && event.advisorId ? <AdminImpersonateLink userId={event.advisorId} /> : <></>}
                        {props.userType === 'affiliate' && event.affiliateId ? <AdminImpersonateLink userId={event.affiliateId} /> : <></>}
                      </td>

                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.consumerId}/edit`}>{event.consumerFullName}</Link>
                        {!event.consumerFullName && <span>{event.consumerEmail}</span>}
                        {event.consumerId && <AdminImpersonateLink userId={event.consumerId} />}{' '}
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.consumerId}/report`}>
                          <i className="fa-duotone fa-file-invoice"></i>
                        </Link>
                      </td>
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Events_Full}/${event.id}/edit`}>
                          {DateHelper.mediumDateFormat(event.startTime)}
                        </Link>
                      </td>
                      <td>{DateHelper.mediumDateFormat(event.notesSentAt)}</td>
                      <td>{event.status}</td>
                      <td>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: event.paymentCurrency ? event.paymentCurrency : 'USD',
                          maximumFractionDigits: 0
                        }).format(event.paymentAmount)}
                        {!event.paymentSuccessful && event.paymentAmount > 0 && (
                          <span className="text-danger">
                            {' '}
                            <strong>FAILED!</strong>
                          </span>
                        )}
                      </td>
                      <td>
                        $
                        {props.userType === 'advisor'
                          ? event.advisorPaidAmount.toLocaleString()
                          : event.affiliatePaidAmount.toLocaleString()}
                      </td>
                      <td>
                        $
                        {props.userType === 'advisor'
                          ? event.advisorOwedAmount.toLocaleString()
                          : event.affiliateOwedAmount.toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminPayments;
