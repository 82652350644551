import React, { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup, Form, Input, Label } from 'reactstrap';
import ReviewsApiService from '../../services/api/ReviewsApiService';
import AdvisorsHelper from '../Helpers/AdvisorsHelper';
import StateHelper from '../Helpers/StateHelper';
import LoadingDots from './../ui/LoadingAnimations/LoadingDots/LoadingDots';

interface ReviewFormProps {
  consumer: User_BasicDetails_ViewModel;
  advisor: User_BasicDetails_ViewModel;
  review: Reviews_Review_EditModel;
  isNonAuthenticatedReview: boolean;
  onCancel?: () => void;
  onSuccessfulSave: () => void;
}

const starRatingOptions = Array.from({ length: 5 }, (v, i) => i + 1);
const numericRatingOptions = Array.from({ length: 11 }, (v, i) => i);
const maxTitleLength = 50;
const maxBodyLength = 5000;

export const ReviewForm: React.FC<ReviewFormProps> = (props: ReviewFormProps) => {
  const [review, setReview] = useState<Reviews_Review_EditModel>(props.review);
  const [errorSection, setErrorSection] = useState<ErrorMessageContainer>({ show: false, text: null });
  const [isSaving, setIsSaving] = useState(false);
  const [displayNameOptions, setDisplayNameOptions] = useState<string[]>([]);

  useEffect(() => {
    setDisplayNameOptions([
      props.consumer.fullName,
      props.consumer.firstName + ' ' + props.consumer.lastName.charAt(0) + '.',
      'Anonymous',
      `Anonymous from ${StateHelper.getStateNameFromAbbreviation(props.consumer.state)}`
    ]);
  }, [props.consumer]);

  async function onSaveReviewClick(e: React.FormEvent) {
    e.preventDefault();

    setIsSaving(true);

    if (!review.rating || review.rating <= 0 || review.rating > 5) {
      setIsSaving(false);
      showError('Please click the stars to choose a rating.');
      return;
    }

    const reviewCopy = { ...review };

    reviewCopy.revieweeId = props.advisor.id;

    // Clean up the data
    reviewCopy.title = (reviewCopy.title || '').trim();
    reviewCopy.body = (reviewCopy.body || '').trim();
    reviewCopy.privateComments = (reviewCopy.privateComments || '').trim();

    try {
      await ReviewsApiService.saveReview(reviewCopy, props.isNonAuthenticatedReview);

      setErrorSection({ show: false, text: null });
      props.onSuccessfulSave();
    } catch (error) {
      showError('Something unexpected went wrong! Please contact us if this keeps happening.');
      console.error('Unable to save review.', error);
    } finally {
      setIsSaving(false);
    }
  }

  const showError = (text: string) => {
    setErrorSection({
      show: true,
      text
    });
  };

  function onReviewRatingButtonClicked(
    value: number,
    inputElement: 'rating' | 'coverageRating' | 'competenceRating' | 'recapRating' | 'recommendationRating'
  ) {
    const reviewPropertyName = inputElement;

    setReview((currReview) => ({
      ...currReview,
      [reviewPropertyName]: value
    }));
  }

  function onInputElementChanged(e: React.ChangeEvent, inputElement: 'title' | 'body' | 'displayName' | 'privateComments') {
    const targetAsInputElement = e.target as HTMLInputElement;
    const newValue = targetAsInputElement.value ?? '';

    const reviewPropertyName = inputElement;

    let newTitleValue = '';
    if (inputElement === 'body') {
      // If the title is empty or is in sync with the body, keep it in sync with the body text.
      if (!review.title || (review.body && review.body.slice(0, maxTitleLength - 3) === review.title.slice(0, maxTitleLength - 3))) {
        if (newTitleValue.length >= maxTitleLength - 3) {
          newTitleValue = newValue.slice(0, maxTitleLength - 3) + '...';
        } else {
          newTitleValue = newValue;
        }
      }

      if (newTitleValue.length >= maxTitleLength) {
        newTitleValue = newValue.slice(0, maxTitleLength - 3) + '...';
      }
    }

    setReview((currReview) => ({
      ...currReview,
      // NOTE: The title property must come before whatever property we're setting with reviewPropertyName,
      // so title updates don't get overwritten.
      title: newTitleValue || currReview.title,
      [reviewPropertyName]: newValue
    }));
  }

  return (
    <Form onSubmit={onSaveReviewClick} className="review-form">
      <h1>Write a Review</h1>
      <img
        src={props.advisor.profilePicUrl}
        className="circle-image small-headshot"
        alt={AdvisorsHelper.getAdvisorDisplayName(props.advisor)}
      />
      <h2 className="mt-3">How was your experience with {props.advisor.firstName + ' ' + props.advisor.lastName}?</h2>
      <ButtonGroup className="review-ratings">
        {starRatingOptions.map((v, i) => {
          return (
            <i
              key={v}
              onClick={() => onReviewRatingButtonClicked(v, 'rating')}
              className={review.rating >= v ? `fa-star fa-solid rating-${review.rating}` : 'fa-star fa-solid'}
            ></i>
          );
        })}
      </ButtonGroup>
      <h4>Tell us why</h4>
      <Input
        id="body"
        name="Body"
        type="textarea"
        rows="3"
        placeholder="Share your experience with others here..."
        value={review.body}
        required
        maxLength={maxBodyLength}
        onChange={(e) => onInputElementChanged(e, 'body')}
      />
      <h4>Title your review</h4>
      <Input
        id="title"
        name="Title"
        placeholder="Summarize your review"
        type="text"
        value={review.title}
        required
        maxLength={maxTitleLength}
        onChange={(e) => onInputElementChanged(e, 'title')}
      />
      <h4>How would you like your name to appear on this review?</h4>
      <div>
        {displayNameOptions.map((currDisplayNameOption, i) => {
          return (
            <div key={currDisplayNameOption}>
              <Input
                id={`DisplayNameRadioButtonId${i + 1}`}
                name="displayName"
                type="radio"
                required
                value={currDisplayNameOption}
                onChange={(e) => onInputElementChanged(e, 'displayName')}
                checked={review.displayName == currDisplayNameOption}
              />
              &nbsp;&nbsp;
              <Label for={`DisplayNameRadioButtonId${i + 1}`}>{currDisplayNameOption}</Label>
            </div>
          );
        })}
      </div>
      <h4 className="mt-3">How well did {props.advisor.firstName} cover what you expected during your meeting?</h4>
      <div>
        <ButtonGroup className="review-ratings">
          {starRatingOptions.map((v, i) => {
            return (
              <i
                key={v}
                onClick={() => onReviewRatingButtonClicked(v, 'coverageRating')}
                className={review.coverageRating >= v ? `fa-star fa-solid rating-${review.coverageRating}` : 'fa-star fa-solid'}
              ></i>
            );
          })}
        </ButtonGroup>
      </div>
      <h4 className="mt-3">How competent and compassionate was {props.advisor.firstName} regarding your financial situation?</h4>
      <div>
        <ButtonGroup className="review-ratings">
          {starRatingOptions.map((v, i) => {
            return (
              <i
                key={v}
                onClick={() => onReviewRatingButtonClicked(v, 'competenceRating')}
                className={review.competenceRating >= v ? `fa-star fa-solid rating-${review.competenceRating}` : 'fa-star fa-solid'}
              ></i>
            );
          })}
        </ButtonGroup>
      </div>
      <h4 className="mt-3">How valuable was the recap email you received after your meeting? </h4>
      <div>
        <ButtonGroup className="review-ratings">
          {starRatingOptions.map((v, i) => {
            return (
              <i
                key={v}
                onClick={() => onReviewRatingButtonClicked(v, 'recapRating')}
                className={review.recapRating >= v ? `fa-star fa-solid rating-${review.recapRating}` : 'fa-star fa-solid'}
              ></i>
            );
          })}
        </ButtonGroup>
      </div>
      <h4 className="mt-3">How likely are you to recommend {props.advisor.firstName} to a friend on a scale of 0 to 10? </h4>
      <ButtonGroup className="mt-1 mt-lg-0 review-numeric-ratings flex-wrap gap-1 gap-lg-2 justify-content-around justify-content-lg-start">
        {numericRatingOptions.map((v, i) => {
          return (
            <React.Fragment key={v}>
              <span
                className={`${v === review.recommendationRating ? 'isSelected' : ''}`}
                onClick={() => onReviewRatingButtonClicked(v, 'recommendationRating')}
              >
                {v}
              </span>
              {i === 4 ? <div className="flex-break mt-3 d-lg-none"></div> : <></>}
            </React.Fragment>
          );
        })}
      </ButtonGroup>
      {/* Add a good bit of extra space because we use pseudo-elements to display text under the  */}
      <div className="mt-5"></div>
      &nbsp;
      <div className="mt-3"></div>
      <h4>
        Private Feedback <span className="c-gray">(optional)</span>
      </h4>
      <Input
        id="privateComments"
        name="privateComments"
        placeholder="Enter any feedback here that you would only like shown to the advisor."
        type="textarea"
        rows="3"
        value={review.privateComments}
        maxLength={maxBodyLength}
        onChange={(e) => onInputElementChanged(e, 'privateComments')}
      />
      <div className="mt-3"></div>
      {errorSection.show ? (
        <Alert color="danger">
          <strong>Uh oh!</strong> {errorSection.text}
        </Alert>
      ) : (
        <></>
      )}
      <div className="mt-5 d-flex">
        {props.onCancel ? (
          <Button color="light" size="lg" onClick={() => props.onCancel!()}>
            Cancel
          </Button>
        ) : (
          <></>
        )}

        <div className="flex-grow-1 text-right"></div>
        <Button color="primary" size="lg" className="float-end" disabled={isSaving}>
          {isSaving ? <LoadingDots /> : 'Submit'}
        </Button>
      </div>
    </Form>
  );
};
