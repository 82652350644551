import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

export const AdminUserAgreements: React.FC = () => {
  const [userAgreements, setUserAgreements] = useState<User_UserAgreement_ViewModel[] | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getRecentUserAgreements();
      setUserAgreements(result);
    };

    loadData();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>Admin / UserAgreements</title>
      </Helmet>

      <h1>User Agreements</h1>
      <p>
        Showing most recent <strong>100</strong> User Agrements.
      </p>

      {!userAgreements ? <LoadingSpinner message="Loading..." /> : <></>}

      {userAgreements ? (
        <NectarineTable<User_UserAgreement_ViewModel>
          maxHeight="50vh"
          data={userAgreements ?? []}
          searchPlaceholderText="Search User Agreements"
          searchFn={(item, searchText) => {
            const regex = new RegExp(searchText, 'gi');
            const dataToSearch: string[] = [
              item.firstName,
              item.lastName,
              item.emailAddress,
              item.advisorFirstName,
              item.advisorLastName,
              item.ipAddress,
              item.complianceDocuments.map((v) => v.displayName).join(' '),
              DateHelper.mediumDateFormat(item.agreedAtTime)
            ];

            return dataToSearch.some((v) => regex.test(v));
          }}
          rowDataTemplate={(item) => {
            return (
              <>
                <NectarineTableDataColumn>
                  <div>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.consumerId}/edit`}>
                      {item.firstName} {item.lastName}
                    </Link>{' '}
                    <AdminImpersonateLink userId={item.consumerId} />
                  </div>
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.consumerId}/edit`}>{item.emailAddress}</Link>
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.stateAbbreviation}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.ipAddress}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{DateHelper.mediumDateFormat(item.agreedAtTime)}</NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  {item.advisorId ? (
                    <div>
                      <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.advisorId}/edit`}>
                        {item.advisorFirstName} {item.advisorLastName}
                      </Link>{' '}
                      <AdminImpersonateLink userId={item.advisorId} />
                    </div>
                  ) : (
                    <></>
                  )}
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  <div>
                    {item.complianceDocuments.map((doc) => (
                      <span key={doc.id}>
                        <a href={doc.url} target="_blank" rel="noopener noreferrer">
                          {doc.displayName}
                        </a>
                        <span>, </span>
                      </span>
                    ))}
                  </div>
                </NectarineTableDataColumn>
              </>
            );
          }}
        >
          <NectarineTableHeaderColumns>
            <NectarineTableHeaderColumn<User_UserAgreement_ViewModel> sortFn={(v) => (v.firstName + v.lastName).toLowerCase()}>
              Name
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<User_UserAgreement_ViewModel> sortFn={(v) => v.emailAddress.toLowerCase()}>
              Email
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<User_UserAgreement_ViewModel> sortFn={(v) => v.stateAbbreviation.toLowerCase()}>
              State
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<User_UserAgreement_ViewModel> sortFn={(v) => v.ipAddress.toLowerCase()}>
              IP Address
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<User_UserAgreement_ViewModel> sortFn={(v) => v.agreedAtTime}>Timestamp</NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<User_UserAgreement_ViewModel>
              sortFn={(v) => (v.advisorFirstName + v.advisorLastName).toLowerCase()}
            >
              Advisor
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<User_UserAgreement_ViewModel>>Documents</NectarineTableHeaderColumn>
          </NectarineTableHeaderColumns>
        </NectarineTable>
      ) : (
        <></>
      )}
    </Container>
  );
};
