import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ApplicationRoutes } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';

const AdminRoles: React.FC = () => {
  const [roles, setRoles] = useState<Admin_UserRole_ViewModel[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getAllUserRoles();
      setRoles(result);
    };
    loadData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Admin / Roles</title>
      </Helmet>

      <h2>Roles</h2>

      <NectarineButton
        text="New Role"
        onClick={() => {
          navigate(`/${ApplicationRoutes.AdminRoutes.RolesRoutes.Create_Full}`);
        }}
      />

      <div className="mt-3"></div>

      <ul>
        {roles.map((role) => (
          <li key={role.id}>
            <a href={`/admin/roles/${role.id}/edit`}>{role.name}</a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default AdminRoles;
