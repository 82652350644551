import DOMPurify from 'dompurify';
import React, { useEffect, useRef, useState } from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStrikeThrough,
  ContentEditableEvent,
  Editor,
  EditorProvider,
  HtmlButton,
  Separator,
  Toolbar
} from 'react-simple-wysiwyg';
import { Alert, Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';
import { LocalStorageKeys } from '../../../../../constants';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import { useGeneralContext } from '../../../../../services/GeneralContext';
import DateHelper from '../../../../Helpers/DateHelper';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import { useConfirmationOverlayContext } from '../../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingDots from '../../../../ui/LoadingAnimations/LoadingDots/LoadingDots';

interface EnterMeetingNotesModalProps {
  show: boolean;
  toggle: () => void;
  save: () => void;
  event: Advisor_Event_ViewModel_Base;
}

const EnterMeetingNotesModal: React.FC<EnterMeetingNotesModalProps> = (props: EnterMeetingNotesModalProps) => {
  const [eventToEdit, setEventToEdit] = useState<Advisor_Event_EditModel>({
    id: '',
    privateNotes: '',
    publicNotes: '',
    lastUpdated: new Date()
  });

  const eventToEditRef = useRef<Advisor_Event_EditModel>(eventToEdit);

  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const { getCachedData, setCachedData, clearCachedData } = useGeneralContext();

  const meetingNotesDraftStorageKey = `${LocalStorageKeys.Advisor.MeetingNotes}-${props.event.id}`;

  useEffect(() => {
    eventToEditRef.current = eventToEdit;
    // NOTE: We need this ref kept up to date with the eventToEdit state variable, so we can use it inside
    // a setInterval without running into stale values (like if we try to reference eventToEdit -- yay, React!)
  }, [eventToEdit]);

  useEffect(() => {
    const loadData = async () => {
      try {
        // See if we have any notes drafts on the server, and check local storage if not.
        // Checking the server first will let us sync things between different machines or browsers,
        // since local storage can't.
        const result = await AdvisorsApiService.getMeetingNotesDraft(props.event.id);

        if (result.publicNotes || result.privateNotes) {
          setEventToEdit({
            id: props.event.id,
            lastUpdated: result.lastUpdated,
            publicNotes: result.publicNotes,
            privateNotes: result.privateNotes
          });
        } else {
          const previouslyEditedEventData = getStoredEvent(meetingNotesDraftStorageKey);

          if (previouslyEditedEventData) {
            setEventToEdit({
              id: props.event.id,
              lastUpdated: previouslyEditedEventData.lastUpdated,
              publicNotes: previouslyEditedEventData.publicNotes,
              privateNotes: previouslyEditedEventData.privateNotes
            });
          } else {
            setEventToEdit({
              id: props.event.id,
              lastUpdated: props.event.notesUpdated,
              publicNotes: props.event.publicNotes,
              privateNotes: props.event.privateNotes
            });
          }
        }
      } catch (error) {
        // Nothing to do here, it's not a showstopper
      }
    };

    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Every so often, we auto save notes, because we're cool like that
    const setIntervalReference = setInterval(async () => {
      try {
        const dataToSave = getNotesDraftDataToSave(eventToEditRef.current);

        await AdvisorsApiService.saveMeetingNotes(dataToSave, false);
      } catch (error) {
        // Nothing we can do here
      }
    }, 20_000);

    return () => {
      clearInterval(setIntervalReference);
    };
  }, []);

  function getStoredEvent(id: string): Advisor_Event_EditModel | null {
    return (getCachedData(id, true) as Advisor_Event_EditModel) ?? null;
  }

  function saveEventToLocal(newEventData: Advisor_Event_EditModel) {
    setCachedData(meetingNotesDraftStorageKey, true, { ...newEventData });
  }

  function clearCachedEventEditData() {
    clearCachedData(meetingNotesDraftStorageKey);
  }

  function stripStyles(html: string) {
    html = addTargetBlankToLinks(html);

    // Allow only a super restricted set of tags and attributes
    return sanitizeHtml(html, {
      allowedTags: ['b', 'i', 'u', 'em', 'strong', 'a', 'ul', 'ol', 'li', 'br', 'div', 'strike'],
      allowedAttributes: {
        a: ['href', 'target']
      }
    });
  }

  function addTargetBlankToLinks(html: string) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const anchorElements = tempDiv.querySelectorAll('a');

    anchorElements.forEach((anchor) => {
      if (!anchor.hasAttribute('target')) {
        anchor.setAttribute('target', '_blank');
      }
    });

    return tempDiv.innerHTML;
  }

  function previewMode() {
    // Strip styles from the notes strings
    const newVal: Advisor_Event_EditModel = {
      ...eventToEdit,
      privateNotes: stripStyles(eventToEdit.privateNotes),
      publicNotes: stripStyles(eventToEdit.publicNotes)
    };

    setEventToEdit(newVal);

    setIsPreviewMode(true);
  }

  const formChangeUpdateLocalStorageTimeout = useRef<NodeJS.Timeout | null>(null);

  function onFormChange(e: ContentEditableEvent, propertyName: keyof Advisor_Event_EditModel) {
    hideError();

    const newEventData: Advisor_Event_EditModel = {
      ...eventToEdit,
      [propertyName]: e.target.value
    };

    setEventToEdit(newEventData);

    // Wait at least 2 seconds until after the user finishes typing before we save the
    // tentative meeting notes
    if (formChangeUpdateLocalStorageTimeout.current) {
      clearTimeout(formChangeUpdateLocalStorageTimeout.current);
    }

    formChangeUpdateLocalStorageTimeout.current = setTimeout(() => {
      saveEventToLocal(newEventData);
    }, 2_000);
  }

  const getNotesDraftDataToSave = (eventDataToSave: Advisor_Event_EditModel): Advisor_Event_EditModel => {
    const dataToSave: Advisor_Event_EditModel = { ...eventDataToSave };
    dataToSave.publicNotes = (dataToSave.publicNotes ?? '').trim();
    dataToSave.privateNotes = (dataToSave.privateNotes ?? '').trim();

    // Sometimes when things are empty, we end up with a random line break character that
    // doesn't show in the UI but exists in the data, so remove that
    const lineBreakRegex = /^<br\s?\/>$/gi;

    dataToSave.publicNotes = dataToSave.publicNotes.replace(lineBreakRegex, '');
    dataToSave.privateNotes = dataToSave.privateNotes.replace(lineBreakRegex, '');

    if (!dataToSave.publicNotes.length) {
      dataToSave.publicNotes = '';
    }

    if (!dataToSave.privateNotes.length) {
      dataToSave.privateNotes = '';
    }

    return dataToSave;
  };

  const saveNotes = async (e: React.MouseEvent, type: 'save' | 'save-and-send') => {
    e.preventDefault();
    e.stopPropagation();

    const sendToServer = async () => {
      try {
        setSaveLoading(true);

        const dataToSave = getNotesDraftDataToSave(eventToEdit);

        await AdvisorsApiService.saveMeetingNotes(dataToSave, type === 'save-and-send');

        props.save();

        clearCachedEventEditData();

        closeModal();
      } catch (error) {
        showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
      } finally {
        setSaveLoading(false);
      }
    };

    if (type === 'save-and-send') {
      showConfirmationOverlay({
        text: 'Are you sure you want to save and send the email to the client?',
        yesButtonText: 'Save and Send',
        noButtonText: 'Not Yet',
        yesAction: async () => {
          await sendToServer();
        }
      });
    } else {
      await sendToServer();
    }
  };

  function showError(text: string) {
    setErrorSection({ show: true, text });
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  function closeModal() {
    setIsPreviewMode(false);
    props.toggle();
  }

  return (
    <Modal isOpen={props.show} toggle={closeModal}>
      <Form>
        <ModalHeader toggle={closeModal}>
          Meeting Notes for <strong>{props.event.consumerFullName}</strong> on{' '}
          <strong>{DateHelper.mediumDateFormat(props.event.startTime)}</strong>
        </ModalHeader>
        <ModalBody>
          {errorSection.show ? <Alert color="danger">{errorSection.text}</Alert> : <></>}

          {isPreviewMode ? (
            <div>
              <div className="private-notes">
                <h3>Private notes (not visible to client):</h3>
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eventToEdit.privateNotes) }}></div>
              </div>
              {!props.event.notesSent && (
                <div>
                  <hr />
                  <h3>Wrap-up email that will be sent to {props.event.consumerFullName}</h3>
                  <p className="email-header">
                    <strong>To:</strong> {props.event.consumerFullName} &lt;{props.event.consumerEmail}&gt;
                    <br />
                    <strong>From:</strong> Nectarine Team &lt;team@hellonectarine.com&gt;
                    <br />
                    <strong>Bcc:</strong> {props.event.advisorFullName} &lt;{props.event.advisorEmail}&gt;
                    <br />
                  </p>
                  <hr className="hr-light" />
                  <p className="email-header">
                    <strong>Subject:</strong> Your Nectarine Meeting Notes from {props.event.advisorFullName} -{' '}
                    {DateHelper.shortDateFormat(props.event.startTime)}
                    <br />
                  </p>
                  <hr className="hr-light" />
                  <p>Hi {props.event.consumerFirstName},</p>
                  <p>
                    Thanks so much for meeting with Nectarine! Below are notes and takeaways from your advisor,{' '}
                    {props.event.advisorFullName}:
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eventToEdit.publicNotes, { ADD_ATTR: ['target'] }) }}></p>
                  <hr />
                  <p>How was your meeting with {props.event.advisorFirstName}? Please take a moment to leave a review.</p>
                  <p>
                    <a className="btn btn-light">Leave a review for {props.event.advisorFirstName}</a>
                  </p>
                  <p>Do you have more questions or want to follow up with {props.event.advisorFirstName}?</p>
                  <p>
                    <a className="btn btn-light">Book another meeting with {props.event.advisorFirstName}</a>
                  </p>

                  <p>Until next time!</p>
                  <p>
                    Nectarine Team
                    <br />
                    <a href="https://hellonectarine.com">hellonectarine.com</a>
                  </p>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}

          {!isPreviewMode ? (
            <div>
              <FormGroup>
                <Label for="privateNotes">Private Notes (Not Visible to Client)</Label>
                <EditorProvider>
                  <Editor
                    id="privateNotes"
                    name="privateNotes"
                    value={eventToEdit.privateNotes}
                    containerProps={{ style: { minHeight: '200px' } }}
                    onChange={(e) => onFormChange(e, 'privateNotes')}
                    {...{
                      required: true,
                      placeholder: 'e.g. ' + props.event.consumerFirstName + ' is 35 years old and works as a teacher....'
                    }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnStrikeThrough />
                      <Separator />
                      <BtnBulletList />
                      <BtnNumberedList />
                      <Separator />
                      <BtnLink />
                      <HtmlButton />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
              </FormGroup>
              {!props.event.notesSent ? (
                <FormGroup>
                  <Label for="publicNotes">Meeting Notes (Shared With Client)</Label>
                  <EditorProvider>
                    <Editor
                      id="publicNotes"
                      name="publicNotes"
                      value={eventToEdit.publicNotes}
                      containerProps={{ style: { minHeight: '200px' } }}
                      onChange={(e) => onFormChange(e, 'publicNotes')}
                      {...{
                        required: true,
                        placeholder:
                          'e.g. It was great talking with you today, ' +
                          props.event.consumerFirstName +
                          '! Here are your next steps and resources we discussed...'
                      }}
                    >
                      <Toolbar>
                        <BtnBold />
                        <BtnItalic />
                        <BtnStrikeThrough />
                        <Separator />
                        <BtnBulletList />
                        <BtnNumberedList />
                        <Separator />
                        <BtnLink />
                        <HtmlButton />
                      </Toolbar>
                    </Editor>
                  </EditorProvider>
                </FormGroup>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </ModalBody>
        <ModalFooter>
          {!isPreviewMode ? (
            <div className="d-flex gap-3">
              <Button color="light" onClick={closeModal}>
                Cancel
              </Button>

              <Button
                color="primary"
                onClick={() => {
                  previewMode();
                  return false;
                }}
              >
                Preview Notes
              </Button>
            </div>
          ) : (
            <></>
          )}

          {isPreviewMode ? (
            <div>
              <div className="d-flex gap-3">
                {!saveLoading ? (
                  <span>
                    <Button
                      color="light"
                      onClick={() => {
                        setIsPreviewMode(false);
                        return false;
                      }}
                    >
                      Back
                    </Button>{' '}
                  </span>
                ) : (
                  <></>
                )}

                <Button
                  color="light"
                  disabled={saveLoading}
                  onClick={(e) => {
                    saveNotes(e, 'save');
                    return false;
                  }}
                >
                  {saveLoading ? <LoadingDots /> : 'Save Notes Without Sending'}
                </Button>

                {!props.event.notesSent ? (
                  <Button
                    color="primary"
                    disabled={saveLoading}
                    onClick={(e) => {
                      saveNotes(e, 'save-and-send');
                      return false;
                    }}
                  >
                    {saveLoading ? <LoadingDots /> : 'Save and Send Email To Client'}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EnterMeetingNotesModal;
