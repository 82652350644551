import React, { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineTextInput from '../../../ui/NectarineTextInput/NectarineTextInput';
import { useNotificationsPanelContext } from '../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';

const AdminCreateRole: React.FC = () => {
  const [redirectToAdminList, setRedirectToAdminList] = useState(false);

  const [newRoleName, setNewRoleName] = useState<string>('');

  const formRef = useRef<HTMLFormElement | null>(null);

  const { showErrorNotification, showSuccessNotification } = useNotificationsPanelContext();

  const onSubmit = async () => {
    try {
      await AdminApiService.createUserRole(newRoleName);

      showSuccessNotification('The user role was created successfully.');

      setRedirectToAdminList(true);
    } catch (error) {
      showErrorNotification('An error occurred while creating the user role.');
    }
  };

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.RolesRoutes.Root_Full}`} />;
  }

  return (
    <Container>
      <Helmet>
        <title>Admin / Create Roles</title>
      </Helmet>

      <h2>Create New Role</h2>

      <form ref={formRef} noValidate>
        <div className="w-25">
          <NectarineTextInput
            label="Role Name"
            placeholder="e.g. Admin, Consumer, Advisor, etc..."
            value={newRoleName}
            onChange={(newVal) => {
              setNewRoleName(newVal ?? '');
            }}
            required
            minLength={3}
            maxLength={30}
          />

          <div className="mt-3"></div>

          <NectarineButton
            text="Create New Role"
            onClick={async () => {
              const formIsValid = formRef.current?.checkValidity();
              formRef.current!.reportValidity();

              if (formIsValid) {
                await onSubmit();
              }
            }}
          />
        </div>
      </form>
    </Container>
  );
};

export default AdminCreateRole;
