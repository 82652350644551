import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useParams } from 'react-router-dom';
import { Alert, Badge, Button, Col, Container, Form, Input, Row } from 'reactstrap';
import { ApplicationRoutes, UserRoles, ValueLimits } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import { ChooseStates } from '../../../Advisor/ChooseStates';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import DateHelper from '../../../Helpers/DateHelper';
import HttpHelper from '../../../Helpers/HttpHelper';
import StateHelper from '../../../Helpers/StateHelper';
import IntakeResponsesListing from '../../../IntakeResponsesListing/IntakeResponsesListing';
import { useLoadingOverlayContext } from '../../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineSelectInput from '../../../ui/NectarineSelectInput/NectarineSelectInput';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import NectarineTextAreaInput from '../../../ui/NectarineTextAreaInput/NectarineTextAreaInput';
import NectarineTextInput from '../../../ui/NectarineTextInput/NectarineTextInput';
import NectarineToggle from '../../../ui/NectarineToggle/NectarineToggle';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';
import './../../../../styles/Admin.scss';

const AdminEditUser: React.FC = () => {
  const [redirectToAdminList, setRedirect] = useState<boolean>(false);
  const [user, setUser] = useState<Admin_UserDetails_ViewModel | null>(null);
  const [userToEdit, setUserToEdit] = useState<Admin_EditUser_EditModel | null>(null);
  const [roles, setRoles] = useState<Admin_UserRole_ViewModel[] | null>(null);
  const [userRoles, setUserRoles] = useState<string[] | null>(null);
  const [errorSection, setErrorSection] = useState<ErrorSection>({
    show: false,
    text: null
  });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [roleToAdd, setRoleToAdd] = useState<string | undefined>(undefined);

  const [sortedComplianceDocuments, setSortedComplianceDocuments] = useState<Admin_ComplianceDocument_ViewModel[]>([]);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { userID } = useParams();

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  async function loadUserData(): Promise<void> {
    const userData = await AdminApiService.getUserById(userID!);
    setUser(userData);

    setSortedComplianceDocuments(userData.complianceDocuments);

    setUserToEdit({
      bio: userData.bio,
      calendarLink: userData.calendarLink,
      certifications: userData.certifications,
      email: userData.email,
      firstName: userData.firstName,
      lastName: userData.lastName,
      id: userData.id,
      isNectarine: userData.isNectarine,
      password: '',
      slug: userData.slug,
      state: userData.state,
      title: userData.title,
      userName: userData.userName,
      stripeConnectedAccountId: userData.stripeConnectedAccountId
    });
  }

  async function loadRolesForUser(): Promise<void> {
    const userRoles = await AdminApiService.getRolesForUser(userID!);
    setUserRoles(userRoles);
  }

  useEffect(() => {
    const loadData = async () => {
      await loadUserData();

      await loadRolesForUser();

      const allRoles = await AdminApiService.getAllUserRoles();
      setRoles(allRoles);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.Users_Full}`} />;
  }

  function onFormChange(e: React.ChangeEvent<HTMLInputElement>, propertyName: keyof Admin_EditUser_EditModel) {
    hideError();

    setUserToEdit((v) => {
      return {
        ...v!,
        [propertyName]: e.target.value
      };
    });
  }

  const onIsNectarineValueChange = () => {
    setUserToEdit((a) => ({
      ...a!,
      isNectarine: !a!.isNectarine
    }));
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({
      show: false,
      text: null
    });
  }

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      setSelectedFile(file);
    }
  };

  const handleFileUpload = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // console.log('handleFileUpload formData', formData);

      const displayName = (event.target as any).elements['displayName'].value;
      const params = new URLSearchParams();
      params.append('displayName', displayName);

      const url = '/api/files/compliancedocument/' + userID + '?' + params.toString();

      const token = await AuthorizeService.getAccessToken();

      fetch(url, {
        method: 'POST',
        body: formData,
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      })
        .then(async () => {
          const user = await AdminApiService.getUserById(userID!);

          setUser(user);
          setSortedComplianceDocuments(user.complianceDocuments);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  const onSave = async () => {
    try {
      showLoadingOverlay();

      await AdminApiService.updateUser(userToEdit!);
      setRedirect(true);
    } catch (error) {
      showError(error as string);
    } finally {
      hideLoadingOverlay();
    }

    return false;
  };

  const deleteUser = async () => {
    HttpHelper.delete('/api/users/' + userID, null)
      .then(() => {
        // console.log('DELETE response', response);
      })
      .then(() => {
        // console.log('End onDelete');
        setRedirect(true);
      })
      .catch((error) => {
        console.error(error);

        alert('Unable to delete item.');
        setRedirect(false);
      });

    return false;
  };

  const onSetDocumentActive = async (event: React.MouseEvent, docID: string, showToTheHourBuyers: boolean) => {
    event.preventDefault();

    try {
      await HttpHelper.put('/api/files/compliancedocument/setshowtothehourbuyers/' + docID, showToTheHourBuyers);
    } catch (error) {
      console.error(error);
      alert('An error occurred setting the document as active.');
    } finally {
      await loadUserData();
    }
  };

  const deleteRole = async (roleToDelete: string) => {
    try {
      showLoadingOverlay();

      await AdminApiService.deleteRoleFromUser(userID!, roleToDelete);

      await loadRolesForUser();
    } catch (error) {
      console.error(error);
      alert(`Unable to delete role: ${roleToDelete}.`);
      setRedirect(false);
    } finally {
      hideLoadingOverlay();
    }
  };

  const addRole = async () => {
    try {
      showLoadingOverlay();

      await AdminApiService.addRoleToUser(userID!, roleToAdd!);

      loadRolesForUser();
    } catch (error) {
      console.log(`Unable to add role: ${roleToAdd}`);
    } finally {
      hideLoadingOverlay();
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Admin / Edit User</title>
      </Helmet>

      {errorSection.show && <Alert color="danger">An error happened! {errorSection.text}</Alert>}

      {!user ? <LoadingSpinner message="Loading..." /> : <></>}

      {userToEdit && userRoles ? (
        <Row>
          <Col md="6" sm="12" xs="12">
            <form noValidate ref={formRef}>
              <h2>
                Edit {userToEdit.userName} from {userToEdit.state}
              </h2>

              <Row>
                <Col md={12} className="mb-3">
                  <div className="d-flex gap-3 align-items-end">
                    <NectarineToggle
                      label="Is Nectarine"
                      value={userToEdit.isNectarine}
                      onChange={(newVal) => setUserToEdit((v) => ({ ...v!, isNectarine: newVal }))}
                    />
                    <i className={'fa-duotone fa-peach' + (userToEdit.isNectarine ? ' light-blue' : '')}></i>
                  </div>

                  <div className="mt-2">(When checked this advisor is operating under the Nectarine RIA)</div>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="mb-3">
                  <NectarineTextInput
                    label="First Name"
                    required
                    minLength={ValueLimits.Name_MinLength}
                    maxLength={ValueLimits.Name_MaxLength}
                    value={userToEdit.firstName}
                    onChange={(newVal) => {
                      setUserToEdit((v) => ({ ...v!, firstName: newVal! }));
                    }}
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <NectarineTextInput
                    label="Last Name"
                    required
                    minLength={ValueLimits.Name_MinLength}
                    maxLength={ValueLimits.Name_MaxLength}
                    value={userToEdit.lastName}
                    onChange={(newVal) => {
                      setUserToEdit((v) => ({ ...v!, lastName: newVal! }));
                    }}
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <NectarineTextInput
                    label="Username"
                    required
                    // NOTE: We use the email as the max length here
                    maxLength={ValueLimits.Email_MaxLength}
                    value={userToEdit.userName}
                    onChange={(newVal) => {
                      setUserToEdit((v) => ({ ...v!, userName: newVal! }));
                    }}
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <NectarineTextInput
                    type="email"
                    label="Email"
                    required
                    maxLength={ValueLimits.Email_MaxLength}
                    value={userToEdit.email}
                    onChange={(newVal) => {
                      setUserToEdit((v) => ({ ...v!, email: newVal! }));
                    }}
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <NectarineSelectInput
                    label="State"
                    value={userToEdit.state}
                    onChange={(newVal) => {
                      setUserToEdit((v) => ({ ...v!, state: newVal as string }));
                    }}
                    items={[
                      {
                        value: '',
                        name: 'Choose a state...'
                      },

                      ...StateHelper.usStatesAndTerritories.map((v) => ({
                        name: v.name,
                        value: v.abbreviation
                      }))
                    ]}
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <NectarineTextInput
                    label="Password"
                    placeholder="Leave blank to leave unchanged"
                    maxLength={ValueLimits.Password_MaxLength}
                    value={userToEdit.password}
                    onChange={(newVal) => {
                      setUserToEdit((v) => ({ ...v!, password: newVal! }));
                    }}
                  />
                </Col>
              </Row>

              {userRoles.includes(UserRoles.Advisor) || userRoles.includes(UserRoles.Affiliate) ? (
                <>
                  <div className="basicContainer mt-3">
                    <span className="containerLabel">
                      <i className="c-blue fa-duotone fa-regular fa-user"></i>

                      <span className="ms-2">{userRoles.includes(UserRoles.Advisor) ? UserRoles.Advisor : UserRoles.Affiliate} Info</span>
                    </span>

                    <Row>
                      {userRoles.includes(UserRoles.Advisor) ? (
                        <>
                          <Col md={6} className="mb-3">
                            <NectarineTextInput
                              label="Certifications"
                              placeholder="CFP, CFA"
                              value={userToEdit.certifications}
                              onChange={(newVal) => {
                                setUserToEdit((v) => ({ ...v!, certifications: newVal! }));
                              }}
                            />
                          </Col>

                          <Col md={6} className="mb-3">
                            <NectarineTextInput
                              label="Title"
                              placeholder="Financial Planner"
                              value={userToEdit.title}
                              onChange={(newVal) => {
                                setUserToEdit((v) => ({ ...v!, title: newVal! }));
                              }}
                            />
                          </Col>

                          <Col md={12} className="mb-3">
                            <NectarineTextAreaInput
                              label="Advisor Bio"
                              secondaryLabel="(Exactly three lines, one bullet per line)"
                              placeholder={'9 years experience\nEnjoys those dolla bills\nNever fakes the funk on a nasty dunk'}
                              rows={3}
                              value={userToEdit.bio}
                              onChange={(newVal) => {
                                setUserToEdit((v) => ({ ...v!, bio: newVal! }));
                              }}
                            />
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}

                      <Col md={12} className="mb-2">
                        <NectarineTextInput
                          label="URL slug"
                          value={userToEdit.slug}
                          onChange={(newVal) => {
                            setUserToEdit((v) => ({ ...v!, slug: newVal! }));
                          }}
                        />
                      </Col>
                    </Row>
                  </div>

                  {userRoles.includes(UserRoles.Advisor) ? (
                    <div className="basicContainer mt-4">
                      <span className="containerLabel">
                        <i className="c-blue fa-duotone fa-regular fa-calendar"></i>

                        <span className="ms-2">Advisor Scheduling</span>
                      </span>

                      <NectarineTextInput
                        label="Calendly ID"
                        placeholder="https://calendly.com/advisorname"
                        value={user?.calendlyID}
                        onChange={() => {}}
                        readOnly
                      />

                      <div className="mt-2"></div>

                      <NectarineTextInput
                        label="Calendly Link"
                        placeholder="https://calendly.com/advisorname"
                        value={userToEdit.calendarLink}
                        onChange={(newVal) => {
                          setUserToEdit((v) => ({ ...v!, calendarLink: newVal! }));
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="basicContainer mt-4">
                    <span className="containerLabel">
                      <i className="fa-brands fa-cc-stripe c-blue2"></i>
                    </span>

                    <NectarineTextInput
                      label="Stripe Connected Account ID"
                      value={userToEdit.stripeConnectedAccountId}
                      onChange={(newVal) => {
                        setUserToEdit((v) => ({ ...v!, stripeConnectedAccountId: newVal! }));
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="mt-4"></div>

              <NectarineButton
                text="Save"
                onClick={async () => {
                  const formIsValid = formRef.current?.checkValidity();
                  formRef.current!.reportValidity();

                  if (formIsValid) {
                    await onSave();
                  }
                }}
              />
            </form>
          </Col>
          <Col md="6" sm="12" xs="12">
            <h2>User Roles</h2>

            {!(roles && user && userRoles) && <LoadingSpinner message="Loading..." />}

            {roles && user && userRoles && (
              <div>
                {userRoles.map((role) => (
                  <Badge color="info" key={role} className="d-flex-inline align-items-end">
                    <span>{role}</span>

                    <i
                      className="fa-solid fa-xmark ms-3 pointer p-1"
                      onClick={() => {
                        deleteRole(role);
                      }}
                    ></i>
                  </Badge>
                ))}

                <div>
                  <label>Add a New Role</label>
                </div>

                <Input
                  id="roleName"
                  name="RoleName"
                  type="select"
                  value={roleToAdd}
                  onChange={(v) => {
                    setRoleToAdd(v.target.value);
                  }}
                >
                  <option value="">Choose a role...</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </Input>

                <Button
                  color="primary"
                  className="mt-4"
                  onClick={() => {
                    addRole();
                  }}
                  disabled={!roleToAdd}
                >
                  Add Role to {user.userName}
                </Button>

                {/* <Form onSubmit={onAddRole}>
                    <Input id="userID" name="UserID" type="hidden" value={user.id} />
                    <Input id="roleName" name="RoleName" type="select">
                      <option value="">Choose a role...</option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </Input>
                    <Button color="primary" className="mt-4">
                      Add Role to {user.userName}
                    </Button>
                  </Form> */}
              </div>
            )}
          </Col>

          {userRoles.includes(UserRoles.Advisor) ? (
            <Row className="mt-5">
              <h3>Advisor States</h3>

              <ChooseStates userID={userToEdit.id} />

              <div className="mt-4"></div>

              <h3>Advisor Documents</h3>

              <p>
                When uploading a new document, it should automatically become active and show the ✅. It should also make inactive all other
                documents with the same name. But if you happen to need to make an old document active or inactive (for example you're
                replacing a document with a differently named document) use can use the "Set Active" buttons.
              </p>

              {user ? (
                <NectarineTable<Admin_ComplianceDocument_ViewModel>
                  data={sortedComplianceDocuments}
                  searchFn={(item, searchText) => {
                    const searchValues: string[] = [item.displayName, item.originalFileName, DateHelper.mediumDateFormat(item.timestamp)];

                    return searchValues
                      .filter((v) => v)
                      .map((v) => (v ?? '').toLowerCase())
                      .some((v) => v.includes(searchText.toLowerCase()));
                  }}
                  rowDataTemplate={(item) => {
                    return (
                      <>
                        <NectarineTableDataColumn>{item.showToTheHourBuyers ? '✅' : '-'}</NectarineTableDataColumn>

                        <NectarineTableDataColumn>{DateHelper.mediumDateFormat(item.timestamp)}</NectarineTableDataColumn>

                        <NectarineTableDataColumn>
                          <a href={item.url} target="_blank" rel="noopener noreferrer">
                            {item.displayName}
                          </a>
                        </NectarineTableDataColumn>

                        <NectarineTableDataColumn>{item.originalFileName}</NectarineTableDataColumn>

                        <NectarineTableDataColumn>
                          {item.showToTheHourBuyers ? (
                            <a href="#" onClick={(e) => onSetDocumentActive(e, item.id, false)}>
                              Set Inactive
                            </a>
                          ) : (
                            <a href="#" onClick={(e) => onSetDocumentActive(e, item.id, true)}>
                              Set Active
                            </a>
                          )}
                        </NectarineTableDataColumn>
                      </>
                    );
                  }}
                >
                  <NectarineTableHeaderColumns>
                    <NectarineTableHeaderColumn<Admin_ComplianceDocument_ViewModel> sortFn={(item) => item.showToTheHourBuyers}>
                      Show To The Hour Buyers
                    </NectarineTableHeaderColumn>

                    <NectarineTableHeaderColumn<Admin_ComplianceDocument_ViewModel>
                      sortFn={(item) => DateHelper.mediumDateFormat(item.timestamp)}
                    >
                      Date
                    </NectarineTableHeaderColumn>

                    <NectarineTableHeaderColumn<Admin_ComplianceDocument_ViewModel> sortFn={(item) => item.displayName}>
                      Document
                    </NectarineTableHeaderColumn>

                    <NectarineTableHeaderColumn<Admin_ComplianceDocument_ViewModel> sortFn={(item) => item.originalFileName}>
                      Original Name
                    </NectarineTableHeaderColumn>

                    <NectarineTableHeaderColumn<Admin_ComplianceDocument_ViewModel>>Set Active</NectarineTableHeaderColumn>
                  </NectarineTableHeaderColumns>
                </NectarineTable>
              ) : (
                <></>
              )}

              <div className="mt-4"></div>

              <h3>Upload A New Document</h3>
              <div>
                <Form onSubmit={handleFileUpload}>
                  <Input id="displayName" name="displayName" type="select">
                    <option value="">Choose a contract...</option>
                    <option key="Contract" value="Contract">
                      Contract
                    </option>
                    <option key="ADV Part 2" value="ADV Part 2">
                      ADV Part 2
                    </option>
                    <option key="Privacy Policy" value="Privacy Policy">
                      Privacy Policy
                    </option>
                    <option key="Form CRS" value="Form CRS">
                      Form CRS
                    </option>
                  </Input>
                  <Input type="file" onChange={handleFileSelect} />
                  <Button color="primary">Upload</Button>
                </Form>
              </div>
            </Row>
          ) : (
            <></>
          )}

          <h3 className="mt-5">Events as Consumer</h3>

          <table className="table">
            <thead>
              <tr>
                <th>Advisor</th>
                <th>Created At</th>
                <th>Start Time</th>
                <th>Status</th>
                <th>Links</th>
                <th>Paid</th>
              </tr>
            </thead>
            <tbody>
              {user ? (
                user.eventsAsGuest.map((event) => (
                  <tr key={event.id}>
                    {event.advisorId ? (
                      <td>
                        <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${event.advisorId}/edit`}>{event.advisorFullName}</Link>
                        <AdminImpersonateLink userId={event.advisorId} />
                      </td>
                    ) : (
                      <></>
                    )}

                    <td>{event.advisorEmail}</td>

                    <td>{DateHelper.mediumDateFormat(event.createdAt)}</td>

                    <td>{DateHelper.mediumDateFormat(event.startTime)}</td>

                    <td>{event.status}</td>

                    <td>
                      <a href={event.location}>Zoom</a> <a href={event.rescheduleUrl}>Reschedule</a> <a href={event.cancelUrl}>Cancel</a>{' '}
                      {event.consumerId && event.advisorId ? (
                        <a href={'/leaveareview/' + event.advisorId + '/' + event.consumerId}>Review Page</a>
                      ) : (
                        <></>
                      )}
                    </td>

                    <td>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: event.paymentCurrency ? event.paymentCurrency : 'USD',
                        maximumFractionDigits: 0
                      }).format(event.paymentAmount)}
                      {!event.paymentSuccessful && event.paymentAmount > 0 && (
                        <span className="text-danger">
                          {' '}
                          <strong>FAILED!</strong>
                        </span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <></>
              )}
            </tbody>
          </table>

          <h3 className="mt-5">Intake Form Responses</h3>

          <table className="table">
            <tbody>
              {user && user.intakeResponses ? (
                user.intakeResponses.map((currIntakeResponse) => {
                  const parsedJson = currIntakeResponse.jsonData;

                  return (
                    <tr key={currIntakeResponse.id}>
                      <td>
                        <strong>
                          {new Date(currIntakeResponse.submittedAt).toDateString()} - {currIntakeResponse.email}
                        </strong>
                        <br />

                        <IntakeResponsesListing
                          clientFirstName={currIntakeResponse.firstName}
                          intakeResponseData={parsedJson}
                          intakeResponseSubmitDate={currIntakeResponse.submittedAt}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>

          <h3>Other User Details</h3>
          <table className="table">
            <tbody>
              {user &&
                Object.keys(user).map((keyName) => (
                  <tr key={keyName}>
                    <td>{keyName}</td>
                    <td>{(user as any)[keyName] ? (user as any)[keyName].toString() : '-'}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          <h3 className="mt-3">Danger Zone</h3>

          <div>
            <Button color="danger" onClick={() => deleteUser()}>
              Delete User
            </Button>
          </div>
        </Row>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default AdminEditUser;
