import { useRef, useState } from 'react';
import { Alert, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AuthorizeService from '../../../../api-authorization/AuthorizeService';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import NectarineButton from '../../../../ui/NectarineButton/NectarineButton';

interface AdvisorEditPersonalPhotoProps {
  show: boolean;
  toggle: () => void;
  save: () => void;
  advisor: Advisor_BasicDetails_ViewModel;
}

const AdvisorEditPersonalPhoto: React.FC<AdvisorEditPersonalPhotoProps> = (props: AdvisorEditPersonalPhotoProps) => {
  const [savePhotoLoading, setSavePhotoLoading] = useState(false);
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.files![0]);
    hideError();
  };

  //Post the selected file to the API at /api/advisor/photo
  const onSave = async () => {
    if (selectedFile) {
      hideError();
      setSavePhotoLoading(true);
      const formData = new FormData();
      formData.append('file', selectedFile);

      const token = await AuthorizeService.getAccessToken();

      try {
        await fetch('/api/advisor/photo', {
          method: 'POST',
          body: formData,
          headers: !token
            ? {}
            : {
                Authorization: `Bearer ${token}`
              }
        });

        setSavePhotoLoading(false);

        props.save();
      } catch (error) {
        showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
        setSavePhotoLoading(false);
      }
    }
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({ text: null, show: false });
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <form ref={formRef} noValidate>
        <ModalHeader toggle={props.toggle}>Edit Personal Photo</ModalHeader>
        <ModalBody>
          {errorSection.show && <Alert color="danger">{errorSection.text}</Alert>}
          <p>
            Choose a personal photo that highlights your personality, family, or interests. We recommend the photo be{' '}
            <strong>horizontal</strong> orientation (wide, not tall) and a maximum of <strong>1MB</strong>.
          </p>

          <Input type="file" onChange={handleFileSelect} />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end gap-2">
            <NectarineButton
              text="Save Photo"
              type="blue"
              isDisabled={savePhotoLoading}
              showLoadingDots={savePhotoLoading}
              onClick={async () => {
                const formIsValid = formRef.current?.checkValidity();
                formRef.current!.reportValidity();

                if (formIsValid) {
                  await onSave();
                }
              }}
            />
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AdvisorEditPersonalPhoto;
