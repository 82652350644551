import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { AppSettings, AttributeCategories, AttributeCategoriesText } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import ErrorsHelper from '../../../Helpers/ErrorsHelper';
import { useConfirmationOverlayContext } from '../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineSelectInput from '../../../ui/NectarineSelectInput/NectarineSelectInput';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import NectarineTextInput from '../../../ui/NectarineTextInput/NectarineTextInput';
import { useNotificationsPanelContext } from '../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';

const AdvisorAttributes: React.FC = () => {
  const [attributes, setAttributes] = useState<Admin_Attribute_ViewModel[] | null>(null);
  const [attributeToEdit, setAttributeToEdit] = useState<Admin_Attribute_EditModel | null>(null);

  const formRef = useRef<HTMLFormElement | null>(null);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  const { showErrorNotification, showSuccessNotification } = useNotificationsPanelContext();

  useEffect(() => {
    const loadData = async () => {
      const attributes = await AdminApiService.getSharedAdvisorAttributes();
      setAttributes(attributes);
    };

    loadData();
  }, []);

  const saveAttributeChanges = async () => {
    if (!attributeToEdit) {
      return;
    }

    try {
      await AdminApiService.updateAdvisorAttribute(attributeToEdit);

      // Reload things from the server
      const attributes = await AdminApiService.getSharedAdvisorAttributes();
      setAttributes(attributes);

      showSuccessNotification('The attribute was saved sucessfully.');

      setAttributeToEdit(null);
    } catch (error) {
      console.log(error);

      showErrorNotification(ErrorsHelper.getErrorMessageFromErrorObject(showErrorNotification));
    }
  };

  const deleteAttribute = async (attributeToDelete: Admin_Attribute_ViewModel) => {
    showConfirmationOverlay({
      title: 'Confirm Deletion',
      text: `Are you sure you want to delete the <b>${attributeToDelete.title}</b> attribute?`,
      text2: `This is permanent and can't be undone.`,
      noButtonText: 'Not yet',
      yesButtonText: 'Delete',
      yesAction: async () => {
        try {
          await AdminApiService.deleteAdvisorAttribute(attributeToDelete.id);

          setAttributes((v) => {
            return v!.filter((m) => m.id !== attributeToDelete.id);
          });
        } catch (error) {
          alert(error);
          console.error('Unable to delete item.', error);
        }
      }
    });
  };

  const createNewAttribute = () => {
    setAttributeToEdit({
      categoryId: 0,
      startDate: null,
      endDate: null,
      iconTag: '',
      id: '',
      slug: '',
      subtitle: '',
      title: ''
    });
  };

  const getAttributeCategoryText = (categoryId: number) => {
    switch (categoryId) {
      case AttributeCategories.ICanHelpYouWith:
        return AttributeCategoriesText.ICanHelpYouWith;

      case AttributeCategories.IEnjoyWorkingWith:
        return AttributeCategoriesText.IEnjoyWorkingWith;

      default:
        return '';
    }
  };

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Advisor Attributes</title>
      </Helmet>
      <Row>
        <Col>
          <h1>Advisor Attributes</h1>

          <p>Use this page to add, edit, or remove the default attributes that advisors can select to list on their profile.</p>
          {!attributeToEdit && (
            <p>
              <NectarineButton
                text="New Advisor Attribute"
                iconClassString="fa-solid fa-plus"
                onClick={() => {
                  createNewAttribute();
                }}
              />
            </p>
          )}

          {attributeToEdit ? (
            <form ref={formRef}>
              <div className="w-50">
                <div>
                  <NectarineSelectInput
                    label="Category"
                    placeholder="Choose a category..."
                    required
                    items={[
                      {
                        name: 'Choose a category...',
                        value: ''
                      },
                      {
                        name: AttributeCategoriesText.ICanHelpYouWith,
                        value: AttributeCategories.ICanHelpYouWith
                      },
                      {
                        name: AttributeCategoriesText.IEnjoyWorkingWith,
                        value: AttributeCategories.IEnjoyWorkingWith
                      }
                    ]}
                    value={attributeToEdit.categoryId}
                    onChange={(newVal) => {
                      setAttributeToEdit((v) => ({ ...v!, categoryId: newVal as number }));
                    }}
                  />
                </div>

                <div className="mt-3">
                  <NectarineTextInput
                    label="Title"
                    placeholder="e.g. Families with children"
                    required
                    value={attributeToEdit.title}
                    onChange={(newVal) => {
                      setAttributeToEdit((v) => ({ ...v!, title: newVal ?? '' }));
                    }}
                  />
                </div>

                <div className="mt-3">
                  <NectarineTextInput
                    label="Slug"
                    placeholder="e.g. pre-retirees"
                    value={attributeToEdit.slug}
                    onChange={(newVal) => {
                      setAttributeToEdit((v) => ({ ...v!, slug: newVal ?? '' }));
                    }}
                  />
                </div>

                <div className="mt-3">
                  <NectarineTextInput
                    label="Icon Tag"
                    placeholder="e.g. fa-lightbulb"
                    value={attributeToEdit.iconTag}
                    onChange={(newVal) => {
                      setAttributeToEdit((v) => ({ ...v!, iconTag: newVal ?? '' }));
                    }}
                  />
                </div>
              </div>

              <div className="mt-3 d-flex gap-3">
                <NectarineButton
                  text="Save"
                  onClick={async () => {
                    const formIsValid = formRef.current?.checkValidity();
                    formRef.current!.reportValidity();

                    if (formIsValid) {
                      await saveAttributeChanges();
                    }
                  }}
                />

                <NectarineButton
                  text="Cancel"
                  type="white"
                  onClick={async () => {
                    setAttributeToEdit(null);
                  }}
                />
              </div>

              <div className="mt-4"></div>
              <hr />
              <div className="mt-4"></div>
            </form>
          ) : (
            <></>
          )}

          {!attributes ? <LoadingSpinner message="Loading..." /> : <></>}

          {attributes ? (
            <NectarineTable<Admin_Attribute_ViewModel>
              data={attributes ?? []}
              searchFn={(item, searchText) => {
                const searchValues: string[] = [
                  item.title,
                  item.subtitle,
                  item.slug,
                  item.iconTag,
                  getAttributeCategoryText(item.categoryId)
                ];

                return searchValues
                  .filter((v) => v)
                  .map((v) => (v ?? '').toLowerCase())
                  .some((v) => v.includes(searchText.toLowerCase()));
              }}
              rowDataTemplate={(item) => {
                return (
                  <>
                    <NectarineTableDataColumn>{getAttributeCategoryText(item.categoryId)}</NectarineTableDataColumn>

                    <NectarineTableDataColumn>
                      <i className={'fa-light ' + item.iconTag}></i>
                    </NectarineTableDataColumn>

                    <NectarineTableDataColumn>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          setAttributeToEdit(item);
                        }}
                      >
                        {item.title}
                      </a>
                    </NectarineTableDataColumn>

                    <NectarineTableDataColumn>{item.slug}</NectarineTableDataColumn>

                    <NectarineTableDataColumn>{item.iconTag}</NectarineTableDataColumn>

                    <NectarineTableDataColumn>
                      <NectarineButton
                        type="red"
                        size="small"
                        text="Delete"
                        onClick={() => {
                          deleteAttribute(item);
                        }}
                      />
                    </NectarineTableDataColumn>
                  </>
                );
              }}
            >
              <NectarineTableHeaderColumns>
                <NectarineTableHeaderColumn<Admin_Attribute_ViewModel> sortFn={(item) => getAttributeCategoryText(item.categoryId)}>
                  Category
                </NectarineTableHeaderColumn>
                <NectarineTableHeaderColumn<Admin_Attribute_ViewModel> sortFn={(item) => item.iconTag ?? ''}>
                  Icon
                </NectarineTableHeaderColumn>
                <NectarineTableHeaderColumn<Admin_Attribute_ViewModel> sortFn={(item) => (item.title ?? '').toLowerCase()}>
                  Title
                </NectarineTableHeaderColumn>
                <NectarineTableHeaderColumn<Admin_Attribute_ViewModel> sortFn={(item) => (item.slug ?? '').toLowerCase()}>
                  Slug
                </NectarineTableHeaderColumn>
                <NectarineTableHeaderColumn<Admin_Attribute_ViewModel> sortFn={(item) => item.iconTag ?? ''}>
                  Icon Tag
                </NectarineTableHeaderColumn>
                <NectarineTableHeaderColumn<Admin_Attribute_ViewModel>>Delete</NectarineTableHeaderColumn>
              </NectarineTableHeaderColumns>
            </NectarineTable>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default AdvisorAttributes;
