import React from 'react';
import LandingPageTemplate from './LandingPageTemplate';

const LandingPage_HourlyFinancialAdvisors: React.FC = () => {
  return (
    <LandingPageTemplate
      title="Hourly Financial Advisors"
      plural="hourly financial advisors"
      singular="hourly financial advisor"
      blurb={blurb}
    />
  );
};

const blurb = (
  <div>
    <p>
      <strong>Flexibility:</strong> Pay only for the time you need. Whether you're seeking a quick financial check-up or in-depth advice,
      our hourly model adapts to your schedule and requirements.
    </p>
    <p>
      <strong>Tailored Solutions:</strong> Your financial situation is unique, and so is our approach. Nectarine advisors are here to assist
      with investment management, retirement planning, tax strategies, and more, all customized to address your specific needs.
    </p>
    <p>
      <strong>Transparent Pricing:</strong> Say goodbye to hidden fees. With our straightforward hourly rate, you know exactly what you're
      paying for. No surprises, just clear and transparent financial guidance.
    </p>
    <p>
      <strong>No Commitments:</strong> Embrace financial guidance on your terms. Our hourly model is perfect for those who prefer occasional
      advice without the commitment of a long-term relationship. It's your financial journey, and you're in control.
    </p>
  </div>
);

export default LandingPage_HourlyFinancialAdvisors;
