import { forwardRef } from 'react';
import NectarineInfoIcon from '../NectarineInfoIcon/NectarineInfoIcon';
import './NectarineNumericInput.scss';

interface NectarineNumericInputProps {
  value: number | null;
  onChange: (newValue: number | null) => void;
  isDisabled?: boolean;
  required?: boolean;
  min?: number;
  max?: number;
  placeholder?: string;
  label?: string;
  secondaryLabel?: string;
  infoIconTooltipText?: string;
}

const NectarineNumericInput = forwardRef<HTMLInputElement, NectarineNumericInputProps>((props: NectarineNumericInputProps, ref) => {
  if ((props.min !== undefined || props.max !== undefined) && !props.required) {
    console.warn(
      'The min or max is set on a NectarineNumericInput but the required attribute was false, ' +
        'which means HTML form validation will not occur for this input.' +
        (props.label ? `\nThis was for text input with label '${props.label}'.` : '')
    );
  }

  return (
    <>
      <div>
        {props.label ? (
          <div className="labelWrapper">
            <label className="bold mb-1">
              {props.label}
              {props.required ? <span className="ms-1 c-green"> *</span> : ''}
            </label>

            {props.infoIconTooltipText ? <NectarineInfoIcon tooltipText={props.infoIconTooltipText} /> : <></>}

            {props.secondaryLabel ? <span className="ms-1">{props.secondaryLabel}</span> : <></>}
          </div>
        ) : (
          <></>
        )}

        <div className="numericInputWrapper">
          <div className="inputIconContainer">
            <i className="fa-solid fa-dollar-sign"></i>
          </div>

          <input
            ref={ref}
            className="form-control"
            type="number"
            value={props.value ?? ''}
            onChange={(e) => {
              e.preventDefault();
              e.stopPropagation();

              if (props.isDisabled) {
                return;
              }

              const parsedVal = parseFloat(e.target.value);

              props.onChange(isNaN(parsedVal) ? null : parsedVal);
            }}
            onBlur={() => {
              if (props.min !== undefined && (props.value ?? 0) < props.min) {
                props.onChange(props.min);
                return;
              }

              if (props.max !== undefined && (props.value ?? 0) > props.max) {
                props.onChange(props.max);
                return;
              }
            }}
            placeholder={props.placeholder}
            required={props.required}
            disabled={props.isDisabled}
            min={props.min}
            max={props.max}
          />

          {!props.isDisabled && props.value !== null ? (
            <span
              className="clearIcon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                props.onChange(null);
              }}
            >
              <span className="fa-solid fa-times"></span>
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
});

NectarineNumericInput.displayName = 'NectarineNumericInput';

export default NectarineNumericInput;
