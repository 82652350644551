import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';
import { AccordionBody, AccordionHeader, AccordionItem, Button, Col, Container, Row, UncontrolledAccordion } from 'reactstrap';
import { ApplicationRoutes, AppSettings, RecordingStatus } from '../../../../../constants';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import RecordingsApiService from '../../../../../services/api/RecordingsApiService';
import DateHelper from '../../../../Helpers/DateHelper';
import StateHelper from '../../../../Helpers/StateHelper';
import IntakeResponsesListing from '../../../../IntakeResponsesListing/IntakeResponsesListing';
import LoadingSpinner from '../../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import ZoomMeetingSummary from '../../../../ZoomMeetingSummary/ZoomMeetingSummary';
import EnterMeetingNotesModal from '../EnterMeetingNotesModal/EnterMeetingNotesModal';

const AdvisorAccountClient = () => {
  const { clientID } = useParams();
  const [client, setClient] = useState<Advisor_ClientDetails_ViewModel | null>(null);
  const [showEnterNotesModal, setShowEnterNotesModal] = useState(false);
  const [modalEvent, setModalEvent] = useState<Advisor_ClientEvent_ViewModel | null>(null);
  const [recordingsAndPresignedS3Urls, setRecordingsAndPresignedS3Urls] = useState<{ [index: string]: string }>({});

  const openModal = (event: Advisor_ClientEvent_ViewModel) => {
    setModalEvent(event);
    setShowEnterNotesModal(true);
  };

  const toggleEnterNotesModal = () => {
    setShowEnterNotesModal(!showEnterNotesModal);
  };

  function loadPageData() {
    const loadData = async () => {
      const clientData = await AdvisorsApiService.getAdvisorClientInfo(clientID!);
      setClient(clientData);

      const distinctRecordingIds = clientData.pastEvents.flatMap((v) => v.recordings.map((m) => m.id));
      const presignedS3Urls = await Promise.all(distinctRecordingIds.map((v) => RecordingsApiService.getPresignedS3UrlForRecording(v)));

      setRecordingsAndPresignedS3Urls((v) => {
        const newVal = { ...v };

        distinctRecordingIds.forEach((m, i) => {
          newVal[m] = presignedS3Urls[i];
        });

        return {
          ...v,
          ...newVal
        };
      });

      setShowEnterNotesModal(false);
    };

    loadData();
  }

  useEffect(() => {
    loadPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientID]);

  const currentDateTime = new Date();
  const twoHoursFromNow = new Date(currentDateTime.getTime() + 2 * 60 * 60 * 1000);

  return (
    <>
      <Helmet>
        {!client && <title>{AppSettings.ApplicationName} - Loading...</title>}
        {client && (
          <title>
            {AppSettings.ApplicationName} - {client.fullName}
          </title>
        )}
      </Helmet>

      <Container>
        <Row>
          <Col className="advisor-consumer-details">
            <div className="breadcrumb-nav">
              <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`}>
                <i className="c-black fa-regular fa-arrow-left back-arrow"></i> Back
              </Link>
            </div>
            {!client && <LoadingSpinner message="Loading..." />}
            {client && (
              <div>
                <h1>{client.fullName}</h1>
                <h4>
                  <a href={'mailto:' + client.email}>{client.email}</a> - {StateHelper.getStateNameFromAbbreviation(client.state)}
                </h4>
                <p>
                  <Link
                    to={`/${ApplicationRoutes.AdvisorAccountRoutes.Compliance_Full}/${client.id}/report`}
                    className="btn btn-primary btn-lg"
                  >
                    Compliance Report &nbsp;<i className="fa-light fa-file-check"></i>
                  </Link>
                </p>

                {client.intakeResponses && client.intakeResponses.length == 0 && (
                  <div>
                    <h2>Intake Form Responses</h2>
                    <p>
                      <i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i> {client.fullName} has not filled out any intake
                      forms.
                    </p>
                  </div>
                )}

                {client.upcomingEvents && client.upcomingEvents.length > 0 && (
                  <div>
                    <h2>Upcoming Events</h2>
                    {client.upcomingEvents.map((event, index) => (
                      <UncontrolledAccordion
                        className="accordion-primary past-meetings"
                        defaultOpen={index === 0 ? '1' : ''}
                        key={event.id}
                        toggle={() => {}}
                      >
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <strong>{DateHelper.longDateFormat(event.startTime)}</strong> &nbsp;with&nbsp;{' '}
                            <strong>{event.advisorFullName}</strong>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            <div className="upcoming-buttons">
                              {new Date(event.startTime) < twoHoursFromNow && (
                                <a className="btn btn-primary btn-lg" href={event.location}>
                                  Join Meeting
                                </a>
                              )}
                              {new Date(event.startTime) >= twoHoursFromNow && (
                                <a className="btn btn-secondary disabled btn-lg" href={event.location}>
                                  Join Meeting
                                </a>
                              )}{' '}
                              <a className="btn btn-light btn-lg" href={event.rescheduleUrl}>
                                Reschedule Meeting
                              </a>{' '}
                              <a className="btn btn-light btn-lg" href={event.cancelUrl}>
                                Cancel Meeting
                              </a>
                            </div>

                            {event.isNectarine && (
                              <p>
                                <i className="fa-duotone fa-peach light-blue"></i> This engagement will operate under Nectarine's RIA.
                              </p>
                            )}
                            {!event.isNectarine && (
                              <p>
                                <i className="fa-kit-duotone fa-duotone-solid-peach-slash missing-icon"></i> This engagement will not
                                operate under Nectarine's RIA.
                              </p>
                            )}

                            <h3>Intake Responses</h3>
                            {event.intakeForms.length == 0 && (
                              <div className="intake-form">{client.firstName} has not submitted an intake form before this meeting.</div>
                            )}

                            {event.intakeForms.map((ir, index) => {
                              const parsedJson = ir.jsonData;

                              return (
                                <IntakeResponsesListing
                                  key={index}
                                  clientFirstName={client.firstName}
                                  intakeResponseData={parsedJson}
                                  intakeResponseSubmitDate={ir.submittedAt}
                                />
                              );
                            })}
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    ))}
                  </div>
                )}

                {client.pastEvents && client.pastEvents.length > 0 && (
                  <div>
                    <h2>Past Events</h2>
                    {client.pastEvents.map((event, index) => (
                      <UncontrolledAccordion
                        className="accordion-primary past-meetings"
                        defaultOpen={index == 0 ? '1' : ''}
                        key={event.id}
                        toggle={() => {}}
                      >
                        <AccordionItem>
                          <AccordionHeader targetId="1">
                            <strong>{DateHelper.longDateFormat(event.startTime)}</strong> &nbsp;with&nbsp;{' '}
                            <strong>{event.advisorFullName}</strong>
                          </AccordionHeader>
                          <AccordionBody accordionId="1">
                            {event.isNectarine && (
                              <p>
                                <i className="fa-duotone fa-peach light-blue"></i> This engagement operated under Nectarine's RIA.
                              </p>
                            )}
                            {!event.isNectarine && (
                              <p>
                                <i className="fa-kit-duotone fa-duotone-solid-peach-slash missing-icon"></i> This engagement did not operate
                                under Nectarine's RIA.
                              </p>
                            )}

                            <h3>Intake Responses</h3>
                            {event.intakeForms.length == 0 && (
                              <div className="intake-form">
                                <i className="fa-kit fa-solid-check-to-slot-slash missing-icon"></i> {client.firstName} did not submit an
                                intake form before this meeting.
                              </div>
                            )}

                            {event.intakeForms.map((ir, index) => {
                              const parsedJson = ir.jsonData;

                              return (
                                <IntakeResponsesListing
                                  key={index}
                                  clientFirstName={client.firstName}
                                  intakeResponseData={parsedJson}
                                  intakeResponseSubmitDate={ir.submittedAt}
                                />
                              );
                            })}

                            {!event.notesSent && (
                              <div>
                                <h3>Meeting Notes</h3>
                                <div className="meeting-notes">
                                  <i className="fa-duotone fa-notes missing-icon"></i> {event.advisorFullName} has not yet submitted notes
                                  for this meeting.
                                  <p>
                                    <Link to={`/${ApplicationRoutes.AdvisorAccountRoutes.Root}`} className="btn btn-primary btn-lg mt-3">
                                      <i className="fa-light fa-pen-to-square"></i> Enter Notes
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            )}
                            {event.notesSent && (
                              <div>
                                <p>
                                  <i className="fa-duotone fa-notes att-icon"></i> {event.advisorFullName} last updated these notes{' '}
                                  <strong>{DateHelper.longDateFormat(event.notesUpdated)}</strong>
                                </p>
                                <p>
                                  <Button color="primary" onClick={(e) => openModal(event)}>
                                    <i className="fa-light fa-pen-to-square"></i> Edit Private Notes
                                  </Button>
                                </p>
                                <h3>Private Notes</h3>
                                <div
                                  className="meeting-notes"
                                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.privateNotes) }}
                                ></div>
                                <h3>Notes for Client</h3>
                                <div className="meeting-notes" dangerouslySetInnerHTML={{ __html: event.publicNotes }}></div>
                              </div>
                            )}

                            {modalEvent ? (
                              <EnterMeetingNotesModal
                                show={showEnterNotesModal}
                                toggle={toggleEnterNotesModal}
                                save={loadPageData}
                                event={modalEvent}
                              />
                            ) : (
                              <></>
                            )}

                            {event.recordings && event.recordings.length > 0 && (
                              <div>
                                <h3>Meeting Recording</h3>
                                {event.recordings.map((recording, index) => (
                                  <div key={recording.id}>
                                    {recording && ![RecordingStatus.Processing, RecordingStatus.Error].includes(recording.status) ? (
                                      <div>
                                        {recordingsAndPresignedS3Urls[recording.id] ? (
                                          <>
                                            <video key={index} controls className="recording-video">
                                              <source src={recordingsAndPresignedS3Urls[recording.id]} type="video/mp4" />
                                              Your browser does not support the video tag.
                                            </video>

                                            <div className="mt-3">
                                              <a className="btn btn-light" href={recordingsAndPresignedS3Urls[recording.id]}>
                                                <i className="fa-solid fa-download me-2"></i> Download (
                                                {(recording.fileSize / 1024.0 / 1024.0).toFixed(0)}MB)
                                              </a>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {recording && recording.status == 'Uploading' ? (
                                      <div>
                                        <i className="fa-duotone fa-upload missing-icon"></i> The recording for this meeting is{' '}
                                        <strong>{recording.durationInMinutes}</strong> minutes long and is still being processed.
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {recording.meetingSummary ? (
                                      <div className="mt-4">
                                        <ZoomMeetingSummary meetingSummary={recording.meetingSummary} />
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </AccordionBody>
                        </AccordionItem>
                      </UncontrolledAccordion>
                    ))}
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdvisorAccountClient;
