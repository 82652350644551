import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationRoutes } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import ErrorsHelper from '../../../Helpers/ErrorsHelper';
import { useConfirmationOverlayContext } from '../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineTextInput from '../../../ui/NectarineTextInput/NectarineTextInput';
import { useNotificationsPanelContext } from '../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';
import './../../../../styles/Admin.scss';

const AdminEditRole: React.FC = () => {
  const [redirectToAdminList, setRedirect] = useState<boolean>(false);
  const [roleViewModel, setRoleViewModel] = useState<Admin_UserRole_ViewModel | null>(null);
  const [roleToEdit, setRoleToEdit] = useState<Admin_UserRole_EditModel | null>(null);

  const { roleID } = useParams<{ roleID: string }>();

  const formRef = useRef<HTMLFormElement | null>(null);

  const { showSuccessNotification, showErrorNotification } = useNotificationsPanelContext();

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  useEffect(() => {
    const loadData = async () => {
      if (roleID) {
        const result = await AdminApiService.getUserRoleById(roleID);

        setRoleViewModel(result);

        setRoleToEdit({
          ...result
        });
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redirectToAdminList) {
    return <Navigate to={`/${ApplicationRoutes.AdminRoutes.RolesRoutes.Root_Full}`} />;
  }

  const onSave = async () => {
    try {
      await AdminApiService.updateUserRole(roleToEdit!);

      showSuccessNotification('The role was saved successfully.');

      setRedirect(true);
    } catch (error) {
      showErrorNotification('An error occurred while saving the role: ' + ErrorsHelper.getErrorMessageFromErrorObject(error));

      console.error('Unable to add item.', error);
    }

    return false;
  };

  const onDelete = async () => {
    try {
      showConfirmationOverlay({
        title: 'Confirm Deletion',
        text: `Are you sure you want to delete the <b>${roleViewModel!.name}</b> role?`,
        text2: `This is permanent and can't be undone.`,
        noButtonText: 'Not yet',
        yesButtonText: 'Delete',
        yesAction: async () => {
          await AdminApiService.deleteUserRole(roleToEdit!.id);

          showSuccessNotification('The role was deleted successfully.');

          setRedirect(true);
        }
      });
    } catch (error) {
      showErrorNotification('An error occurred while deleting the role: ' + ErrorsHelper.getErrorMessageFromErrorObject(error));
      setRedirect(false);
    }

    return false;
  };

  return (
    <Container>
      <Helmet>
        <title>Admin / Edit Role</title>
      </Helmet>

      {!roleToEdit ? <LoadingSpinner message="Loading..." /> : <></>}

      {roleToEdit ? (
        <div>
          <form ref={formRef} noValidate>
            <h2>Edit {roleViewModel?.name}</h2>

            <div className="w-25">
              <NectarineTextInput
                label="Role Name"
                placeholder="Enter a role name"
                value={roleToEdit.name}
                required
                minLength={3}
                maxLength={25}
                onChange={(newVal) => {
                  setRoleToEdit((v) => ({ ...v!, name: newVal! }));
                }}
              />
            </div>

            <div className="mt-3"></div>

            <div className="d-flex gap-3">
              <NectarineButton
                text="Save"
                onClick={async () => {
                  const formIsValid = formRef.current?.checkValidity();
                  formRef.current!.reportValidity();

                  if (formIsValid) {
                    await onSave();
                  }
                }}
              />

              <NectarineButton
                text="Delete"
                type="red"
                onClick={async () => {
                  await onDelete();
                }}
              />
            </div>
          </form>
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default AdminEditRole;
