import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'reactstrap';
import { ApplicationRoutes, AppSettings, ValueLimits } from '../../../../constants';
import logoImage from '../../../../img/nectarine-logo.png';
import AdvisorsApiService from '../../../../services/api/AdvisorsApiService';
import UsersApiService from '../../../../services/api/UsersApiService';
import { ErrorLogService } from '../../../../services/ErrorLogService';
import AuthorizeService from '../../../api-authorization/AuthorizeService';
import AdvisorsHelper from '../../../Helpers/AdvisorsHelper';
import ErrorsHelper from '../../../Helpers/ErrorsHelper';
import HttpHelper from '../../../Helpers/HttpHelper';
import { useLoadingOverlayContext } from '../../../ui/LoadingAnimations/LoadingOverlay/LoadingOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import NectarineTextInput from '../../../ui/NectarineTextInput/NectarineTextInput';

const className = 'AccountRegister';

export const AccountRegister = () => {
  const navigate = useNavigate();

  const [newUserRegistrationInfo, setNewUserRegistrationInfo] = useState<Users_Register_EditModel>({
    email: '',
    password: '',
    confirmPassword: '',
    advisorSlug: '',
    firstName: '',
    lastName: '',
    isAffiliate: false
  });
  const [advisor, setAdvisor] = useState<Advisor_BasicDetails_ViewModel | null>(null);
  const [advisorLoading, setAdvisorLoading] = useState<boolean>(true);
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });
  const [isAuthenticated, setIsAuthenticated] = useState<{ loading: boolean; authenticated: boolean }>({
    loading: true,
    authenticated: false
  });
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [affiliateSignUpMode, setAffiliateSignUpMode] = useState<boolean>(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const advisorSlug = queryParams.get('advisor');

  const formRef = useRef<HTMLFormElement | null>(null);

  const { showLoadingOverlay, hideLoadingOverlay } = useLoadingOverlayContext();

  useEffect(() => {
    const loadData = async () => {
      const isAffiliateMode = queryParams.get('affiliate') === 'true';

      setAffiliateSignUpMode(isAffiliateMode);

      if (advisorSlug) {
        const advisor = await AdvisorsApiService.getAdvisorDetailsByIdOrSlug(advisorSlug, false, false, false);
        setAdvisor(advisor);
      } else {
        setAdvisor(null);
      }

      setAdvisorLoading(false);

      setNewUserRegistrationInfo((v) => ({
        ...v,
        advisorSlug: advisorSlug,
        isAffiliate: isAffiliateMode
      }));

      const isAuthenticated = await AuthorizeService.isAuthenticated();
      await onAuthenticated(isAuthenticated, isAffiliateMode);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onAuthenticated(isAuthenticated: boolean, isAffiliateMode: boolean) {
    //If we're authenticated and it's affiliate mode, post to the server that we should be an affiliate
    if (isAuthenticated && isAffiliateMode) {
      const addedRole = await addAffiliateRole();

      if (addedRole) {
        redirectBackHereToRefreshRoles();
      }
    }

    setIsAuthenticated({ loading: false, authenticated: isAuthenticated });
  }

  function hideError() {
    setErrorSection({ text: null, show: false });
  }

  function showError(text: string) {
    setErrorSection({ show: true, text: text });
  }

  async function addAffiliateRole() {
    try {
      let returnValue = false;

      await HttpHelper.put('/api/home/becomeaffiliate/', undefined, undefined, {
        200: () => {
          returnValue = true;
        },
        204: () => {
          showError('No changes were made.');
          returnValue = false;
        }
      });

      return returnValue;
    } catch (error) {
      ErrorLogService.logError(
        `${className} - addAffiliateRole()`,
        'An error occurred while attempting to add an affiliate role to the user.',
        error
      );

      showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
      return false;
    }
  }

  async function registerAccount() {
    setIsSaving(true);

    try {
      showLoadingOverlay();

      await UsersApiService.register(newUserRegistrationInfo);
      setIsRegistered(true);

      redirectBackHereToRefreshRoles();

      setIsSaving(false);
    } catch (error: unknown) {
      const errorMessage = (error as Error).message || '';
      showError(errorMessage);
      setIsSaving(false);
    } finally {
      hideLoadingOverlay();
    }

    return false;
  }

  function redirectBackHereToRefreshRoles() {
    //Redirect back to login page, then back to this page so the client roles are refreshed
    const returnUrl = encodeURIComponent(window.location.href);
    navigate(`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${returnUrl}`);
  }

  return (
    <Container>
      <Helmet>
        <title>Register a New Account - {AppSettings.ApplicationName}</title>
      </Helmet>
      <Row>
        <Col lg="3" md="0" />

        {(isAuthenticated.loading || advisorLoading) && <LoadingSpinner />}

        {!isAuthenticated.loading && isAuthenticated.authenticated && advisor && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Welcome to Nectarine!</h2>
              <p>
                To finish scheduling your meeting with <strong>{advisor.fullName}</strong> click continue below to visit the advisor's page
                and then click the schedule meeting button.
              </p>

              {advisor.profilePicUrl ? (
                <img
                  src={advisor.profilePicUrl}
                  className="circle-image headshot center"
                  alt={AdvisorsHelper.getAdvisorDisplayName(advisor)}
                />
              ) : (
                <span className="fa-duotone fa-solid fa-circle-user font-rem-10 c-blue"></span>
              )}

              <p className="mt-5">
                <Link to={`/${ApplicationRoutes.Advisor}/${advisor.slug}`} className="btn btn-primary btn-lg center">
                  Continue
                </Link>
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && isAuthenticated.authenticated && !advisor && !affiliateSignUpMode && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Welcome to Nectarine!</h2>
              <p>Your account has been successfully created and you are currently logged in.</p>

              <p className="mt-5">
                <Link to={ApplicationRoutes.Root} className="btn btn-primary btn-lg center">
                  Return to Nectarine Homepage
                </Link>
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && isAuthenticated.authenticated && !advisor && affiliateSignUpMode && (
          <Col lg="6" md="12" className="align-items-center justify-content-center pt-5">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>You're all signed up!</h2>
              <p>
                Visit your <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}>account</Link> to and click
                <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Share_Full}`}> Share Nectarine</Link> to get started.
              </p>
            </div>
          </Col>
        )}
        {!isAuthenticated.loading && !advisorLoading && !isAuthenticated.authenticated && !isRegistered && (
          <Col lg="6" md="12" className="align-items-center justify-content-center">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              {advisor && (
                <p>
                  To book a meeting with {advisor.firstName}, <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}`}>login</Link> or
                  create your account below.
                </p>
              )}
              <h2>Create an Account</h2>

              <form ref={formRef} noValidate>
                <div className="mb-3">
                  <Row>
                    <Col sm="6">
                      <NectarineTextInput
                        placeholder="First Name"
                        label="Name"
                        required
                        minLength={ValueLimits.Name_MinLength}
                        maxLength={ValueLimits.Name_MaxLength}
                        value={newUserRegistrationInfo.firstName}
                        onChange={(newVal) => {
                          setNewUserRegistrationInfo((v) => ({ ...v, firstName: newVal ?? '' }));
                        }}
                      />
                    </Col>
                    <Col sm="6" className="d-flex align-items-end">
                      <div className="flex-grow-1">
                        <NectarineTextInput
                          placeholder="Last Name"
                          required
                          minLength={ValueLimits.Name_MinLength}
                          maxLength={ValueLimits.Name_MaxLength}
                          value={newUserRegistrationInfo.lastName}
                          onChange={(newVal) => {
                            setNewUserRegistrationInfo((v) => ({ ...v, lastName: newVal ?? '' }));
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  {affiliateSignUpMode ? (
                    <Alert color="info" className="mt-3">
                      <strong>Important!</strong> Please use your real legal name for compliance and payment purposes.
                    </Alert>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mb-3">
                  <NectarineTextInput
                    type="email"
                    label="Email"
                    placeholder="name@example.com"
                    required
                    maxLength={ValueLimits.Email_MaxLength}
                    value={newUserRegistrationInfo.email}
                    onChange={(newVal) => {
                      setNewUserRegistrationInfo((v) => ({ ...v, email: newVal ?? '' }));
                    }}
                  />
                </div>

                <div className="mb-3">
                  <NectarineTextInput
                    type="password"
                    label="Choose a password"
                    secondaryLabel=" (8 character minimum)"
                    placeholder="Choose a password"
                    required
                    minLength={ValueLimits.Password_MinLength}
                    maxLength={ValueLimits.Password_MaxLength}
                    value={newUserRegistrationInfo.password}
                    onChange={(newVal) => {
                      setNewUserRegistrationInfo((v) => ({ ...v, password: newVal ?? '' }));
                    }}
                  />
                </div>

                <div className="mb-3">
                  <NectarineTextInput
                    type="password"
                    label="Re-enter password"
                    placeholder="Re-enter your password"
                    required
                    minLength={ValueLimits.Password_MinLength}
                    maxLength={ValueLimits.Password_MaxLength}
                    value={newUserRegistrationInfo.confirmPassword}
                    onChange={(newVal) => {
                      setNewUserRegistrationInfo((v) => ({ ...v, confirmPassword: newVal ?? '' }));
                    }}
                  />
                </div>

                {errorSection.show ? <Alert color="danger">{errorSection.text}</Alert> : <></>}

                <div className="d-flex justify-content-end mt-4">
                  <NectarineButton
                    type="blue"
                    text="Create Account"
                    showLoadingDots={isSaving}
                    isDisabled={isSaving}
                    onClick={async () => {
                      const formIsValid = formRef.current?.checkValidity();
                      formRef.current!.reportValidity();

                      if (formIsValid) {
                        await registerAccount();
                      }
                    }}
                  />
                </div>
              </form>
            </div>
            <div className="text-center register-footer mt-3">
              <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${encodeURIComponent(window.location.href)}`}>
                Login
              </Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Contact}`}>Contact</Link> &bull; <Link to={`/${ApplicationRoutes.Terms}`}>Terms</Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Privacy}`}>Privacy</Link>
            </div>
          </Col>
        )}

        {!isAuthenticated.loading && isRegistered ? (
          <Col lg="6" md="12" className="align-items-center justify-content-center">
            <div className="line-box register-box">
              <Link to={ApplicationRoutes.Root}>
                <img src={logoImage} alt="Nectarine" className="center" />
              </Link>
              <h2>Welcome!</h2>
              <p>
                Your account is created and ready to go! Now you can visit{' '}
                <Link to={`/${ApplicationRoutes.ClientAccountRoutes.Root}`}>your account</Link>.
              </p>
            </div>
            <div className="text-center register-footer">
              <Link to={`/${ApplicationRoutes.AuthenticationRoutes.Login}?returnUrl=${encodeURIComponent(window.location.href)}`}>
                Login
              </Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Contact}`}>Contact</Link> &bull; <Link to={`/${ApplicationRoutes.Terms}`}>Terms</Link>{' '}
              &bull; <Link to={`/${ApplicationRoutes.Privacy}`}>Privacy</Link>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Container>
  );
};
