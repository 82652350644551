import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Container, Row } from 'reactstrap';
import { AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';

const AdminIntakeForms = () => {
  const [intakeForms, setIntakeForms] = useState<Admin_IntakeResponse_ViewModel[] | null>(null);

  useEffect(() => {
    const loadData = async () => {
      const result = await AdminApiService.getOrphanIntakeForms();
      setIntakeForms(result);
    };
    loadData();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Intake Forms</title>
      </Helmet>
      <Row>
        <Col>
          <h2>Intake Forms</h2>

          {!intakeForms ? <LoadingSpinner message="Loading..." /> : <></>}

          {intakeForms ? (
            <ul>
              {intakeForms.map((item) => (
                <li key={item.id}>{item.email}</li>
              ))}
            </ul>
          ) : (
            <></>
          )}

          {intakeForms && !intakeForms.length ? <span className="c-gray">(No intake forms were found)</span> : <></>}
        </Col>
      </Row>
    </Container>
  );
};

export default AdminIntakeForms;
