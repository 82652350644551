import './NectarineTable.scss';

const NectarineTableDataColumn: React.FC<NectarineTableDataColumnProps> = (props: NectarineTableDataColumnProps) => {
  const getCssClasses = (): string => {
    const returnValue: string[] = [];

    if (props.alignment !== 'stretch') {
      returnValue.push('d-flex');
    }

    switch (props.alignment) {
      case 'center':
        returnValue.push('justify-content-center');
        break;

      case 'right':
        returnValue.push('justify-content-end');
        break;

      default:
        break;
    }

    return returnValue.join(' ');
  };

  return (
    <td>
      <div className={`${getCssClasses()}`}>{props.children}</div>
    </td>
  );
};

export default NectarineTableDataColumn;
