import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { ApplicationRoutes, AppSettings } from '../../../../constants';
import AdminApiService from '../../../../services/api/AdminApiService';
import DateHelper from '../../../Helpers/DateHelper';
import GeneralHelper from '../../../Helpers/GeneralHelper';
import { useConfirmationOverlayContext } from '../../../ui/ConfirmationOverlay/ConfirmationOverlayContext';
import LoadingSpinner from '../../../ui/LoadingAnimations/LoadingSpinner/LoadingSpinner';
import NectarineButton from '../../../ui/NectarineButton/NectarineButton';
import { NectarineTable } from '../../../ui/NectarineTable/NectarineTable';
import NectarineTableDataColumn from '../../../ui/NectarineTable/NectarineTableDataColumn';
import { NectarineTableHeaderColumn, NectarineTableHeaderColumns } from '../../../ui/NectarineTable/NectarineTableHeaderColumn';
import AdminImpersonateLink from '../AdminImpersonateLink/AdminImpersonateLink';

const AdminReviews: React.FC = () => {
  const [reviews, setReviews] = useState<Reviews_Review_ViewModel[]>([]);

  const { showConfirmationOverlay } = useConfirmationOverlayContext();

  useEffect(() => {
    const loadData = async () => {
      const reviewsData = await AdminApiService.getAllReviews();
      setReviews(reviewsData);
    };

    loadData();
  }, []);

  const onDelete = async (reviewToDelete: Reviews_Review_ViewModel) => {
    showConfirmationOverlay({
      title: 'Confirm Deletion',
      text: `Are you sure you want to delete the review with title <b>${reviewToDelete.title}</b>?`,
      text2: `This is permanent and can't be undone.`,
      noButtonText: 'Not yet',
      yesButtonText: 'Delete',
      yesAction: async () => {
        const updatedReviews = [...reviews];
        const index = updatedReviews.indexOf(reviewToDelete);
        if (index !== -1) {
          updatedReviews.splice(index, 1);
        }

        await AdminApiService.deleteReview(reviewToDelete.id);

        setReviews(updatedReviews);
      }
    });
  };

  return (
    <Container>
      <Helmet>
        <title>{AppSettings.ApplicationName} - Reviews</title>
      </Helmet>

      <h1>All Reviews</h1>

      {!reviews && <LoadingSpinner message="Loading..." />}

      {reviews ? (
        <div>
          Showing <strong>{GeneralHelper.formatNumberWithCommas(reviews.length)}</strong> results
        </div>
      ) : (
        <></>
      )}

      {reviews ? (
        <NectarineTable<Reviews_Review_ViewModel>
          maxHeight="50vh"
          data={reviews ?? []}
          searchPlaceholderText="Search Reviews"
          searchFn={(item, searchText) => {
            const regex = new RegExp(searchText, 'gi');
            const dataToSearch: string[] = [
              item.revieweeFullName,
              item.reviewerFullName,
              item.rating.toString(),
              item.coverageRating.toString(),
              item.competenceRating.toString(),
              item.title.toLowerCase(),
              item.displayName,
              DateHelper.mediumDateFormat(item.modifiedAt)
            ];

            return dataToSearch.some((v) => regex.test(v));
          }}
          rowDataTemplate={(item) => {
            return (
              <>
                <NectarineTableDataColumn>
                  <div>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.reviewerId}/edit`}>{item.reviewerFullName}</Link>
                    <AdminImpersonateLink userId={item.reviewerId} />
                  </div>
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  <div>
                    <Link to={`/${ApplicationRoutes.AdminRoutes.Users_Full}/${item.revieweeId}/edit`}>{item.revieweeFullName}</Link>
                    <AdminImpersonateLink userId={item.revieweeId} />
                  </div>
                </NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.rating}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.coverageRating}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.competenceRating}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.recapRating}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.recommendationRating}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.title}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{item.displayName}</NectarineTableDataColumn>

                <NectarineTableDataColumn>{DateHelper.mediumDateFormat(item.createdAt)}</NectarineTableDataColumn>

                <NectarineTableDataColumn>
                  <NectarineButton
                    type="red"
                    size="small"
                    text="Delete"
                    onClick={async () => {
                      await onDelete(item);
                    }}
                  />
                </NectarineTableDataColumn>
              </>
            );
          }}
        >
          <NectarineTableHeaderColumns>
            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.reviewerFullName.toLowerCase()}>
              Reviewer
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.revieweeFullName.toLowerCase()}>
              Reviewee
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.rating}>Rating</NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.coverageRating}>
              Coverage Rating
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.competenceRating}>
              Competence Rating
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.recapRating}>Recap Rating</NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.recommendationRating}>
              Recommendation Rating
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.title.toLowerCase()}>Title</NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.displayName.toLowerCase()}>
              Display Name
            </NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel> sortFn={(v) => v.modifiedAt}>Modified</NectarineTableHeaderColumn>

            <NectarineTableHeaderColumn<Reviews_Review_ViewModel>>Delete</NectarineTableHeaderColumn>
          </NectarineTableHeaderColumns>
        </NectarineTable>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default AdminReviews;
