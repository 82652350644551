import { useRef, useState } from 'react';
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import AdvisorsApiService from '../../../../../services/api/AdvisorsApiService';
import ErrorsHelper from '../../../../Helpers/ErrorsHelper';
import NectarineButton from '../../../../ui/NectarineButton/NectarineButton';
import NectarineDateInput from '../../../../ui/NectarineDateInput/NectarineDateInput';
import NectarineTextAreaInput from '../../../../ui/NectarineTextAreaInput/NectarineTextAreaInput';
import { useNotificationsPanelContext } from '../../../../ui/Notifications/NotificationsPanel/NotificationsPanelContext';

interface AdvisorEditBioProps {
  show: boolean;
  toggle: () => void;
  advisor: Advisor_BasicDetails_ViewModel;
  save: (updatedAdvisorVM: Advisor_BasicDetails_ViewModel) => void;
}

const AdvisorEditBio: React.FC<AdvisorEditBioProps> = (props: AdvisorEditBioProps) => {
  const [saveBioLoading, setSaveBioLoading] = useState(false);
  const [advisorToEdit, setAdvisorToEdit] = useState<Advisor_EditAdvisor_EditModel>({ ...props.advisor });
  const [errorSection, setErrorSection] = useState<ErrorSection>({ show: false, text: null });

  const { showSuccessNotification } = useNotificationsPanelContext();

  const formRef = useRef<HTMLFormElement | null>(null);

  const onSave = async () => {
    try {
      setSaveBioLoading(true);

      await AdvisorsApiService.updateAdvisorBio(advisorToEdit);

      props.save({
        ...props.advisor,
        experienceStart: advisorToEdit.experienceStart,
        personalBio: advisorToEdit.personalBio,
        longBio: advisorToEdit.longBio
      });

      showSuccessNotification('Your profile information was updated successfully.');
    } catch (error) {
      showError(ErrorsHelper.getErrorMessageFromErrorObject(error));
    } finally {
      setSaveBioLoading(false);
    }

    return false;
  };

  function showError(text: string) {
    setErrorSection({
      show: true,
      text
    });
  }

  function hideError() {
    setErrorSection({ show: false, text: null });
  }

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <form ref={formRef} noValidate>
        <ModalHeader toggle={props.toggle}>
          <div className="bold">Edit Bio</div>
        </ModalHeader>
        <ModalBody>
          {errorSection.show ? <Alert color="danger">{errorSection.text}</Alert> : <></>}

          <NectarineDateInput
            label="Experience Start Date"
            value={advisorToEdit.experienceStart ?? new Date()}
            onChange={(newVal) => {
              setAdvisorToEdit((a) => ({
                ...a,
                experienceStart: newVal
              }));
            }}
          />

          <div className="mt-3"></div>

          <NectarineTextAreaInput
            label="Bio (1,000 max characters)"
            placeholder="e.g. Hi, I'm Shane! I'm such a personal finance nerd that I kept a budget on our family trips at age 7. After working at a Wall Street firm for eight years, I started my own financial planning firm and also became a personal finance content creator. My main goal is to make you feel more confident with your money. And I will always encourage you to pursue your dreams, whether it is being a successful entrepreneur or achieving financial independence. I'd love to learn about your story, how you got to where you are today, and how I can help!"
            rows={6}
            required
            maxLength={1000}
            value={advisorToEdit.longBio}
            onChange={(e) => {
              hideError();
              setAdvisorToEdit((a) => ({
                ...a,
                longBio: e ?? ''
              }));
            }}
          />

          <div className="mt-3"></div>

          <NectarineTextAreaInput
            label="Personal Stuff (500 max characters)"
            placeholder="e.g. I've lived all over the US - from LA to NYC, Boston, SF, Austin, and Chicago. But this guy likes my backyard in the suburbs so I guess I'm staying put."
            rows={6}
            required
            maxLength={500}
            value={advisorToEdit.personalBio}
            onChange={(e) => {
              hideError();
              setAdvisorToEdit((a) => ({
                ...a,
                personalBio: e ?? ''
              }));
            }}
          />
        </ModalBody>
        <ModalFooter>
          <div className="d-flex gap-2">
            <NectarineButton
              text="Cancel"
              type="white"
              onClick={() => {
                props.toggle();
              }}
            />

            <NectarineButton
              text="Save Bio"
              showLoadingDots={saveBioLoading}
              isDisabled={saveBioLoading}
              onClick={() => {
                const formIsValid = formRef.current?.checkValidity();
                formRef.current!.reportValidity();

                if (formIsValid) {
                  onSave();
                }
              }}
            />
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AdvisorEditBio;
